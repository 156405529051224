import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { DropdownFilter  } from '../../../../../_metronic/partials/content/dropdown/DropdownFilter'

type Props = {
    filterName: any,
    onCleanFilter: () => void,
    onSearchFilter: ( text:string ) => void,

}

const Filter: React.FC<Props> = ( { filterName, onSearchFilter, onCleanFilter } ) => {

return (
    <>
        <div className='card-title align-items-start flex-column'>
            <a
            href='#/'
            // className='btn btn-sm btn-light-primary'
            className={ (filterName) ? 'btn btn-sm btn-primary': 'btn btn-sm btn-light-primary'}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            >
            <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
            />
            Filtrar
            </a>
            <DropdownFilter handleFilter={ onSearchFilter } valueFilter={ filterName } handleFilterClean={  onCleanFilter }/>
        </div>
    </>
)

}

export { Filter }