import { useState, useEffect } from 'react';
import {
  getAllContratoLocal,
  getAllContratoLocalWithoutPages,
  getOneContratoLocal,
  getHistorial,
} from '../../../service/contrato_local/ContratoLocal';
import { ContratoLocalModel } from '../models/ContratoLocalModel';
import { HistorialModel } from '../models/HistorialModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<ContratoLocalModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllContratoLocal(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, realoadGrid]);
  return { data, count, loadingRequest };
};

export const useGetAllWithoutPages = (realoadGrid: number, filters: any) => {
  const [dataExcel, setDataExcel] = useState<ContratoLocalModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllContratoLocalWithoutPages(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataExcel(res.rows);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { dataExcel };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    arrendatario_id: 0,
    plaza_id: -2,
    frecuencia_id: 1,
    locales: [],
    contrato: '',
    contrato_firmado: '',
    contrato_xf: '',
    estatus: '',
    file: '',
  };
  const [initValues, setInitValues] = useState<ContratoLocalModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getOneContratoLocal(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues };
};

export const useGetHistory = (
  startIndex: number,
  endIndex: number,
  id: number
) => {
  const [data, setData] = useState<HistorialModel[]>([]);
  const [count, setCount] = useState(1);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getHistorial(startIndex, endIndex, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
      }
    };
    if (id > 0) {
      fetchPost();
    } else {
      setData([]);
      setCount(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, id]);
  return { data, count };
};
