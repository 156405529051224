import { useState, useEffect } from 'react'
import { getAllArrendatarios, getOneArrendatario, getSelectArrendatario, getSelectArrendatarioContrato } from '../../../../service/catalog/Catalogs'
import { ArrendatarioModel } from '../models/ArrendatarioModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<ArrendatarioModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllArrendatarios( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });

            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid]);
    return { data, count, loadingRequest }
}

export const useGetOne = ( id:number ) => {
    const initModel = {
        id: 0,
        nombre: '',
        apellido: '',
        img_id_frente: '',
        img_id_atras: '',
        telefono: '',
        correo: '',
        aval: '',
        rfc: '',
        calle: '',
        num_int: '',
        num_ext: '',
        colonia: '',
        codigo_postal: '',
        ciudad: '',
        estado: '',
        files: ''
    }
    const [ initValues, setInitValues ] = useState<ArrendatarioModel>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOneArrendatario( id ).then().catch((error) => {
                history.push('/error/500')
            });
            
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}

export const useSelectArrendatario = ( ) => {

    const [ data, setData ] = useState<ArrendatarioModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getSelectArrendatario().then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { data }
}
export const useSelectArrendatarioContrato = ( plazaId: number ) => {

    const [ data, setData ] = useState<ArrendatarioModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getSelectArrendatarioContrato( plazaId ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plazaId]);

    return { data }
}
