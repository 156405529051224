import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllContratoLocal(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.arrendatario_id > 0) {
      search += `&arrendatario_id=${filters.arrendatario_id}`;
    }
    if (filters.plaza_id >= -1) search += `&plaza_id=${filters.plaza_id}`;
    if (filters.contrato > 0) search += `&id=${filters.contrato}`;
  }
  const response = await axios.get(
    `${API_URL}catalogos/contratosArrendatarios?sort=id&limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getAllContratoLocalWithoutPages(filters: any) {
  let search: string = '';
  if (filters) {
    if (filters.arrendatario_id > 0) {
      search += `&arrendatario_id=${filters.arrendatario_id}`;
    }
    if (filters.contrato > 0) search += `&id=${filters.contrato}`;
  }
  const response = await axios.get(
    `${API_URL}catalogos/contratosArrendatarios?sort=id${search}`
  );
  
  const data = response.data.doc.data;
  return data;
}

export async function getOneContratoLocal(id_row: number) {
  const response = await axios.get(
    `${API_URL}catalogos/contratosArrendatarios/${id_row}`
  );
  const {
    id,
    arrendatario,
    plaza,
    frecuencia,
    locales,
    contrato,
    contrato_firmado,
    contrato_xf,
    estatus,
  } = response.data.doc.data;
  let rowsLocales: any = [];
  locales.map((item: any) => {
    rowsLocales.push({
      fecha_fin: item.fecha_fin,
      fecha_inicio: item.fecha_inicio,
      guante: Number(item.guante),
      id: Number(item.id),
      local: item.local,
      local_id: Number(item.local_id),
      monto: Number(item.monto),
      parcialidad: Number(item.parcialidad),
    });
    return true;
  });

  return {
    id: id || 0,
    arrendatario_id: arrendatario.id || 0,
    plaza_id: plaza.id || 0,
    frecuencia_id: frecuencia.id || 0,
    locales: rowsLocales || [],
    contrato: contrato || '',
    contrato_firmado: contrato_firmado || '',
    contrato_xf: contrato_xf || '',
    estatus: estatus || '',
  };
}
export async function addContratoLocal(body: any) {
  const { arrendatario_id, plaza_id, frecuencia_id, locales } = body;
  const data = {
    arrendatario_id: Number(arrendatario_id),
    frecuencia_id: Number(frecuencia_id),
    plaza_id: Number(plaza_id),
    locales: locales,
  };
  const response = await axios.post(
    `${API_URL}catalogos/contratosArrendatarios`,
    data
  );
  return response;
}
export async function updateContratoLocal(body: any) {
  const { id, arrendatario_id, plaza_id, frecuencia_id, locales } = body;
  const response = await axios.put(
    `${API_URL}catalogos/contratosArrendatarios/${id}`,
    {
      arrendatario_id: arrendatario_id,
      frecuencia_id: frecuencia_id,
      plaza_id: plaza_id,
      locales: locales,
    }
  );
  return response;
}
export async function cargoProrata(id: any) {
  const response = await axios.post(
    `${API_URL}catalogos/contratosArrendatarios/movtos/${id}`
  );
  return response;
}
export async function removeContratoLocal(id: number) {
  const response = await axios.delete(
    `${API_URL}catalogos/contratosArrendatarios/${id}`
  );
  return response;
}
export async function addContratoFirmado(body: any) {
  const { id, file } = body;

  let formData = new FormData();
  formData.append('contrato_arrendatario_id', id);
  formData.append('contrato_firmado', file);

  const response = await axios({
    method: 'post',
    url: `${API_URL}catalogos/contratosArrendatarios/firmado`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
}

export async function getHistorial(
  startIndex: number,
  endIndex: number,
  id: number
) {
  const response = await axios.get(
    `${API_URL}catalogos/contratosArrendatarios/historial/${id}?limit=${startIndex}&page=${endIndex}`
  );
  const data = response.data.doc.data;
  return data;
}
