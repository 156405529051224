import React, { useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import {
  addParametrosOperativos,
  updateParametrosOperativos,
} from '../../../../service/catalog/ParametrosOperativos';
import { toast } from 'react-toastify';
import { useSelectPlazas } from '../../plazas/hooks/PlazaHook';
import { useGetOne, convertToLocalDate } from '../hooks/ParametroOperativoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
let fechaActual = new Date();
let minSiguienteMes = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 1,
  1
);
let maxSiguienteMes = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 2,
  0
);
const mesSiguiente = minSiguienteMes.toLocaleString('es-ES', { month: 'long' });

const ParamsOperachema = Yup.object().shape({
  plaza_id: Yup.number()
    .min(-1, 'Seleccione una plaza.')
    .required('Plaza es requerido.'),
  promo_dcto_ini_uno: Yup.date().when('promo_pcte_dcto_uno', {
    is: (val: any) => val === undefined || val > 0,
    then: Yup.date().max(
      Yup.ref('promo_dcto_fin_uno'),
      'No puede ser mayor fecha fin.'
    ),
  }),
  promo_dcto_fin_uno: Yup.date().when('promo_pcte_dcto_uno', {
    is: (val: any) => val === undefined || val > 0,
    then: Yup.date().min(
      Yup.ref('promo_dcto_ini_uno'),
      'No puede ser menor fecha inicio.'
    ),
    otherwise: Yup.date(),
  }),
  promo_dcto_ini_dos: Yup.date().when('promo_pcte_dcto_dos', {
    is: (val: any) => val === undefined || val > 0,
    then: Yup.date().test(
      'promo_dcto_fin_uno',
      'Este día está en el primer descuento',
      function (value) {
        const promo_dcto_fin_uno = this.parent.promo_dcto_fin_uno;
        return !promo_dcto_fin_uno || !value || value > promo_dcto_fin_uno;
      }
    ),
    otherwise: Yup.date(),
  }),
  promo_dcto_fin_dos: Yup.date().when('promo_pcte_dcto_dos', {
    is: (val: any) => val === undefined || val > 0,
    then: Yup.date().min(
      Yup.ref('promo_dcto_ini_dos'),
      'No puede ser menor fecha inicio.'
    ),
  }),

  otro_promo_fecha_ini: Yup.date().when('otro_promo_pcte_dcto', {
    is: (val: any) => val === undefined || val > 0,
    then: Yup.date().max(
      Yup.ref('otro_promo_fecha_fin'),
      'No puede ser mayor fecha fin.'
    ),
    otherwise: Yup.date(),
  }),
  otro_promo_fecha_fin: Yup.date().when('otro_promo_pcte_dcto', {
    is: (val: any) => val === undefined || val > 0,
    then: Yup.date().min(
      Yup.ref('otro_promo_fecha_ini'),
      'No puede ser menor fecha inicio.'
    ),
  }),
  otro_promo_descripcion: Yup.string().when('otro_promo_pcte_dcto', {
    is: (val: any) => val > 0,
    then: Yup.string().required(
      'Debe agregar una descripción para otros descuentos.'
    ),
    otherwise: Yup.string(),
  }),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { plazas } = useSelectPlazas();

  const formParamsOpera = useFormik({
    initialValues: initValues,
    validationSchema: ParamsOperachema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateParametrosOperativos(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formParamsOpera.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addParametrosOperativos(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formParamsOpera.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-xl"
      show={show}
      onHide={() => {
        formParamsOpera.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formParamsOpera.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            noValidate
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formParamsOpera.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-5">
                <div className="w-100 d-flex">
                  <div className="pe-5  w-25">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Plaza
                      </label>
                      {formParamsOpera.touched.plaza_id &&
                        formParamsOpera.errors.plaza_id && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.plaza_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select mb-3"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      disabled={formParamsOpera.values.id > 0}
                      {...formParamsOpera.getFieldProps('plaza_id')}
                    >
                      <option value={-2}>Seleccione</option>
                      <option value={-1}>Casas</option>
                      <option value={0}>Sin plaza</option>
                      {plazas.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pe-5 w-25">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Descuento contrato XF
                      </label>
                      {formParamsOpera.touched.pcte_dcto_segundo_contrato &&
                        formParamsOpera.errors.pcte_dcto_segundo_contrato && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {
                                formParamsOpera.errors
                                  .pcte_dcto_segundo_contrato
                              }
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="pcte_dcto_segundo_contrato"
                      className="form-control mb-3"
                      value={formParamsOpera.values.pcte_dcto_segundo_contrato}
                      decimalScale={0}
                      placeholder="% 0"
                      prefix="% "
                      isAllowed={(values) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 99;
                      }}
                      onValueChange={(values) => {
                        const { floatValue } = values;

                        formParamsOpera.setFieldValue(
                          'pcte_dcto_segundo_contrato',
                          floatValue
                        );
                      }}
                    />
                  </div>
                  <div className="pe-5 w-25">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Días anticipacion al vencimiento
                      </label>
                      {formParamsOpera.touched.dias_anticipacion_vencimiento &&
                        formParamsOpera.errors
                          .dias_anticipacion_vencimiento && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {
                                formParamsOpera.errors
                                  .dias_anticipacion_vencimiento
                              }
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="dias_anticipacion_vencimiento"
                      className="form-control mb-3"
                      value={
                        formParamsOpera.values.dias_anticipacion_vencimiento
                      }
                      decimalScale={0}
                      fixedDecimalScale
                      placeholder="0"
                      isAllowed={(values) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 99;
                      }}
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formParamsOpera.setFieldValue(
                          'dias_anticipacion_vencimiento',
                          floatValue
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-5">
                <KTSVG
                  path="/media/icons/duotune/general/gen044.svg"
                  className="svg-icon-2tx svg-icon-primary me-4"
                />
                <div className="d-flex flex-stack flex-grow-1">
                  <div className="fw-bold">
                    <div className="fs-6 text-primary">
                      Los cambios realizados el día de hoy se verán reflejados
                      en el mes de <b>{mesSiguiente}</b>.
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-gray-600">Descuento por promoción pago</div>
              <div className="separator my-5"></div>

              <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-3">
                <div className="w-100 d-flex">
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className=" fw-bold fs-6 mb-2 w-auto">
                        Descuento
                      </label>
                      {formParamsOpera.touched.promo_pcte_dcto_uno &&
                        formParamsOpera.errors.promo_pcte_dcto_uno && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.promo_pcte_dcto_uno}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="promo_pcte_dcto_uno"
                      className="form-control mb-3"
                      value={formParamsOpera.values.promo_pcte_dcto_uno}
                      decimalScale={0}
                      fixedDecimalScale
                      placeholder="% 0"
                      prefix="% "
                      isAllowed={(values) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 99;
                      }}
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formParamsOpera.setFieldValue(
                          'promo_pcte_dcto_uno',
                          floatValue
                        );
                      }}
                    />
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-100px">
                        Día inicio
                      </label>
                      {formParamsOpera.touched.promo_dcto_ini_uno &&
                        formParamsOpera.errors.promo_dcto_ini_uno && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.promo_dcto_ini_uno}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={
                        new Date(formParamsOpera.values.promo_dcto_ini_uno)
                          .toISOString()
                          .split('T')[0]
                      }
                      min={minSiguienteMes.toISOString().split('T')[0]}
                      max={maxSiguienteMes.toISOString().split('T')[0]}
                      disabled={
                        formParamsOpera.values.promo_pcte_dcto_uno <= 0 ||
                        formParamsOpera.values.promo_pcte_dcto_uno === undefined
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formParamsOpera.setFieldValue(
                            'promo_dcto_ini_uno',
                            fecha
                          );
                        }
                      }}
                    ></input>
                  </div>
                  <div className="pe-5 " style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-100px">
                        Día fin
                      </label>
                      {formParamsOpera.touched.promo_dcto_fin_uno &&
                        formParamsOpera.errors.promo_dcto_fin_uno && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.promo_dcto_fin_uno}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={
                        new Date(formParamsOpera.values.promo_dcto_fin_uno)
                          .toISOString()
                          .split('T')[0]
                      }
                      min={minSiguienteMes.toISOString().split('T')[0]}
                      max={maxSiguienteMes.toISOString().split('T')[0]}
                      disabled={
                        formParamsOpera.values.promo_pcte_dcto_uno <= 0 ||
                        formParamsOpera.values.promo_pcte_dcto_uno === undefined
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formParamsOpera.setFieldValue(
                            'promo_dcto_fin_uno',
                            fecha
                          );
                        }
                      }}
                    ></input>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-5">
                <div className="w-100 d-flex">
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className=" fw-bold fs-6 mb-2 w-auto">
                        Descuento
                      </label>
                      {formParamsOpera.touched.promo_pcte_dcto_dos &&
                        formParamsOpera.errors.promo_pcte_dcto_dos && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.promo_pcte_dcto_dos}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="promo_pcte_dcto_dos"
                      className="form-control mb-3"
                      value={formParamsOpera.values.promo_pcte_dcto_dos}
                      decimalScale={0}
                      fixedDecimalScale
                      placeholder="% 0"
                      prefix="% "
                      isAllowed={(values) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 99;
                      }}
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formParamsOpera.setFieldValue(
                          'promo_pcte_dcto_dos',
                          floatValue
                        );
                      }}
                    />
                  </div>
                  <div className="pe-5 " style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-100px">
                        Día inicio
                      </label>
                      {formParamsOpera.touched.promo_dcto_ini_dos &&
                        formParamsOpera.errors.promo_dcto_ini_dos && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.promo_dcto_ini_dos}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={
                        new Date(formParamsOpera.values.promo_dcto_ini_dos)
                          .toISOString()
                          .split('T')[0]
                      }
                      min={minSiguienteMes.toISOString().split('T')[0]}
                      max={maxSiguienteMes.toISOString().split('T')[0]}
                      disabled={
                        formParamsOpera.values.promo_pcte_dcto_dos <= 0 ||
                        formParamsOpera.values.promo_pcte_dcto_dos === undefined
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formParamsOpera.setFieldValue(
                            'promo_dcto_ini_dos',
                            fecha
                          );
                        }
                      }}
                    ></input>
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-100px">
                        Día fin
                      </label>
                      {formParamsOpera.touched.promo_dcto_fin_dos &&
                        formParamsOpera.errors.promo_dcto_fin_dos && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.promo_dcto_fin_dos}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={convertToLocalDate(
                        formParamsOpera.values.promo_dcto_fin_dos
                      )}
                      min={minSiguienteMes.toISOString().split('T')[0]}
                      max={maxSiguienteMes.toISOString().split('T')[0]}
                      disabled={
                        formParamsOpera.values.promo_pcte_dcto_dos <= 0 ||
                        formParamsOpera.values.promo_pcte_dcto_dos === undefined
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value);
                          fecha.setDate(fecha.getDate() + 2);
                          fecha.setSeconds(fecha.getSeconds() - 1);
                          formParamsOpera.setFieldValue(
                            'promo_dcto_fin_dos',
                            fecha
                          );
                        }
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="text-gray-600">Otras promociones</div>
              <div className="separator my-5"></div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-5">
                <div className="w-100 d-flex">
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className=" fw-bold fs-6 mb-2 w-auto">
                        Descuento
                      </label>
                      {formParamsOpera.touched.otro_promo_pcte_dcto &&
                        formParamsOpera.errors.otro_promo_pcte_dcto && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.otro_promo_pcte_dcto}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="otro_promo_pcte_dcto"
                      className="form-control mb-3"
                      value={formParamsOpera.values.otro_promo_pcte_dcto}
                      decimalScale={0}
                      fixedDecimalScale
                      placeholder="% 0"
                      prefix="% "
                      isAllowed={(values) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 99;
                      }}
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formParamsOpera.setFieldValue(
                          'otro_promo_pcte_dcto',
                          floatValue
                        );
                      }}
                    />
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-100px">
                        Día inicio
                      </label>
                      {formParamsOpera.touched.otro_promo_fecha_ini &&
                        formParamsOpera.errors.otro_promo_fecha_ini && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.otro_promo_fecha_ini}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={
                        new Date(formParamsOpera.values.otro_promo_fecha_ini)
                          .toISOString()
                          .split('T')[0]
                      }
                      min={minSiguienteMes.toISOString().split('T')[0]}
                      max={maxSiguienteMes.toISOString().split('T')[0]}
                      disabled={
                        formParamsOpera.values.otro_promo_pcte_dcto <= 0 ||
                        formParamsOpera.values.otro_promo_pcte_dcto ===
                          undefined
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formParamsOpera.setFieldValue(
                            'otro_promo_fecha_ini',
                            fecha
                          );
                        }
                      }}
                    ></input>
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-100px">
                        Día fin
                      </label>
                      {formParamsOpera.touched.otro_promo_fecha_fin &&
                        formParamsOpera.errors.otro_promo_fecha_fin && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.otro_promo_fecha_fin}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={convertToLocalDate(
                        formParamsOpera.values.otro_promo_fecha_fin
                      )}
                      min={minSiguienteMes.toISOString().split('T')[0]}
                      //   max={maxSiguienteMes.toISOString().split('T')[0]}
                      disabled={
                        formParamsOpera.values.otro_promo_pcte_dcto <= 0 ||
                        formParamsOpera.values.otro_promo_pcte_dcto ===
                          undefined
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value);
                          fecha.setDate(fecha.getDate() + 2);
                          fecha.setSeconds(fecha.getSeconds() - 1);
                          formParamsOpera.setFieldValue(
                            'otro_promo_fecha_fin',
                            fecha
                          );
                        }
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-5">
                <div className="w-100 d-flex">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Descripción{' '}
                      </label>
                      {formParamsOpera.touched.otro_promo_descripcion &&
                        formParamsOpera.errors.otro_promo_descripcion && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formParamsOpera.errors.otro_promo_descripcion}
                            </span>
                          </div>
                        )}
                    </div>
                    <textarea
                      style={{ resize: 'none' }}
                      className="form-control"
                      disabled={
                        formParamsOpera.values.otro_promo_pcte_dcto <= 0 ||
                        formParamsOpera.values.otro_promo_pcte_dcto ===
                          undefined
                      }
                      rows={2}
                      data-kt-element="input"
                      placeholder="Agregue una descripción"
                      {...formParamsOpera.getFieldProps(
                        'otro_promo_descripcion'
                      )}
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* <div className="text-gray-600">Notificaciones</div>
                            <div className='separator my-5'></div> */}
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formParamsOpera.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
