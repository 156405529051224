import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';

type Props = {
  show: boolean;
  dataEdit: any;
  handleClose: (updateGrid: boolean) => void;
  handleEdit: (data: boolean) => void;
};
const editLocalDetailschema = Yup.object().shape({
  monto: Yup.number()
    .min(1, 'Monto requerido.')
    .required('Monto es requerido.'),
  guante: Yup.number()
    .min(1, 'Depósito requerido.')
    .required('Depósito requerido.'),
  parcialidad: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  fecha_inicio: Yup.date().required('Fecha inicio es requerido.'),
  fecha_fin: Yup.date()
    .min(Yup.ref('fecha_inicio'), 'Debe ser mayor')
    .required('Fecha fin es requerido.'),
});

const EditarLocalesModal: React.FC<Props> = ({
  show,
  dataEdit,
  handleClose,
  handleEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const formEditLocalDetails = useFormik({
    initialValues: dataEdit,
    validationSchema: editLocalDetailschema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        handleEdit(values);
        formEditLocalDetails.resetForm();
        setLoading(false);
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_active_user"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        formEditLocalDetails.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header " id="kt_modal_active_user_header">
          <h2> Editar local</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formEditLocalDetails.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <div
            className="d-flex flex-column scroll-y"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{ default: false, lg: true }"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          ></div>
          <form
            id="kt_modal_detail_form"
            className="form"
            action="#"
            onSubmit={formEditLocalDetails.handleSubmit}
          >
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="w-25 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">Local</label>
                    {formEditLocalDetails.touched.local_id &&
                      formEditLocalDetails.errors.local_id && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEditLocalDetails.errors.local_id}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    value={dataEdit.local.num_local}
                    disabled={true}
                  ></input>
                </div>
                <div className="w-25 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Mensualidad
                    </label>
                    {formEditLocalDetails.touched.monto &&
                      formEditLocalDetails.errors.monto && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEditLocalDetails.errors.monto}
                          </span>
                        </div>
                      )}
                  </div>
                  <NumericFormat
                    name="monto"
                    className="form-control mb-3"
                    value={formEditLocalDetails.values.monto}
                    decimalScale={2}
                    decimalSeparator="."
                    fixedDecimalScale
                    placeholder="$ 0.00"
                    prefix="$ "
                    thousandSeparator=","
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      formEditLocalDetails.setFieldValue(
                        'monto',
                        floatValue ? floatValue : 0
                      );
                    }}
                  />
                </div>
                <div className="w-25 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">Depósito</label>
                    {formEditLocalDetails.touched.guante &&
                      formEditLocalDetails.errors.guante && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEditLocalDetails.errors.guante}
                          </span>
                        </div>
                      )}
                  </div>
                  <NumericFormat
                    name="guante"
                    className="form-control mb-3"
                    value={formEditLocalDetails.values.guante}
                    decimalScale={2}
                    decimalSeparator="."
                    fixedDecimalScale
                    placeholder="$ 0.00"
                    prefix="$ "
                    thousandSeparator=","
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      formEditLocalDetails.setFieldValue(
                        'guante',
                        floatValue ? floatValue : 0
                      );
                    }}
                  />
                </div>
                <div className="w-25 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Parcialidades depósito
                    </label>
                    {formEditLocalDetails.touched.parcialidad &&
                      formEditLocalDetails.errors.parcialidad && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEditLocalDetails.errors.parcialidad}
                          </span>
                        </div>
                      )}
                  </div>
                  <select
                    className="form-select mb-8"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...formEditLocalDetails.getFieldProps('parcialidad')}
                  >
                    <option value={1}>1</option>
                    {/* <option value={3}>3</option>
                    <option value={6}>6</option>
                    <option value={9}>9</option>
                    <option value={12}>12</option> */}
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="w-25 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Fecha inicio
                    </label>
                    {formEditLocalDetails.touched.fecha_inicio &&
                      formEditLocalDetails.errors.fecha_inicio && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEditLocalDetails.errors.fecha_inicio}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(formEditLocalDetails.values.fecha_inicio)
                        .toISOString()
                        .split('T')[0]
                    }
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        formEditLocalDetails.setFieldValue(
                          'fecha_inicio',
                          fecha
                        );
                      }
                    }}
                  ></input>
                </div>
                <div className="w-25 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-95px">
                      Fecha fin
                    </label>
                    {formEditLocalDetails.touched.fecha_fin &&
                      formEditLocalDetails.errors.fecha_fin && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {formEditLocalDetails.errors.fecha_fin}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(formEditLocalDetails.values.fecha_fin)
                        .toISOString()
                        .split('T')[0]
                    }
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        formEditLocalDetails.setFieldValue('fecha_fin', fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </form>
          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                formEditLocalDetails.resetForm();
                handleClose(false);
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={() => {
                formEditLocalDetails.submitForm();
              }}
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Confirmar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { EditarLocalesModal };
