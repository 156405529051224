import React, { FC } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { View } from './components/View';

const SemaforoPagosPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Semáforo pagos</PageTitle>
      <View />
    </>
  );
};

export { SemaforoPagosPage };
