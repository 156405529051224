/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { ResultModel } from '../models/ResultModel';

type Props = {
  className: string;
  data: Array<ResultModel>;
};

const ResultsList: React.FC<Props> = ({ className, data }) => {
  return (
    <div className="card card-xl-stretch mb-5 mb-xl-8">
      <div className="card-header border-0">
        <h3 className="card-title fw-bolder text-dark">Ingresos</h3>
      </div>
      <div className="card-body pt-0">
        <div className="col-sm-12">
          <div className="row gy-5 g-xl-12">
            {data.map((item, index) => {
              return (
                <div className="col-xl-12" key={index}>
                  <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-1">
                    <span className="svg-icon text-gray-800 me-5">
                      <KTSVG
                        path="/media/icons/duotune/finance/fin010.svg"
                        className="svg-icon-1"
                      />
                    </span>
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="fw-bolder text-gray-800 text-hover-primary fs-6"
                      >
                        {item.plaza.nombre}
                      </a>
                      <span className=" fw-bold d-block text-gray-800">
                        Utilidad: {formatNumber(item.ingreso)}
                      </span>
                    </div>
                    <div className="flex-grow-1 me-2">
                      {cardData('Cargo:', item.total_cargo)}
                      {cardData('Abono:', item.total_abono)}
                      {cardData('Gasto:', item.total_gasto)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

function cardData(title: string, value: number) {
  return (
    <div className="d-flex flex-column flex-xl-row flex-row-fluid mb-0">
      <div className="w-50 d-flex">
        <span className="fw-bolder py-1 w-100 text-end text-gray-800">
          {title}
        </span>
      </div>
      <div className="w-50 d-flex">
        <span className="fw-bolder py-1 w-100 text-end text-gray-800">
          {formatNumber(value)}
        </span>
      </div>
    </div>
  );
}

function formatNumber(value: number) {
  return Number(value).toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export { ResultsList };
