import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getAllTipoCP( startIndex:number, endIndex:number, filters:any ) {

    let search : string = ''; 
    if( filters ){
        if( filters.descripcion !== "" ) search += `&descripcionOpLike=%${filters.descripcion}%`;
    }
    const response = await axios.get(`${API_URL}catalogos/tiposCuentaPago?sort=id&limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function getOneTipoCP( id_row:number ) {


    const response = await axios.get(`${API_URL}catalogos/tiposCuentaPago/${id_row}`);
    const { id, descripcion, frecuencia } = response.data.doc.data;
    return {
        id: id || 0,
        descripcion : descripcion || "",
        frecuencia_id: frecuencia.id || 0,
    };

}
export async function addTipoCP( body:any ) {

    const { descripcion, frecuencia_id } = body;
    const data = {
        descripcion: descripcion,
        frecuencia_id: frecuencia_id
    }
    const response = await axios.post(`${API_URL}catalogos/tiposCuentaPago`, data);
    return response;

}
export async function updateTipoCP( body:any ) {

    const { id, descripcion, frecuencia_id } = body;
    const response = await axios.put(`${API_URL}catalogos/tiposCuentaPago/${id}`, {
        descripcion, 
        frecuencia_id
    });
    return response;

}
export async function removeTipoCP( id:number ) {

    const response = await axios.delete(`${API_URL}catalogos/tiposCuentaPago/${id}`);
    return response;

}
export async function getSelectTipoCP() {

    const response = await axios.get(`${API_URL}catalogos/tiposCuentaPago`);
    const data = response.data.doc.data;
    return data;

}