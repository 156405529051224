import React, { FC } from 'react'
import { PageTitle, PageLink } from '../../../_metronic/layout/core'
import { ListGrid } from './components/ListGrid'
   
const Usuarios: FC = () => (
    <>
         <div className='row gy-5 g-xl-8'>
            <div className='col-xl-12'>
              <ListGrid className='card-xxl-stretch mb-5 mb-xl-12' />
            </div>
         </div>
    </>
)

const widgetsBreadCrumbs: Array<PageLink> = []

const UsuariosPage: FC = () => {
    return (
      <>
        <PageTitle breadcrumbs={widgetsBreadCrumbs}>Usuarios</PageTitle>
        <Usuarios />
      </>
    )
}

export { UsuariosPage }