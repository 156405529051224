import React, { useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addPlaza, updatePlaza } from '../../../../service/catalog/Catalogs';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/PlazaHook';
import { NumericFormat } from 'react-number-format';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const PlazasSchema = Yup.object().shape({
  nombre: Yup.string()
    .max(100, 'No debe tener más de 100 caracteres')
    .required('Nombre es requerido.'),
  codigo_plaza: Yup.string()
    .max(5, 'Máximo 5 caracteres')
    .required('Requerido.'),
  encargado: Yup.string().max(100, 'No debe tener más de 100 caracteres'),
  num_locales: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  num_estacionamientos: Yup.number().required('Requerido.'),
  socio: Yup.boolean(),
  nombre_socio: Yup.string().when('socio', {
    is: true,
    then: Yup.string()
      .max(100, 'No debe tener más de 100 caracteres')
      .required('Nombre de socio requerido.'),
  }),
  num_int: Yup.string(),
  num_ext: Yup.string(),
  codigo_postal: Yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    'Sólo numeros'
  ),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);

  const formPlazas = useFormik({
    initialValues: initValues,
    validationSchema: PlazasSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updatePlaza(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formPlazas.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addPlaza(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formPlazas.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      onHide={() => {
        formPlazas.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formPlazas.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formPlazas.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="text-gray-600">Información general</div>
              <div className="separator my-5"></div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-75 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Nombre
                      </label>
                      {formPlazas.touched.nombre && formPlazas.errors.nombre && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">{formPlazas.errors.nombre}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Nombre"
                      {...formPlazas.getFieldProps('nombre')}
                      className="form-control mb-3"
                      type="text"
                      name="nombre"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Código
                      </label>
                      {formPlazas.touched.codigo_plaza &&
                        formPlazas.errors.codigo_plaza && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formPlazas.errors.codigo_plaza}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Código"
                      maxLength={5}
                      style={{ textTransform: 'uppercase' }}
                      {...formPlazas.getFieldProps('codigo_plaza')}
                      className="form-control mb-3"
                      type="text"
                      // name='codigo_plaza'
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Encargado
                      </label>
                      {formPlazas.touched.encargado &&
                        formPlazas.errors.encargado && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formPlazas.errors.encargado}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Encargado"
                      {...formPlazas.getFieldProps('encargado')}
                      className="form-control mb-3"
                      type="text"
                      name="encargado"
                      autoComplete="off"
                    />
                  </div>

                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        No. Locales
                      </label>
                      {formPlazas.touched.num_locales &&
                        formPlazas.errors.num_locales && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formPlazas.errors.num_locales}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="num_locales"
                      className="form-control mb-3"
                      value={formPlazas.values.num_locales}
                      decimalScale={0}
                      decimalSeparator="."
                      maxLength={4}
                      fixedDecimalScale
                      placeholder="0"
                      prefix=""
                      thousandSeparator=""
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formPlazas.setFieldValue(
                          'num_locales',
                          floatValue ? floatValue : 0
                        );
                      }}
                    />
                    {/* <input
                                        placeholder="No. Locales"
                                        {...formPlazas.getFieldProps('num_locales')}
                                        className="form-control mb-3"
                                        type='number'
                                        name='num_locales'
                                        onChange={ (e) => {
                                            formPlazas.setFieldValue("num_locales", e.target.value.slice(0, 3));

                                        }}
                                        autoComplete='off'
                                        /> */}
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        No. Estacionamientos
                      </label>
                      {formPlazas.touched.num_estacionamientos &&
                        formPlazas.errors.num_estacionamientos && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formPlazas.errors.num_estacionamientos}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="No. Estacionamientos"
                      {...formPlazas.getFieldProps('num_estacionamientos')}
                      className="form-control mb-3"
                      type="number"
                      min={0}
                      onChange={(e) => {
                        formPlazas.setFieldValue(
                          'num_estacionamientos',
                          e.target.value.slice(0, 3) === ''
                            ? 0
                            : e.target.value.slice(0, 3)
                        );
                      }}
                      name="num_estacionamientos"
                      autoComplete="off"
                    />
                  </div>
                </div>{' '}
              </div>

              <div className="text-gray-600 mt-5">Dirección</div>
              <div className="separator my-5"></div>

              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Calle</label>
                      {formPlazas.touched.calle && formPlazas.errors.calle && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formPlazas.errors.calle}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Calle"
                      {...formPlazas.getFieldProps('calle')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={100}
                      name="calle"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        No. Exterior
                      </label>
                      {formPlazas.touched.num_ext && formPlazas.errors.num_ext && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formPlazas.errors.num_ext}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="No. Exterior"
                      {...formPlazas.getFieldProps('num_ext')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={10}
                      name="num_ext"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        No. Interior
                      </label>
                      {formPlazas.touched.num_int && formPlazas.errors.num_int && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formPlazas.errors.num_int}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="No. Interior"
                      {...formPlazas.getFieldProps('num_int')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={10}
                      name="num_int"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-75 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Colonia
                      </label>
                      {formPlazas.touched.colonia && formPlazas.errors.colonia && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formPlazas.errors.colonia}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Colonia"
                      {...formPlazas.getFieldProps('colonia')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      name="colonia"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">C.P.</label>
                      {formPlazas.touched.codigo_postal &&
                        formPlazas.errors.codigo_postal && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formPlazas.errors.codigo_postal}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="C.P."
                      {...formPlazas.getFieldProps('codigo_postal')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={5}
                      name="codigo_postal"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Ciudad</label>
                      {formPlazas.touched.ciudad && formPlazas.errors.ciudad && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formPlazas.errors.ciudad}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Ciudad"
                      {...formPlazas.getFieldProps('ciudad')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      name="ciudad"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Estado</label>
                      {formPlazas.touched.estado && formPlazas.errors.estado && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formPlazas.errors.estado}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Estado"
                      {...formPlazas.getFieldProps('estado')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      name="estado"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div className="separator my-5"></div>

              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-400px">
                  <div className="mt-10 mb-5 mx-auto w-50">
                    <label className="form-check form-switch form-check-custom form-check-solid">
                      <span className="form-check-label fw-bold me-5">
                        Incluir socio
                      </span>
                      <input
                        className="form-check-input"
                        name="socio"
                        type="checkbox"
                        checked={formPlazas.values.socio}
                        onChange={(e) => {
                          formPlazas.handleChange(e);
                          if (formPlazas.values.socio) {
                            formPlazas.setFieldValue('nombre_socio', '');
                            formPlazas.setFieldTouched(
                              'nombre_socio',
                              false,
                              false
                            );
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="flex-row-fluid">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label
                        className={
                          formPlazas.values.socio
                            ? 'required fw-bold fs-6 mb-2 w-auto'
                            : 'fw-bold fs-6 mb-2 w-auto text-gray-500'
                        }
                      >
                        Nombre del socio
                      </label>
                      {formPlazas.touched.nombre_socio &&
                        formPlazas.errors.nombre_socio && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formPlazas.errors.nombre_socio}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Nombre"
                      {...formPlazas.getFieldProps('nombre_socio')}
                      className="form-control mb-3"
                      type="text"
                      name="nombre_socio"
                      disabled={!formPlazas.values.socio}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formPlazas.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
