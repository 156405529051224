import { useState, useEffect } from 'react'
import { getAllTipoCP, getOneTipoCP, getSelectTipoCP } from '../../../../service/catalog/TipoCuentasPagar'
import { TipoCuentaPagoModel } from '../models/TipoCuentaPagoModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<TipoCuentaPagoModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllTipoCP( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid ]);
    return { data, count, loadingRequest }
}

export const useGetOne = ( id:number ) => {
    const initModel = {
        id: 0,
        descripcion: '',
        frecuencia_id: 0,
    }
    const [ initValues, setInitValues ] = useState<TipoCuentaPagoModel>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOneTipoCP( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}
export const useSelect = ( ) => {

    const [ data, setData ] = useState<TipoCuentaPagoModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getSelectTipoCP().then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { data }
}
