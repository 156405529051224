

import React, { useState, useRef } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { addContratoFirmado } from '../../../service/contrato_local/ContratoLocal'
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/ContratoLocalHook'

type Props = {
    show: boolean
    handleClose: ( updateGrid:boolean ) => void,
    title: string,
    id_row: number
}
const ContratoFirmadoSchema = Yup.object().shape({
    // plaza_id: Yup.number()
    //     .min(1, 'Seleccione una plaza.')
    //     .required("Plaza es equerido."),
})

const AddContratoModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {

    const [loading, setLoading] = useState(false)
    const { initValues } = useGetOne( id_row );
    const contratoRef = useRef<HTMLInputElement>(null);

    const onOpenFile = () => {
        contratoRef?.current?.click();
    }
    
    const formContratoFirmado = useFormik({
        initialValues: initValues,
        validationSchema: ContratoFirmadoSchema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            
            if( !values.file  && values.contrato_firmado === ''){
                toast.error('Por favor seleccione un documento válido.', {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSubmitting(false);
            } else {

                setLoading(true)
                setTimeout(() => {
                    addContratoFirmado( values )
                        .then(( res ) => {
        
                            const { data: { message } } = res;
                            toast.success(message, {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                            formContratoFirmado.resetForm()
                            setLoading(false);
                            handleClose( true )
                        })
                        .catch(( err ) => {
                            let resMessageToast: string = ""
                            const { data: { message, details = null } } = err.response;
                            resMessageToast =`${message}`;
                            if( details.length ){
                                setStatus(`${details[0]?.message}`)
                            }
                            setLoading(false)
                            setSubmitting(false)
                            toast.error(resMessageToast, {
                              position: "top-right",
                              autoClose: 4000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                        })
    
                }, 1000 );
            }
        
        },
    })
    return (
        <Modal
        id='kt_modal_create'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-650px'
        show={ show }
        onHide={ () => {
            formContratoFirmado.resetForm()
            handleClose( false )
        } }
        >
            <div className='modal-content'>
                <div className='modal-header'>
                    <h2>{ title }</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={ () => {
                        formContratoFirmado.resetForm()
                        handleClose( false )
                    } }>
                        <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className="modal-body scroll-y mx-5 ">
                    <form id="kt_modal_generic_form" className="form" action="#"
                        onSubmit={ formContratoFirmado.handleSubmit }
                    >
                        <div className='d-flex flex-column flex-xl-row flex-row-fluid'>
                            <div className='card-body d-flex justify-content-center text-center flex-column p-2'>
                                <a href='#/' className='text-gray-800 text-hover-primary d-flex flex-column' >
                                    <div className='symbol symbol-75px mb-6'>
                                        <img src={ ( formContratoFirmado.values.file || formContratoFirmado.values.contrato_firmado ) ? toAbsoluteUrl('/media/svg/files/doc.svg') : toAbsoluteUrl('/media/svg/files/upload.svg') }
                                        className="image-input-wrapper"
                                        onClick={ onOpenFile }
                                        crossOrigin='anonymous'
                                        alt='INE'
                                        />
                                    </div>
                                    <div className='required fs-5 fw-bolder mb-2' onClick={ onOpenFile }>CONTRATO FIRMADO</div>
                                    <input style={{display: 'none'}} ref={ contratoRef } type="file" name="contrato" accept=".doc, .docx, .pdf" onChange={( event ) => {
                                        if (event.currentTarget.files) {
                                            let files = event.currentTarget.files;
                                            if(files.length > 0){
                                                formContratoFirmado.setFieldValue(
                                                    "file",
                                                    files[0]
                                                );
                                            }
                                        }
                                        }}
                                        />
                                </a>
                                <div className='fs-7 text-gray-400 mt-auto'> Archivos permitidos: doc, docx, pdf. </div>
                            </div>
                        </div>
                        <div className="text-center pt-15">
                            <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel" onClick={ () => {
                                formContratoFirmado.resetForm()
                                handleClose( false )
                            } }>Cancelar</button>
                            <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit" disabled={loading}>

                            {!loading && <span className='indicator-label'>
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr003.svg'
                                    className='svg-icon-5 svg-icon-gray-500'
                                />
                                Guardar</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Espere por favor...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )

}
export { AddContratoModal }