import { useState, useEffect } from 'react'
import { getAllPlazas, getOnePlazas, getSelectPlazas, getComboPlazas } from '../../../../service/catalog/Catalogs'
import { PlazaModel } from '../models/PlazaModel'
import { useHistory } from 'react-router-dom'


export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:string ) => {

    const [ data, setData ] = useState<PlazaModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()

    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllPlazas( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid ]);
    return { data, count, loadingRequest }
}

export const useGetOne = ( id:number ) => {
    const initModel = {
        calle : "",
        codigo_plaza: "",
        ciudad: "",
        codigo_postal: "",
        colonia: "",
        encargado: "",
        estado: "",
        id: 0,
        nombre: "",
        nombre_socio: "",
        num_estacionamientos: 0,
        num_ext: "",
        num_int: "",
        num_locales: 0,
        socio: false
    }
    const history = useHistory()
    const [ initValues, setInitValues ] = useState<PlazaModel>(initModel);
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOnePlazas( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}

export const useSelectPlazas = ( ) => {

    const [ plazas, setPlazas ] = useState<PlazaModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getSelectPlazas().then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setPlazas(res.rows);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { plazas }
}
export const useComboPlazas = ( ) => {

    const [ plazas, setPlazas ] = useState<PlazaModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getComboPlazas().then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setPlazas(res.rows);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { plazas }
}
