import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetRoles, useGetUser } from "../hooks/userHook";
import { UserModel } from "../../../modules/auth/models/UserModel";
import { addRol, removeRol } from '../../../service/users/Usuarios'
import { toast } from 'react-toastify';

type Props = {
    userCurrentId: number,
    show: boolean
    id: number,
    handleClose: ( updateGrid:boolean ) => void
}
const rolesSchema = Yup.object().shape({
    first_name: Yup.string()
        .max(20, "El nombre no debe tener más de 20 caracteres")
        .required("Nombre es requerido."),
    email: Yup.string()
        .email('Formato de correo inválido.')
        .min(3, 'Mínimo 3 caracteres.')
        .max(50, 'Máximo 50 caracteres.')
        .required('Correo es requerido.'),
    roles: Yup.array().min(1, 'Debe seleccionar al menos un role'),
})

let init: UserModel = {
    id: 0,
    alias: "",
    first_name: "",
    father_last_name: "",
    mother_last_name: "",
    email: "",
    phone: "",
    profile_photo: "",
    company: "",
    active: false,
    roles: [],
}

const AddRolesUserModal: React.FC<Props> = ({ userCurrentId, show, id, handleClose }) => {

    const [reloadInfo, setReloadInfo] = useState(false);
    const [statusError, setStatusError] = useState('');
    const { user } = useGetUser(id, reloadInfo, init );
    const { roles } = useGetRoles();
    const [loading, setLoading] = useState(false)
    const [loadingRoles, setLoadingRoles] = useState(false)
    const [userSelected, setUserSelected] = useState(user);
    const [idRole, setIdRole] = useState<number>(1)


    useEffect(() => {
        if(id){
            setReloadInfo(!reloadInfo);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if(user){
            setUserSelected(user);
        }
    }, [ user ]);

    const formikRoles = useFormik({
        initialValues: userSelected,
        validationSchema: rolesSchema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting }) => {
           
        },
    })

    const addRole = () => {

            const values = {
                id: userSelected.id,
                roleId: idRole
            }
            setLoading(true)
            setLoadingRoles(true);
            setStatusError('')
            setTimeout(() => {
                addRol( values )
                    .then(( res ) => {
                        setReloadInfo(!reloadInfo)
                        const { data: { message } } = res;
                        toast.success(message, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                        setLoading(false);
                        setLoadingRoles(false);
                        
                    })
                    .catch(( err ) => {
                        let resMessageToast: string = ""
                        const { data: { message, details = null } } = err.response;
                        resMessageToast =`${message}`;
                        if( details ){
                            setStatusError(`${details[0]?.message}`)
                        }
                        setLoading(false)
                        setLoadingRoles(false);
                        toast.error(resMessageToast, {
                          position: "top-right",
                          autoClose: 4000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                    })
            }, 1000);

    };
    const selectedRole = ( e:any ) => {
        const idSelected = e.target.value;
        setIdRole(idSelected)
    };
    const removeRole = ( id:number ) => {

        const values = {
            id: userSelected.id,
            roleId: id
        }
        setStatusError('')
        setLoadingRoles(true)
        setTimeout(() => {
            removeRol( values )
                .then(( res ) => {
                    const { data: { message } } = res;
                    if(id === 1 && userCurrentId === userSelected.id){
                        window.location.reload();
                    }
                    toast.success(message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setReloadInfo(!reloadInfo)
                    setLoadingRoles(false)
                })
                .catch(( err ) => {
                    let resMessageToast: string = ""
                    const { data: { message, details = null } } = err.response;
                    resMessageToast =`${message}`;
                    if( details.length ){
                        setStatusError(`${details[0]?.message}`)
                    }
                    toast.error(resMessageToast, {
                      position: "top-right",
                      autoClose: 4000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setLoadingRoles(false)
                })
        }, 1000);
    };


    return (
        <Modal
        id='kt_modal_active_user'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
        show={ show }
        onHide={ () => {
            handleClose( true )
        } }
        >
            <div className='modal-content'>
                <div className='modal-header ' id='kt_modal_active_user_header'>
                    <h2>Roles de usuario</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={ () => {
                        handleClose( true )
                    } }>
                    <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className="modal-body mx-5">
                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-2tx svg-icon-warning me-4'
                    />

                    <div className='d-flex flex-stack flex-grow-1'>
                        <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>Asignación de roles</h4>
                        <div className='fs-6 text-gray-600'>
                            Los roles se guardar automaticamente al momento de la asignación al usuario, tenga en cuenta que si cierra esta ventana los datos quedarán guardados.
                            <br />
                        </div>
                        </div>
                    </div>
                </div>
                        {
                            statusError && (
                            <div className='alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{ statusError }</div>
                            </div>
                            )
                        }
                        <div className='d-flex flex-column flex-xl-row flex-row-fluid'>
                            <div className='d-flex flex-row-auto w-100 w-xl-400px'>
                                <div className="d-flex w-xl-70px">
                                    <label className=" fw-bold fs-6 mb-2">Roles</label>
                                </div>
                                <div className="d-flex flex-row-auto h-40px">
                                    <select
                                        className='form-select form-select-solid'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                        defaultValue={ idRole }
                                        onChange={( e:any ) => {
                                            selectedRole( e );
                                        }} 
                                        >
                                        {roles.map(( item, index ) =>(
                                            <option key={index} value={ item.id }>{item.name}</option>
                                        ))}                                    
                                    </select>
                                </div>
                                <div className="flex-row-fluid">
                                    {/* <button type="submit" className="btn btn-sm btn-light-primary ms-5" onClick={ addRole } data-kt-users-modal-action="submit" disabled={loading}>
                                        Agregar
                                    </button> */}
                                    <button type="submit" className="btn btn-sm btn-primary ms-5" onClick={ addRole } disabled={loading}>
                                    {!loading && <span className='indicator-label'>Agregar</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            <span className='spinner-border spinner-border-sm align-middle'></span>
                                        </span>
                                    )}
                                    </button>
                                </div>
                                
                            </div>
                            <div className='flex-row-fluid'>
                                <form id="kt_modal_add_user_form" className="form" action="#" onSubmit={ formikRoles.handleSubmit }>
                                    <div className="d-flex flex-column scroll-y pe-7" id="kt_modal_add_user_scroll" 
                                    data-kt-scroll="true" data-kt-scroll-activate='{ default: false, lg: true }' data-kt-scroll-max-height="auto" 
                                    data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" 
                                    data-kt-scroll-offset="300px">
                                        <div className="fv-row mb-7">
                                            <label className="fw-bold fs-6 mb-2">Nombre</label>
                                            <input
                                            placeholder="Nombre"
                                            {...formikRoles.getFieldProps('first_name')}
                                            className="form-control mb-3 mb-lg-0"
                                            type='text'
                                            name='first_name'
                                            disabled={ true }
                                            autoComplete='off'
                                            />
                                            {formikRoles.touched.first_name && formikRoles.errors.first_name && (
                                                <div className='fv-plugins-message-container text-danger mt-3'>
                                                    <span role='alert'>{formikRoles.errors.first_name}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-7">
                                            <div className="d-flex fv-row">
                                            <label className="required fw-bold fs-6 mb-5 me-5">Roles</label>
                                            {formikRoles.touched.roles && formikRoles.errors.roles && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert'>{ formikRoles.errors.roles }</span>
                                                </div>
                                            )}
                                            </div>

                                            {loadingRoles ? (
                                                <div className='text-center'>
                                                    <span className='spinner-border text-primary m-10'></span>
                                                </div>
                                            ) : (
                                                formikRoles.values.roles.map(( item:any, index:number ) =>(
                                                    <div key={ item.id }>
                                                            <div className="d-flex fv-row">
                                                                <div className="form-check form-check-custom form-check-solid">
                                                                    <label className="form-check-label w-450px">
                                                                        <div className="fw-bolder text-gray-800">{ item.name }</div>
                                                                        <div className="text-gray-600">{ item.description }</div>
                                                                    </label>
    
                                                                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={ () => {
                                                                        removeRole( item.id )
                                                                    } }>
                                                                    <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        <div className='separator separator-dashed my-5'></div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="text-center pt-15">
                        <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel" onClick={ () => {
                            formikRoles.resetForm()
                            handleClose( true )
                        } }>Cerrar</button>
                    </div> 
                </div>
            </div>
        </Modal>
    )
}
export { AddRolesUserModal }
