import { useState, useEffect } from 'react';
import {
  getAllCPP,
  getAllCPPWithoutPage,
  getOneCPP,
} from '../../../service/cuentas_pagar/CuentasPagar';
import { CuentasPagarModel } from '../models/CuentasPagarModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<CuentasPagarModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllCPP(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, realoadGrid]);
  return { data, count, loadingRequest };
};

export const useGetAllWithoutPages = (realoadGrid: number, filters: any) => {
  const [dataExcel, setDataExcel] = useState<CuentasPagarModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllCPPWithoutPage(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataExcel(res.rows);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { dataExcel };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    descripcion: '',
    tipo_cuenta_pago_id: 0,
    plaza_id: 0,
    fecha: new Date(),
    monto: 0,
  };
  const [initValues, setInitValues] = useState<CuentasPagarModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getOneCPP(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues };
};
