import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { ListGrid } from './ListGrid';
import { useFormik } from 'formik';
import { KTSVG } from '../../../../_metronic/helpers';
import { useGetMovimientos } from '../hooks/PagosHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  id_row: number;
  contrato: any;
};
const MovimientosSchema = Yup.object().shape({
  // anio: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  // mes: Yup.number().min(1, 'Requerido.').required('Requerido.'),
});

let fechaActual = new Date();
let fecha_inicio_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth(),
  1
);
let fecha_fin_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 1,
  0
);
const values = {
  id: 0,
  tipo_movimiento: 0,
  fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
  fecha_fin: fecha_fin_init.toISOString().split('T')[0],
};

const MovimientosModal: React.FC<Props> = ({
  show,
  id_row,
  handleClose,
  contrato,
}) => {
  const [initValues, setInitValues] = useState(values);
  const [loading, setLoading] = useState(false);

  const [initFilterValues, setInitFilterValues] = useState({
    id: 0,
    tipo_movimiento: 0,
    fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
    fecha_fin: fecha_fin_init.toISOString().split('T')[0],
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count } = useGetMovimientos(
    pageSize,
    currentPage,
    initFilterValues
  );
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    setInitValues({
      id: id_row,
      tipo_movimiento: 0,
      fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
      fecha_fin: fecha_fin_init.toISOString().split('T')[0],
    });
  }, [id_row]);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  const formMovimientos = useFormik({
    initialValues: initValues,
    validationSchema: MovimientosSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setInitFilterValues({
        id: Number(values.id),
        tipo_movimiento: Number(values.tipo_movimiento),
        fecha_inicio: values.fecha_inicio,
        fecha_fin: values.fecha_fin,
      });
    },
  });
  return (
    <Modal
      id="kt_modal_movimientos"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-xl"
      show={show}
      onHide={() => {
        setInitFilterValues({
          id: 0,
          tipo_movimiento: 0,
          fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
          fecha_fin: fecha_fin_init.toISOString().split('T')[0],
        });
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>Movimientos</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              setInitFilterValues({
                id: 0,
                tipo_movimiento: 0,
                fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
                fecha_fin: fecha_fin_init.toISOString().split('T')[0],
              });
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <div
            className="d-flex flex-column scroll-y"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{ default: false, lg: true }"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <form
              id="kt_modal_generic_form"
              className="form"
              action="#"
              onSubmit={formMovimientos.handleSubmit}
            >
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="pe-5" style={{ width: '35%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Tipo de Movimiento
                      </label>
                      {formMovimientos.touched.tipo_movimiento &&
                        formMovimientos.errors.tipo_movimiento && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formMovimientos.errors.tipo_movimiento}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select mb-3"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...formMovimientos.getFieldProps('tipo_movimiento')}
                    >
                      <option value={0}>Todos</option>
                      <option value={1}>Cargo</option>
                      <option value={2}>Abono</option>
                    </select>
                  </div>
                  <div className="pe-5" style={{ width: '20%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Fecha inicio
                      </label>
                      {formMovimientos.touched.fecha_inicio &&
                        formMovimientos.errors.fecha_inicio && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formMovimientos.errors.fecha_inicio}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={
                        new Date(formMovimientos.values.fecha_inicio)
                          .toISOString()
                          .split('T')[0]
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formMovimientos.setFieldValue('fecha_inicio', fecha);
                        }
                      }}
                    ></input>
                  </div>
                  <div className="pe-5" style={{ width: '20%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Fecha fin
                      </label>
                      {formMovimientos.touched.fecha_fin &&
                        formMovimientos.errors.fecha_fin && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formMovimientos.errors.fecha_fin}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={
                        new Date(formMovimientos.values.fecha_fin)
                          .toISOString()
                          .split('T')[0]
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formMovimientos.setFieldValue('fecha_fin', fecha);
                        }
                      }}
                    ></input>
                  </div>
                  <div className="pe-5" style={{ width: '25%' }}>
                    <div className="w-100 text-end">
                      <button
                        type="button"
                        onClick={() => {
                          formMovimientos.submitForm();
                        }}
                        className="btn btn-sm btn-primary mt-10"
                        data-kt-users-modal-action="submit"
                        disabled={loading}
                      >
                        {!loading && (
                          <span className="indicator-label">Buscar</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Buscando...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-10">
              <ListGrid
                data={data}
                loading={loading}
                count={count}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(pageNumber: number) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { MovimientosModal };
