import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getCatalog( code:string ) {

    const response = await axios.get(`${API_URL}catalogs/${ code }?sort=order`);
    const data = response.data.doc.data;
    return data;

}
export async function getCatalogGrid( code:string, startIndex:number, endIndex:number, filters:any  ) {

    let search : string = ''; 
    if( filters ){
        search = `&nameOpLike=%${filters}%`;
    }
    const response = await axios.get(`${API_URL}catalogs/${ code }?sort=order&limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function addValueCatalog( code_catalogo:string, body:any ) {

    const { code, name, order } = body;
    const data = {
        code: code,
        name: name,
        order: order,
    }
    const response = await axios.post(`${API_URL}catalogs/${ code_catalogo }`, data);
    return response;

}
export async function removeRegisterCatalog( id:number ) {

    const response = await axios.delete(`${API_URL}catalogs/${id}`);
    return response;

}
export async function getAllPlazas( startIndex:number, endIndex:number, filters:any ) {

    let search : string = ''; 
    if( filters ){
        search = `&nombreOpLike=%${filters}%`;
    }
    const response = await axios.get(`${API_URL}catalogos/plazas?limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function getSelectPlazas() {

    const response = await axios.get(`${API_URL}catalogos/plazas`);
    const data = response.data.doc.data;
    return data;

}
export async function getComboPlazas() {

    const response = await axios.get(`${API_URL}catalogos/plazasusuario/plazas?sort=id`);
    const data = response.data.doc.data;
    return data;

}

export async function getOnePlazas( id_row:number ) {


    const response = await axios.get(`${API_URL}catalogos/plazas/${id_row}`);
    const { id, nombre, socio, nombre_socio, num_estacionamientos, estado, calle, ciudad, codigo_postal, colonia, encargado, num_ext, num_int, num_locales, codigo_plaza } = response.data.doc.data;
    return {
        calle : calle || "",
        codigo_plaza : codigo_plaza || "",
        ciudad: ciudad || "",
        codigo_postal: codigo_postal || "",
        colonia: colonia || "",
        encargado: encargado || "",
        estado: estado || "",
        id: id || 0,
        nombre: nombre || "",
        nombre_socio: nombre_socio || "",
        num_estacionamientos: num_estacionamientos || 0,
        num_ext: num_ext || "",
        num_int: num_int || "",
        num_locales: num_locales || 0,
        socio: socio || false
    };

}
export async function addPlaza( body:any ) {

    body.codigo_plaza = body.codigo_plaza.toUpperCase();
    const response = await axios.post(`${API_URL}catalogos/plazas/`, body);
    return response;

}
export async function updatePlaza( body:any ) {

    const { id, nombre, socio, nombre_socio, num_estacionamientos, estado, calle, ciudad, codigo_postal, colonia, encargado, num_ext, num_int, num_locales, codigo_plaza } = body;
    const response = await axios.put(`${API_URL}catalogos/plazas/${id}`, {
        nombre, 
        encargado, 
        num_locales,
        num_estacionamientos,
        calle, 
        num_int,
        num_ext, 
        colonia,
        codigo_postal, 
        ciudad,
        estado,
        socio, 
        nombre_socio,
        codigo_plaza: codigo_plaza.toLocaleUpperCase()
    });
    return response;

}
export async function removePlaza( id:number ) {

    const response = await axios.delete(`${API_URL}catalogos/plazas/${id}`);
    return response;

}

export async function getAllLocales( startIndex:number, endIndex:number, filters:any ) {

    let search : string = ''; 
    if( filters ){
        if( filters.num_local !== "" ) search += `&num_localOpLike=%${filters.num_local}%`;
        if( filters.plaza_id >= -1 ){
            search += `&plaza_id=${filters.plaza_id}`;
        }
    }
    const response = await axios.get(`${API_URL}catalogos/locales?sort=id&limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function getOneLocal( id_row:number ) {


    const response = await axios.get(`${API_URL}catalogos/locales/${id_row}`);
    const { id, num_local, medidas, tipo_local, nivel, estatus, socio, nombre_socio, plaza, calle, num_int, num_ext, colonia, codigo_postal, ciudad, estado } = response.data.doc.data;
    return {
        id: id || 0,
        num_local : num_local || "",
        medidas: medidas || 0,
        tipo_local_id: tipo_local?.id || 0,
        nivel_id: nivel?.id || 0,
        estatus_id: estatus?.id || 0,
        socio: socio || false,
        nombre_socio: nombre_socio || "",
        plaza_id: plaza?.id || 0,
        calle: calle || "",
        num_int: num_int || "",
        num_ext: num_ext || "",
        colonia: colonia || "",
        codigo_postal: codigo_postal || "",
        ciudad: ciudad || "",
        estado: estado || ""
    };

}

export async function addLocal( body:any ) {

    const { plaza_id, num_local, medidas, tipo_local_id, nivel_id, estatus_id, socio, nombre_socio, plaza, calle, num_int, num_ext, colonia, codigo_postal, ciudad, estado } = body;
    const data: any = {
        plaza_id: Number(plaza_id),
        num_local: num_local,
        medidas: (medidas ? medidas : 0),
        tipo_local_id: Number(tipo_local_id),
        nivel_id: (Number(nivel_id) === 0 ? null : nivel_id ),
        estatus_id: Number(estatus_id),
        socio: socio,
        nombre_socio: nombre_socio, 
        plaza: plaza,
        calle: calle,
        num_ext: num_ext,
        num_int: num_int,
        colonia: colonia,
        codigo_postal: codigo_postal,
        ciudad: ciudad,
        estado: estado
    }
    const response = await axios.post(`${API_URL}catalogos/locales/`, data);
    return response;

}
export async function updateLocal( body:any ) {
    
    const { plaza_id, id, num_local, medidas, tipo_local_id, nivel_id, estatus_id, socio, nombre_socio, plaza, calle, num_int, num_ext, colonia, codigo_postal, ciudad, estado } = body;
    const data: any = {
        plaza_id: Number(plaza_id),
        num_local: num_local,
        medidas: (medidas ? medidas : 0),
        tipo_local_id: Number(tipo_local_id),
        nivel_id: (Number(nivel_id) === 0 ? null : nivel_id ),
        estatus_id: Number(estatus_id),
        socio: socio,
        nombre_socio: nombre_socio, 
        plaza: plaza,
        calle: calle,
        num_ext: num_ext,
        num_int: num_int,
        colonia: colonia,
        codigo_postal: codigo_postal,
        ciudad: ciudad,
        estado: estado
    }
    const response = await axios.put(`${API_URL}catalogos/locales/${id}`, data);
    return response;

}
export async function removeLocal( id:number ) {

    const response = await axios.delete(`${API_URL}catalogos/locales/${id}`);
    return response;

}
export async function getSelectLocal( plazaId: number ) {

    let search : string = `&plaza_id=${plazaId}`;
    const response = await axios.get(`${API_URL}catalogos/locales/statuslocal/DIS?sort=id${ search }`);
    const data = response.data.doc.data;
    return data;

}

// ARRENDATARIOS
export async function getAllArrendatarios( startIndex:number, endIndex:number, filters:any ) {

    let search : string = ''; 
    if( filters ){
        if( filters.nombre !== "" ) search += `&nombreOpLike=%${filters.nombre}%`;
        if( filters.apellido !== "" ) search += `&apellidoOpLike=%${filters.apellido}%`;
    }
    const response = await axios.get(`${API_URL}catalogos/arrendatarios?sort=id&limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function getOneArrendatario( id_row:number ) {


    const response = await axios.get(`${API_URL}catalogos/arrendatarios/${id_row}`);

    const { id, nombre, apellido, img_id_frente, img_id_atras, telefono,
         correo, aval, rfc, calle, num_int, num_ext, colonia, codigo_postal, ciudad, estado } = response.data.doc.data;
    return {
        id: id || 0,
        nombre : nombre || "",
        apellido: apellido || "",
        img_id_frente: img_id_frente || "",
        img_id_atras: img_id_atras || "",
        telefono: telefono || "",
        correo: correo || "",
        aval: aval || "",
        rfc: rfc || "",
        calle: calle || "",
        num_int: num_int || "",
        num_ext: num_ext || "",
        colonia: colonia || "",
        codigo_postal: codigo_postal || "",
        ciudad: ciudad || "",
        estado: estado || ""
    };

}
export async function addArrendatario( body:any ) {

    const { 
        nombre, apellido, telefono, correo, aval, rfc,
        calle, num_int, num_ext, colonia, codigo_postal, ciudad, estado, files
    } = body;

    let formData = new FormData();

    formData.append('nombre', nombre);
    formData.append('apellido', apellido);
    formData.append('telefono', telefono);
    formData.append('correo', correo);
    formData.append('aval', aval);
    formData.append('rfc', rfc);
    formData.append('calle', calle);
    formData.append('num_int', num_int);
    formData.append('num_ext', num_ext);
    formData.append('colonia', colonia);
    formData.append('codigo_postal', codigo_postal);
    formData.append('ciudad', ciudad);
    formData.append('estado', estado);
    if( files ){
        for(let i = 0 ; i < files.length; i++ ){
            formData.append("identificacion", files[i]);
        }
    } 
    const response = await axios({
        method: "post",
        url: `${API_URL}catalogos/arrendatarios/`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;

}

export async function updateArrendatario( body:any ) {

    const { 
        id, nombre, apellido, telefono, correo, aval, rfc,
        calle, num_int, num_ext, colonia, codigo_postal, ciudad, estado, files
    } = body;

    let formData = new FormData();

    formData.append('nombre', nombre);
    formData.append('apellido', apellido);
    formData.append('telefono', telefono);
    formData.append('correo', correo);
    formData.append('aval', aval);
    formData.append('rfc', rfc);
    formData.append('calle', calle);
    formData.append('num_int', num_int);
    formData.append('num_ext', num_ext);
    formData.append('colonia', colonia);
    formData.append('codigo_postal', codigo_postal);
    formData.append('ciudad', ciudad);
    formData.append('estado', estado);
    if( files ){
        for(let i = 0 ; i < files.length; i++ ){
            formData.append("identificacion", files[i]);
        }
    }    
    
    const response = await axios({
        method: "put",
        url: `${API_URL}catalogos/arrendatarios/${id}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;

}
export async function removeArrendatarios( id:number ) {

    const response = await axios.delete(`${API_URL}catalogos/arrendatarios/${id}`);
    return response;

}
export async function getSelectArrendatario() {

    const response = await axios.get(`${API_URL}catalogos/arrendatarios`);
    const data = response.data.doc.data;
    let dataSelect: any = data.rows;
    dataSelect.forEach(( row:any ) => {
        row.label = `${row.nombre} ${row.apellido}`
        row.value = row.id;
    });
    return dataSelect;

}

// CONTRATOS

export async function getAllContratos( startIndex:number, endIndex:number, filters:any ) {

    let search : string = ''; 
    if( filters ){
        if( filters.plaza_id >= -1 ){
            search += `&plaza_id=${filters.plaza_id}`;
        }
    }
    const response = await axios.get(`${API_URL}catalogos/contratos?sort=id&limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function getOneContrato( id_row:number ) {


    const response = await axios.get(`${API_URL}catalogos/contratos/${id_row}`);
    const { id, plaza, contrato } = response.data.doc.data;
    return {
        id: id || 0,
        plaza_id: plaza?.id || 0,
        contrato: contrato || ""
    };

}
export async function addContrato( body:any ) {

    const { plaza_id, file } = body;

    let formData = new FormData();
    formData.append('plaza_id', plaza_id);
    formData.append('contrato', file);

    const response = await axios({
        method: "post",
        url: `${API_URL}catalogos/contratos/`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;

}
export async function updateContrato( body:any ) {

    const { id, plaza_id, file } = body;

    let formData = new FormData();

    formData.append('plaza_id', plaza_id);
    if( file ){
        formData.append('contrato', file);
    }
    
    const response = await axios({
        method: "put",
        url: `${API_URL}catalogos/contratos/${id}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;

}
export async function removeContrato( id:number ) {

    const response = await axios.delete(`${API_URL}catalogos/contratos/${id}`);
    return response;

}
export async function getSelectArrendatarioContrato( plazaId: number ) {

    const response = await axios.get(`${API_URL}catalogos/contratosArrendatarios/arrendatarios/plazas/${ plazaId }`);
    const data = response.data.doc.data;
    let dataSelect: any = data.rows;
    dataSelect.forEach(( row:any ) => {
        row.label = `${row.nombre} ${row.apellido}`
        row.value = row.id;
    });
    return dataSelect;

}