export class DctosModel{
    montoDctoPP: number
    montoDctoGlobal: number
    dctoGlobal: number

    constructor(montoDctoPP:number,montoDctoGlobal:number,dctoGlobal:number){
        this.montoDctoGlobal = montoDctoGlobal;
        this.montoDctoPP = montoDctoPP;
        this.dctoGlobal = dctoGlobal
    }

}