import React, { FC } from 'react'
import { PageTitle, PageLink } from '../../../../_metronic/layout/core'
import { View } from './components/View'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Catálogos',
    path: '/catalogos/parametros_operativos',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ParametrosOperativosPage: FC = () => {
    return (
      <>
        <PageTitle breadcrumbs={ breadCrumbs }>Parametros Operativos</PageTitle>
        <View />
      </>
    )
}

export { ParametrosOperativosPage }