import React, { useState, useEffect } from 'react';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
// import { Filter } from '../../../conciliacion/components/Filter';
import {
  useGetAllDataReport,
  useGetAllWithoutPagesReport,
} from '../../../conciliacion/hooks/ConciliacionHook';
import { DownloadExcel } from '../../../../../_metronic/helpers/TemplateExcelConciliacion';
import { KTSVG } from '../../../../../_metronic/helpers';

let fechaActual = new Date();
let fecha_inicio_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth(),
  1
);
let fecha_fin_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 1,
  0
);

const View: React.FC = () => {
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reset, setReset] = useState(Math.random() * 40);

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    id: 0,
    contrato_arrendatario_id: 0,
    fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
    fecha_fin: fecha_fin_init.toISOString().split('T')[0],
    conciliado: '',
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAllDataReport(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const { dataExcel } = useGetAllWithoutPagesReport(
    reloadGrid,
    initFilterValues
  );

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        id: 0,
        contrato_arrendatario_id: 0,
        fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
        fecha_fin: fecha_fin_init.toISOString().split('T')[0],
        conciliado: '',
      });
    }
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      id: 0,
      contrato_arrendatario_id: 0,
      fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
      fecha_fin: fecha_fin_init.toISOString().split('T')[0],
      conciliado: '',
    });
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const calculate = (row: any) => {
    const mensualidad = Number(row.mensualidad) ? Number(row.mensualidad) : 0;
    const descuento = Number(row.descuento) ? Number(row.descuento) : 0;
    const recargo = Number(row.recargo) ? Number(row.recargo) : 0;
    const guante = Number(row.guante) ? Number(row.guante) : 0;
    const calculo = mensualidad + recargo + guante - descuento;

    let formattedNumber = calculo.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  };

  const currencyFormat = (value: number) => {
    return Number(value).toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const download = () => {
    let columnsName = [
      'Recibo',
      'Contrato',
      'Arrendatario',
      'Mensualidad',
      'Descuento',
      'Recarga',
      'Depósito',
      'Total',
      'Conciliado',
    ];
    let data: any = dataExcel.map((item: any) => {
      return {
        recibo: item.id,
        contrato: item.contrato_arrendatario.id,
        arrendatario: `${item.contrato_arrendatario.arrendatario.nombre} ${item.contrato_arrendatario.arrendatario.apellido} `,
        mensualidad: currencyFormat(item.mensualidad),
        descuento: currencyFormat(item.descuento),
        recargo: currencyFormat(item.recargo),
        guante: currencyFormat(item.guante),
        total: calculate(item),
        conciliado: item.conciliado ? 'Conciliado' : 'No conciliado',
      };
    });

    const response = {
      sheetName: 'Reporte_Conciliación',
      fileName: 'Reporte_Conciliación.xlsx',
      title: 'Conciliación',
      columnsName: columnsName,
      columns: [
        {
          key: 'recibo',
          width: 20,
        },
        {
          key: 'contrato',
          width: 20,
        },
        {
          key: 'arrendatario',
          width: 30,
        },
        {
          key: 'mensualidad',
          width: 20,
        },
        {
          key: 'descuento',
          width: 20,
        },
        {
          key: 'recargo',
          width: 20,
        },
        {
          key: 'guante',
          width: 20,
        },
        {
          key: 'total',
          width: 20,
        },
        {
          key: 'conciliado',
          width: 20,
        },
      ],
      data: data,
    };

    DownloadExcel(response);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              />
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Descargar excel"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-success ms-5"
                  title="Descargar excel"
                  onClick={download}
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil009.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Excel
                </a>
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              // onChangeRegister={changeRegister}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              reset={reset}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
