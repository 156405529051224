import React, { useState, useEffect } from 'react';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
import { useGetAll } from '../hooks/ContratosPorVencerHook';
import { DownloadExcel } from '../../../../../_metronic/helpers/DownloadExcelHelper';
import { KTSVG } from '../../../../../_metronic/helpers';

const View: React.FC = () => {
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reset, setReset] = useState(Math.random() * 40);

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    contrato: 0,
    plaza_id: -2,
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        contrato: 0,
        plaza_id: -2,
      });
    }
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      contrato: 0,
      plaza_id: -2,
    });
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const download = () => {
    let columnsName = [
      'Plaza',
      'Arrendatario',
      'Local',
      'Fecha de vencimiento',
    ];
    let dataExcel: any = data.map((item) => {
      return {
        plaza: item.plaza.nombre,
        arrendatario: item.arrendatario,
        local: item.local,
        fecha_vencimiento: new Date(item.fecha_vencimiento)
          .toISOString()
          .split('T')[0],
      };
    });
    const response = {
      sheetName: 'Contratos',
      fileName: 'contratos_por_vencer.xlsx',
      title: 'Contratos por vencer',
      columnsName: columnsName,
      columns: [
        {
          key: 'plaza',
          width: 20,
        },
        {
          key: 'arrendatario',
          width: 20,
        },
        {
          key: 'local',
          width: 20,
        },
        {
          key: 'fecha_vencimiento',
          width: 10,
        },
      ],
      data: dataExcel,
    };
    DownloadExcel(response);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              />
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Descargar excel"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-success ms-5"
                  title="Descargar excel"
                  onClick={download}
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil009.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Excel
                </a>
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              reset={reset}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
