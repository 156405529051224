import React from 'react';
import { Pagination } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';
// import { PaginationTest } from './Pagination_test';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
  reset: any;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loadingGrid,
  reset,
}) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const conciliadoRender = (conciliado: number) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <div className="text-muted text-center fs-6 w-100">
            {conciliado ? (
              <i className="fa fa-check text-success fs-5"></i>
            ) : (
              <i className="fa fa-times text-danger fs-5"></i>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-600px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-150px">Recibo</th>
                <th className="w-150px">Contrato</th>
                <th className="w-200px">Plaza</th>
                <th className="w-200px">Arrendatario</th>
                <th className="w-150px">Monto</th>
                <th className="w-150px">Concepto</th>
                <th className="w-150px">Folio Movimiento</th>
                <th className="w-100px">Conciliado</th>
                <th className="w-150px">Fecha</th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={9}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={9}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((row: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.recibo}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.contrato}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.plaza ? row.plaza.nombre : 'N/A'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row.arrendatario.nombre} ${row.arrendatario.apellido}`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {Number(row.monto).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.concepto}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.folio_movto}
                        </div>
                      </div>
                    </td>
                    <td>{conciliadoRender(row.conciliado)}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {new Date(row.fecha).toISOString().split('T')[0]}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            reset={reset}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
