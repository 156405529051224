

import React, { useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { addTipoCP, updateTipoCP } from '../../../../service/catalog/TipoCuentasPagar'
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/TipoCuentaPagoHook'
import { useSelectGeneric } from '../../generico/hooks/genericoHook'


type Props = {
    show: boolean
    handleClose: ( updateGrid:boolean ) => void,
    title: string,
    id_row: number
}
const TCPSchema = Yup.object().shape({
    descripcion: Yup.string()
        .max(100, 'No puede tener más de 100 caracteres')
        .required("Descripción requerido."),
    frecuencia_id: Yup.number()
        .min(1, 'Frecuencia requerido.')
        .required("Frecuencia requerido."),
})

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {

    const [loading, setLoading] = useState(false)
    const { initValues } = useGetOne( id_row );
    const { catalog: frecuencia } = useSelectGeneric('FRECUENCIA');

    const formTCP = useFormik({
        initialValues: initValues,
        validationSchema: TCPSchema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setTimeout(() => {
                if( values.id > 0){
                    updateTipoCP( values )
                    .then(( res ) => {
                        const { data: { message } } = res;
                        toast.success(message, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                        formTCP.resetForm()
                        setLoading(false);
                        handleClose( true )
                    })
                    .catch(( err ) => {
                        let resMessageToast: string = ""
                        const { data: { message, details = null } } = err.response;
                        resMessageToast =`${message}`;
                        if( details.length ){
                            setStatus(`${details[0]?.message}`)
                        }
                        setLoading(false)
                        setSubmitting(false)
                        toast.error(resMessageToast, {
                          position: "top-right",
                          autoClose: 4000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                    })
                } else { 
                    addTipoCP( values )
                    .then(( res ) => {
    
                        const { data: { message } } = res;
                        toast.success(message, {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                        formTCP.resetForm()
                        setLoading(false);
                        handleClose( true )
                    })
                    .catch(( err ) => {
                        let resMessageToast: string = ""
                        const { data: { message, details = null } } = err.response;
                        resMessageToast =`${message}`;
                        if( details.length ){
                            setStatus(`${details[0]?.message}`)
                        }
                        setLoading(false)
                        setSubmitting(false)
                        toast.error(resMessageToast, {
                          position: "top-right",
                          autoClose: 4000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                    })
                }

            }, 1000 );

        },
    })
    return (
        <Modal
        id='kt_modal_create'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-650px'
        show={ show }
        onHide={ () => {
            formTCP.resetForm()
            handleClose( false )
        } }
        >
            <div className='modal-content'>
                <div className='modal-header'>
                    <h2>{ title }</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={ () => {
                        formTCP.resetForm()
                        handleClose( false )
                    } }>
                        <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className="modal-body scroll-y mx-5">
                    <form id="kt_modal_generic_form" className="form" action="#"
                        onSubmit={ formTCP.handleSubmit }
                    >
                        <div className="d-flex flex-column scroll-y" id="kt_modal_add_user_scroll"
                        data-kt-scroll="true" data-kt-scroll-activate='{ default: false, lg: true }' data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                            data-kt-scroll-offset="300px">

                            <div className="fv-row mb-7">
                                <div className='w-100 d-flex'>
                                    <label className="required fw-bold fs-6 mb-2 w-auto">Descripción</label>
                                    {formTCP.touched.descripcion && formTCP.errors.descripcion && (
                                        <div className='fv-plugins-message-container text-danger w-50 ms-5'>
                                            <span role='alert'>{formTCP.errors.descripcion}</span>
                                        </div>
                                    )}
                                </div>
                                <input
                                    placeholder="Descripción"
                                    {...formTCP.getFieldProps('descripcion')}
                                    className="form-control"
                                    type='text'
                                    name='descripcion'
                                    autoComplete='off'
                                />
                            </div>
                            <div className="fv-row mb-7">
                                <div className='w-100 d-flex'>
                                    <label className="required fw-bold fs-6 mb-2 w-auto">Frecuencia</label>
                                    {formTCP.touched.frecuencia_id && formTCP.errors.frecuencia_id && (
                                        <div className='fv-plugins-message-container text-danger w-50 ms-5'>
                                            <span role='alert'>{formTCP.errors.frecuencia_id}</span>
                                        </div>
                                    )}
                                </div>
                                <select
                                    className='form-select'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    {...formTCP.getFieldProps('frecuencia_id')}
                                    >
                                        <option value={ 0 } >Seleccione</option>
                                        {frecuencia.map(( item, index ) =>(
                                            <option key={index} value={ item.id }
                                            >{ item.name }</option>
                                        ))}                                    
                                </select>
                            </div>

                        </div>
                        <div className="text-center pt-15">
                            <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel" onClick={ () => {
                                formTCP.resetForm()
                                handleClose( false )
                            } }>Cancelar</button>
                            <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit" disabled={loading}>

                            {!loading && <span className='indicator-label'>Guardar</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Espere por favor...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )

}
export { CreateModal }