import React from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;
    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const intl = useIntl();
  return (
    <>
      {userHasRole(['ADMIN', 'OPER', 'AUDI', 'SOCIO']) && (
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/graphs/gra008.svg"
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['OPER', 'AUDI', 'SOCIO']) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            {/* <div className='menu-content pt-8 pb-2'> */}
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Finanzas
            </span>
          </div>
        </div>
      )}
      {userHasRole(['OPER', 'AUDI', 'SOCIO']) && (
        <AsideMenuItem
          to="/cuentas_pagar"
          icon="/media/icons/duotune/finance/fin010.svg"
          title="Gastos operativos"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['OPER', 'AUDI']) && (
        <AsideMenuItem
          to="/pagos"
          icon="/media/icons/duotune/finance/fin010.svg"
          title="Pagos"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['AUDI']) && (
        <AsideMenuItem
          to="/conciliacion"
          icon="/media/icons/duotune/finance/fin008.svg"
          title="Conciliación"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['OPER', 'AUDI', 'SOCIO']) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            {/* <div className='menu-content pt-8 pb-2'> */}
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              CONTRATOS
            </span>
          </div>
        </div>
      )}
      {userHasRole(['OPER', 'AUDI', 'SOCIO']) && (
        <AsideMenuItem
          to="/contrato_local"
          icon="/media/icons/duotune/files/fil003.svg"
          title="Contratos"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['AUDI', 'OPER', 'SOCIO']) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Reportes
            </span>
          </div>
        </div>
      )}
      {userHasRole(['AUDI', 'OPER', 'SOCIO']) && (
        <AsideMenuItem
          to="/reporte_semaforo"
          icon="/media/icons/duotune/finance/fin010.svg"
          title="Semáforo pagos"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['AUDI', 'OPER']) && (
        <AsideMenuItem
          to="/reporte_por_vencer"
          icon="/media/icons/duotune/files/fil003.svg"
          title="Contratos por vencer"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['AUDI', 'OPER', 'SOCIO']) && (
        <AsideMenuItem
          to="/reporte_pagos_diarios"
          icon="/media/icons/duotune/finance/fin010.svg"
          title="Pagos diarios"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['AUDI', 'SOCIO']) && (
        <AsideMenuItem
          to="/reporte_conciliacion"
          icon="/media/icons/duotune/finance/fin008.svg"
          title="Conciliación"
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(['ADMIN', 'AUDI', 'OPER']) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            {/* <div className='menu-content pt-8 pb-2'> */}
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Catálogos
            </span>
          </div>
        </div>
      )}
      {userHasRole(['ADMIN', 'AUDI', 'OPER']) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen054.svg"
          fontIcon="bi-layers"
          to="/catalogos/"
          title="Catálogos"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/catalogos/arrendatarios"
            title="Arrendatarios"
            icon="/media/icons/duotune/technology/teh002.svg"
          />
          <AsideMenuItem
            to="/catalogos/plantillas"
            title="Plantillas"
            icon="/media/icons/duotune/files/fil003.svg"
          />
          <AsideMenuItem
            to="/catalogos/locales"
            title="Locales"
            icon="/media/icons/duotune/ecommerce/ecm004.svg"
          />
          <AsideMenuItem
            to="/catalogos/plazas"
            title="Plazas"
            icon="/media/icons/duotune/general/gen001.svg"
          />
          <AsideMenuItem
            to="/catalogos/tipos_cuentas_pagar"
            title="Tipo de Gastos Operativos"
            icon="/media/icons/duotune/finance/fin002.svg"
          />
          {/* <AsideMenuItem
            to="/catalogos/tipos_cuentas_cobrar"
            title="Tipos Cuentas por Cobrar"
            icon="/media/icons/duotune/finance/fin002.svg"
          /> */}
          <div className="menu-item">
            <div className="menu-content pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Generales
              </span>
            </div>
          </div>
          {/* <AsideMenuItem
            to="/catalogos/generico/EST_LOCAL"
            title="Estatus del Local"
            icon="/media/icons/duotune/abstract/abs009.svg"
          /> */}
          <AsideMenuItem
            to="/catalogos/generico/NIVELES"
            title="Niveles"
            icon="/media/icons/duotune/abstract/abs027.svg"
          />
          <AsideMenuItem
            to="/catalogos/generico/TIPO_LOCAL"
            title="Tipos de Local"
            icon="/media/icons/duotune/abstract/abs009.svg"
          />
          <AsideMenuItem
            to="/catalogos/parametros_operativos/"
            title="Parametros operativos"
            icon="/media/icons/duotune/coding/cod001.svg"
          />
          <AsideMenuItem
            to="/catalogos/users_plaza/"
            title="Plazas por usuario"
            icon="/media/icons/duotune/coding/cod001.svg"
          />
        </AsideMenuItemWithSub>
      )}
      {userHasRole(['ADMIN']) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Usuarios del sistema
            </span>
          </div>
        </div>
      )}
      {userHasRole(['ADMIN']) && (
        <AsideMenuItem
          to="/usuarios"
          title="Usuarios"
          icon="/media/icons/duotune/general/gen049.svg"
        />
      )}
    </>
  );
}
