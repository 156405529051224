import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { ListGrid } from './ListGrid';
import { KTSVG } from '../../../../_metronic/helpers';
import { useGetHistory } from '../hooks/ContratoLocalHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  id_row: number;
};
const HistoryModal: React.FC<Props> = ({ show, id_row, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count } = useGetHistory(pageSize, currentPage, id_row);
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    setLoading(false);
  }, [data]);

  return (
    <Modal
      id="kt_modal_movimientos"
      tabIndex={-1}
      // size={'lg'}
      fullscreen={true}
      aria-hidden="true"
      // dialogClassName="modal-dialog modal-xl"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>Historial de locales</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y">
          <div
            className="d-flex flex-column scroll-y"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{ default: false, lg: true }"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <ListGrid
                data={data}
                loading={loading}
                count={count}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(pageNumber: number) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { HistoryModal };
