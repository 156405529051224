import { useState, useEffect } from 'react'
import { getInfoContrato, getMovimientos, getResumen } from '../../../service/pagos/Pagos'
import { PagosModel } from '../models/PagosModel'
import { MovimientosModel } from '../models/MovimientosModel'
import { ResumenModel } from '../models/ResumenModel'
import { useHistory } from 'react-router-dom'
import { date } from 'yup'
import { OffertsModel } from '../models/OffersModel'
import { DctosModel } from '../models/DctosMode'

export const useGetInfoContrato = ( reloadData:any, filters:any ) => {
    const initModel = {
        id: 0,
        plaza: {},
        arrendatario: {},
        saldo_actual: 0,
        locales: [],
        dctos: new DctosModel(0,0,0)
    }
    const [ data, setData ] = useState<PagosModel>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getInfoContrato( filters ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res);
            }
         };
         if( filters.arrendatario_id > 0 ){
            fetchPost();
         } else {
            setData(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ reloadData ]);
    return { data }
}

export const useGetMovimientos = ( startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<MovimientosModel[]>([]);
    const [ count, setCount ] = useState(1);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getMovimientos( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
            }
         };
         if( filters.tipo_movimiento >= 0 && filters.id > 0){
            fetchPost();
         }else{
            setData([]);
            setCount(0);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex, filters]);
    return { data, count }
}

export const useGetResumen = ( id:number ) => {
    const initModel = {
        id:0,
        saldo: 0,
        monto_dcto_pp: 0,
        monto_dcto_global: 0,
        total: 0,
        dctoPPObject: new OffertsModel("",0),
        dctoGlobalObject:new OffertsModel("",0)

    }
    const [ data, setData ] = useState<ResumenModel>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getResumen( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setData(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { data }
}
