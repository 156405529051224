import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useComboPlazas } from '../../../catalogos/plazas/hooks/PlazaHook';
import { useSelectUsuarios } from '../../../usuarios/hooks/userHook';
import { addUsuariosPlazas } from '../../../../service/catalog/UsuariosPlazas';
import { useGetAll } from '../hooks/UsuariosPlazaHook';
import { ListGrid } from './ListGrid';
import { RemoveModal } from '../_modals/RemoveModal';

const plazasUsuariosSchema = Yup.object().shape({
  plaza_id: Yup.number()
    .min(-1, 'Plaza es requerido.')
    .required('Plaza es requerido.'),
  usuario_id: Yup.number()
    .min(1, 'Usuario es requerido.')
    .required('Usuario es requerido.'),
});

const filterValues = {
  plaza_id: -2,
  usuario_id: 0,
};

const View: React.FC = () => {
  // GRID
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage
  );
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const [idDelete, setIdDelete] = useState(0);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const { plazas } = useComboPlazas();
  const { usuarios } = useSelectUsuarios();
  const [loading, setLoading] = useState(false);
  // const [ filterValues, setFilterValues ] = useState({
  //     plaza_id: -1,
  //     usuario_id: 0
  // });
  const changePlaza = (e: any) => {
    const id = e.target.value;
    formPlazasUsuarios.setFieldValue('plaza_id', Number(id));
  };
  const handleUsuarios = (selectedOption: any) => {
    const id = selectedOption.value;
    setSelectedOption(selectedOption);
    formPlazasUsuarios.setFieldValue('usuario_id', Number(id));
  };
  const removeRegister = (id: number) => {
    setIdDelete(id);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const formPlazasUsuarios = useFormik({
    initialValues: filterValues,
    validationSchema: plazasUsuariosSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setLoadingGrid(true);
      setTimeout(() => {
        addUsuariosPlazas(values)
          .then((res) => {
            const {
              data: { message },
            } = res;
            toast.success(message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            setLoading(false);
            setReloadGrid(Math.random() * 40);
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            if (details.length) {
              setStatus(`${details[0]?.message}`);
            }
            setLoading(false);
            setLoadingGrid(false);
            setSubmitting(false);
            toast.error(resMessageToast, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });
      }, 1000);
    },
  });

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column w-100">
                <form
                  id="kt_modal_generic_form"
                  className="form w-100"
                  action="#"
                  onSubmit={formPlazasUsuarios.handleSubmit}
                >
                  <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                    <div className="w-100 d-flex">
                      <div className="w-25 pe-5">
                        <div className="w-100 d-flex">
                          <label className=" required fw-bold fs-6 mb-2 w-auto">
                            Usuario
                          </label>
                          {formPlazasUsuarios.touched.usuario_id &&
                            formPlazasUsuarios.errors.usuario_id && (
                              <span className="fs-6 text-danger d-flex  w-100 ms-5">
                                {formPlazasUsuarios.errors.usuario_id}
                              </span>
                            )}
                        </div>
                        <Select
                          options={usuarios}
                          placeholder={'Seleccione'}
                          onChange={handleUsuarios}
                          value={selectedOption}
                          noOptionsMessage={() => {
                            return <span>No hay usuarios</span>;
                          }}
                          styles={{
                            placeholder: (provided, state) => ({
                              ...provided,
                              color: '#181c32',
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display: 'none',
                            }),
                            dropdownIndicator: (provided, state) => ({
                              ...provided,
                              color: '#7E8199',
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              border: '1px solid #e4e6ef',
                              borderRadius: '6px',
                              padding: '3px',
                              fontSize: '1.1rem',
                              boxShadow: 'none',
                              backgroundColor: state.isDisabled
                                ? '#EFF2F5'
                                : 'white',
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              fontSize: '1.1rem',
                            }),
                          }}
                        />
                      </div>
                      <div className="w-25 pe-5">
                        <div className="w-100 d-flex">
                          <label className="required fw-bold fs-6 mb-2 w-auto">
                            Plaza
                          </label>
                          {formPlazasUsuarios.touched.plaza_id &&
                            formPlazasUsuarios.errors.plaza_id && (
                              <span className="fs-6 text-danger d-flex  w-100 ms-5">
                                {formPlazasUsuarios.errors.plaza_id}
                              </span>
                            )}
                        </div>
                        <select
                          className="form-select mb-3"
                          data-kt-select2="true"
                          disabled={plazas.length > 0 ? false : true}
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          onChange={changePlaza}
                        >
                          <option value={-1}>Seleccione</option>
                          {plazas.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.nombre}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="w-50 text-end">
                        <button
                          type="button"
                          onClick={() => {
                            formPlazasUsuarios.submitForm();
                          }}
                          className="btn btn-sm btn-primary mt-10"
                          data-kt-users-modal-action="submit"
                          disabled={loading}
                        >
                          {!loading && (
                            <span className="indicator-label">Guardar</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              Buscando...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onRemoveRegister={removeRegister}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
            />
          </div>
        </div>
      </div>
      <RemoveModal
        show={visivilityRemoveModal}
        id_row={idDelete}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
          }
          setIdDelete(0);
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
