import React, { FC } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { View } from './components/View'

const UsuariosPlazaPage: FC = () => {
    return (
      <>
        <PageTitle breadcrumbs={[]}>Plazas por usuario</PageTitle>
        <View />
      </>
    )
}

export { UsuariosPlazaPage }