import React, { useState, useEffect } from 'react';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
import {
  useGetAll,
  useGetAllWithoutPagesReport,
} from '../hooks/PagosDiariosHook';
import { DownloadExcel } from '../../../../../_metronic/helpers/DownloadExcelHelper';
import { KTSVG } from '../../../../../_metronic/helpers';

let fechaActual = new Date();
let fecha_inicio_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth(),
  1
);
let fecha_fin_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 1,
  0
);

const View: React.FC = () => {
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reset, setReset] = useState(Math.random() * 40);

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    contrato: 0,
    plaza_id: -2,
    arrendatario_id: 0,
    fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
    fecha_fin: fecha_fin_init.toISOString().split('T')[0],
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );

  const { dataExcel } = useGetAllWithoutPagesReport(
    reloadGrid,
    initFilterValues
  );

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        contrato: 0,
        plaza_id: -2,
        arrendatario_id: 0,
        fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
        fecha_fin: fecha_fin_init.toISOString().split('T')[0],
      });
    }
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      contrato: 0,
      plaza_id: -2,
      arrendatario_id: 0,
      fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
      fecha_fin: fecha_fin_init.toISOString().split('T')[0],
    });
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const download = () => {
    let columnsName = [
      'Recibo',
      'Contrato',
      'Plaza',
      'Arrendatario',
      'Monto',
      'Concepto',
      'Folio Movimiento',
      'Conciliado',
      'Fecha',
    ];
    let excelData: any = dataExcel.map((item) => {
      return {
        recibo: item.recibo,
        contrato: item.contrato,
        plaza: item.plaza.nombre,
        arrendatario: `${item.arrendatario.nombre} ${item.arrendatario.apellido}`,
        monto: Number(item.monto).toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        concepto: item.concepto,
        folio_movto: item.folio_movto,
        conciliado: item.conciliado ? 'Conciliado' : 'No conciliado',
        fecha: new Date(item.fecha).toISOString().split('T')[0],
      };
    });
    const response = {
      sheetName: 'Pagos',
      fileName: 'pagos_diarios.xlsx',
      title: 'Pagos Diarios',
      columnsName: columnsName,
      columns: [
        {
          key: 'recibo',
          width: 10,
        },
        {
          key: 'contrato',
          width: 10,
        },
        {
          key: 'plaza',
          width: 20,
        },
        {
          key: 'arrendatario',
          width: 20,
        },
        {
          key: 'monto',
          width: 20,
        },
        {
          key: 'concepto',
          width: 40,
        },
        {
          key: 'folio_movto',
          width: 30,
        },
        {
          key: 'conciliado',
          width: 20,
        },
        {
          key: 'fecha',
          width: 15,
        },
      ],
      data: excelData,
    };
    DownloadExcel(response);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              />
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Descargar excel"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-success ms-5"
                  title="Descargar excel"
                  onClick={download}
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil009.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Excel
                </a>
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              reset={reset}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
