import axios from 'axios'
import { OffertsModel } from '../../pages/pagos/models/OffersModel';
const API_URL = process.env.REACT_APP_API

export async function getInfoContrato( filters:any ) {

    let plaza_id : string = filters.plaza_id; 
    let arrendatario_id : string = filters.arrendatario_id;
    const response = await axios.get(`${API_URL}finanzas/cobranza/plaza/${plaza_id}/arrendatario/${arrendatario_id}`)
    const data = response.data.doc.data;
    return data;

}

export async function getMovimientos( startIndex:number, endIndex:number, filters:any ) {
    let search: string = '';
    if (filters) {
        search += `&fechaIni=${filters.fecha_inicio}&fechaFin=${filters.fecha_fin} 23:59:59`;
        switch (filters.tipo_movimiento) {
            case 0:
                search += ``;
                break;
            case 1:
                search += `&tipo=CARGO`;
                break;
            case 2:
                search += `&tipo=ABONO`;
                break;
            default:
                search += ``;
                break;
        }
    }
    const response = await axios.get(`${API_URL}finanzas/cobranza/movtos/contrato/${filters.id}?limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function getResumen( id_row:number ) {

    const response = await axios.get(`${API_URL}finanzas/cobranza/saldo/contrato/${id_row}`);
    const { saldo, monto_dcto_pp, monto_dcto_global, total,dctoPPObject ,dctoGlobalObject} = response.data.doc.data;
    console.log(response.data.doc.data);
    return {
        saldo: saldo || 0,
        monto_dcto_pp: monto_dcto_pp || 0,
        monto_dcto_global: monto_dcto_global || 0,
        total: total || 0,
        id: 1,
        dctoPPObject:new OffertsModel(dctoPPObject["fecha"],dctoPPObject["comision"]),
        dctoGlobalObject:new OffertsModel(dctoGlobalObject["fecha"],dctoGlobalObject["comision"])
    };

}
export async function realizarPago( body:any, folio:number) {

    const response = await axios.post(`${API_URL}finanzas/cobranza/saldo/contrato/${folio}`, body);
    return response;

}
export async function descargarRecibo( id:number) {

    const response = await axios({
        url: `${ API_URL }finanzas/cobranza/saldo/recibo/${id}`,
        method: 'POST',
        responseType: 'blob',
        // headers: {
        //     'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // },
    })
    .then(response => {

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `recibo-pago-${ id }.pdf`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 0);
        return false;

    })
    .catch(async error => {
        return JSON.parse(await error.response.data.text());
    });
    return response;

}