import React from 'react';
import { Pagination } from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loading: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loading,
}) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-700px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-80px w-80px">Folio</th>
                <th className="min-w-80px w-80px">Num. local</th>
                <th className="min-w-100px w-100px">Monto</th>
                <th className="min-w-100px w-100px">Depósito</th>
                <th className="min-w-80px w-80px">Parcialidad</th>
                <th className="min-w-100px w-100px">Fecha inicio</th>
                <th className="min-w-100px w-100px">Fecha fin</th>
                <th className="min-w-100px w-100px">Fecha modi.</th>
                <th className="min-w-100px w-100px">Vigente</th>
                <th className="min-w-100px w-100px">Usuario</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={9}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={9}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((row: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.folio}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.num_local}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start fw-bold flex-column fs-6">
                          {Number(row.monto).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start fw-bold flex-column fs-6">
                          {Number(row.guante).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.parcialidad}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {
                            new Date(row.fecha_inicio)
                              .toISOString()
                              .split('T')[0]
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {new Date(row.fecha_fin).toISOString().split('T')[0]}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {
                            new Date(row.fecha_modificacion)
                              .toISOString()
                              .split('T')[0]
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          <div className="d-flex align-items-center">
                            <div className="text-muted text-center fs-6 w-100">
                              {row.vigente ? (
                                <i className="fa fa-check text-success fs-5"></i>
                              ) : (
                                <i className="fa fa-times text-danger fs-5"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.usuario.nombre} {row.usuario.apellido}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            reset={null}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
