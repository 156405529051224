import React, { FC } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { View } from './components/view';

const ContratosPorVencerPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Contratos por vencer</PageTitle>
      <View />
    </>
  );
};

export { ContratosPorVencerPage };
