import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { FallbackView } from '../../_metronic/partials';
import { UserModel } from '../modules/auth/models/UserModel';
import { RootState } from '../../setup';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { CuentasPagarPage } from '../pages/cuentas_pagar/CuentasPagar';
import { PagosPage } from '../pages/pagos/Pagos';
import { ConciliacionPage } from '../pages/conciliacion/Conciliacion';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { UsuariosPage } from '../pages/usuarios/Usuarios';
import { GenericoPage } from '../pages/catalogos/generico/Generico';
import { PlazasPage } from '../pages/catalogos/plazas/Plazas';
import { ContratosPage } from '../pages/catalogos/plantillas/Contratos';
import { ArrendatariosPage } from '../pages/catalogos/arrendatarios/Arrendatarios';
import { LocalesPage } from '../pages/catalogos/locales/Locales';
import { ParametrosOperativosPage } from '../pages/catalogos/parametros_operativos/ParametrosOperativos';
import { UsuariosPlazaPage } from '../pages/catalogos/usuarios_plaza/UsuariosPlaza';
import { TiposCuentasPagarPage } from '../pages/catalogos/tipos_cuentas_pagar/TiposCuentasPagar';
import { ContratoLocalPage } from '../pages/contrato_local/ContratoLocal';
import { SemaforoPagosPage } from '../pages/reportes/semaforo_pagos/SemaforoPagos';
import { ContratosPorVencerPage } from '../pages/reportes/contratos_por_vencer/ContratosPorVencer';
import { PagosDiariosPage } from '../pages/reportes/pagos_diarios/PagosDiarios';
import { ConciliacionRptPage } from '../pages/reportes/conciliacion/Conciliacion';
export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;
    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route
          path="/dashboard"
          component={
            userHasRole(['ADMIN', 'OPER', 'AUDI', 'SOCIO'])
              ? DashboardWrapper
              : ErrorsPage
          }
        />
        <Route
          path="/cuentas_pagar"
          component={
            userHasRole(['OPER', 'AUDI', 'SOCIO'])
              ? CuentasPagarPage
              : ErrorsPage
          }
        />
        <Route
          path="/pagos"
          component={userHasRole(['OPER', 'AUDI']) ? PagosPage : ErrorsPage}
        />
        <Route
          path="/conciliacion"
          component={userHasRole(['AUDI']) ? ConciliacionPage : ErrorsPage}
        />
        <Route
          path="/contrato_local/"
          component={
            userHasRole(['OPER', 'AUDI', 'SOCIO'])
              ? ContratoLocalPage
              : ErrorsPage
          }
        />
        <Route
          path="/reporte_semaforo/"
          component={
            userHasRole(['AUDI', 'OPER', 'SOCIO'])
              ? SemaforoPagosPage
              : ErrorsPage
          }
        />
        <Route
          path="/reporte_por_vencer/"
          component={
            userHasRole(['AUDI', 'OPER']) ? ContratosPorVencerPage : ErrorsPage
          }
        />
        <Route
          path="/reporte_pagos_diarios/"
          component={
            userHasRole(['AUDI', 'OPER', 'SOCIO'])
              ? PagosDiariosPage
              : ErrorsPage
          }
        />
        <Route
          path="/reporte_conciliacion"
          component={
            userHasRole(['AUDI', 'SOCIO']) ? ConciliacionRptPage : ErrorsPage
          }
        />
        <Route
          path="/catalogos/plazas"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER']) ? PlazasPage : ErrorsPage
          }
        />
        <Route
          path="/catalogos/plantillas"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER']) ? ContratosPage : ErrorsPage
          }
        />
        <Route
          path="/catalogos/arrendatarios"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER'])
              ? ArrendatariosPage
              : ErrorsPage
          }
        />
        <Route
          path="/catalogos/locales"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER']) ? LocalesPage : ErrorsPage
          }
        />
        <Route
          path="/catalogos/tipos_cuentas_pagar"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER'])
              ? TiposCuentasPagarPage
              : ErrorsPage
          }
        />
        <Route
          path="/catalogos/generico/:code"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER']) ? GenericoPage : ErrorsPage
          }
        />
        <Route
          path="/catalogos/parametros_operativos"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER'])
              ? ParametrosOperativosPage
              : ErrorsPage
          }
        />
        <Route
          path="/catalogos/users_plaza"
          component={
            userHasRole(['ADMIN', 'AUDI', 'OPER'])
              ? UsuariosPlazaPage
              : ErrorsPage
          }
        />
        <Route
          path="/usuarios"
          component={userHasRole(['ADMIN']) ? UsuariosPage : ErrorsPage}
        />
        <Route path="/perfil" component={ProfilePage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
