import React, { FC } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { View } from './components/View'

const PagosPage: FC = () => {
    return (
      <>
        <PageTitle breadcrumbs={[]}>Pagos</PageTitle>
        <View />
      </>
    )
}

export { PagosPage }