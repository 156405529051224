import React from 'react';
import { Pagination } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onRemoveRegister: (row: any) => void;
  onPageChange: (pageNumber: number) => void;
  onEditRegister: (row: any) => void;
  loadingGrid: boolean;
  reset: any;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onRemoveRegister,
  onPageChange,
  onEditRegister,
  loadingGrid,
  reset,
}) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const dropdownRender = (row: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  onEditRegister(row.id);
                }}
              >
                Editar
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  onRemoveRegister(row.id);
                }}
              >
                Eliminar
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-600px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-50">Descripción</th>
                <th className="w-25">Frecuencia</th>
                <th className="text-end "></th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={3}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={3}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.descripcion}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.frecuencia?.name}
                        </div>
                      </div>
                    </td>
                    <td>{dropdownRender(row)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            reset={reset}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
