import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useFormik } from 'formik'


type Props = {
    initFilterValues: any,
    onCleanFilter: () => void,
    onSearchFilter: ( values:any ) => void,

}

const Filter: React.FC<Props> = ( { initFilterValues, onSearchFilter, onCleanFilter } ) => {

    const [ filterValues, setFilterValues ] = useState(initFilterValues);

    useEffect(() => {
        if( initFilterValues ){
            setFilterValues(initFilterValues);
        }
     
    }, [initFilterValues])

    const formFilter = useFormik({
        initialValues: filterValues,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            onSearchFilter( values )

        }
    })

return (
    <>
        <div className='card-title align-items-start flex-column'>
            <a
            href='#/'
            className={ ( formFilter.values.nombre !== "" || formFilter.values.apellido !== "" ) ? 'btn btn-sm btn-primary': 'btn btn-sm btn-light-primary'}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            >
            <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
            />
            Filtrar
            </a>
            <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Opciones de filtro</div>
                </div>

                <div className='separator border-gray-200'></div>
                <form id="kt_modal_generic_form" className="form" action="#"
                        onSubmit={ formFilter.handleSubmit }
                >
                <div className='px-7 py-5'>
                    <div className='mb-5'>
                        <label className='form-label fw-bold'>Nombre</label>
                        <div>
                            <input
                                placeholder="Nombre"
                                {...formFilter.getFieldProps('nombre')}
                                className="form-control mb-3"
                                type='text'
                                name='nombre'
                                autoComplete='off'
                            />
                        </div>
                    </div>
                    <div className='mb-10'>
                        <label className='form-label fw-bold'>Apellido</label>
                        <div>
                            <input
                                placeholder="Apellido"
                                {...formFilter.getFieldProps('apellido')}
                                className="form-control mb-3"
                                type='text'
                                name='apellido'
                                autoComplete='off'
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button
                            type='reset'
                            className='btn btn-sm btn-white btn-active-light-primary me-2'
                            data-kt-menu-dismiss='true'
                            onClick={ () => {
                                formFilter.resetForm()
                                onCleanFilter()
                            } }
                        >
                            Limpiar
                        </button>

                        <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                            Buscar
                        </button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </>
)

}

export { Filter }