/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { BalanceModel } from '../models/BlanceModel'

type Props = {
    className: string,
    data: Array<BalanceModel>
}

const ListsData: React.FC<Props> = ({ className, data }) => {
    return (
        <div className='card card-xl-stretch mb-5 mb-xl-8'>
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Saldos</h3>
            </div>
            <div className='card-body pt-0'>
                <div className='col-sm-12'>
                    <div className='row gy-5 g-xl-12'>
                        {
                            data.map((item, index) => {
                                return (
                                    <div className='col-xl-12' key={index}>
                                        <div className='d-flex align-items-center bg-light-success rounded p-5 mb-2'>
                                            <span className='svg-icon text-gray-800 me-5'>
                                                <KTSVG path='/media/icons/duotune/finance/fin008.svg' className='svg-icon-1' />
                                            </span>
                                            <div className='flex-grow-1 me-2'>
                                                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                                    {item.plaza.nombre}
                                                </a>
                                                <span className='text-muted fw-bold d-block' >Saldo</span>
                                            </div>
                                            <span className='fw-bolder py-1 text-gray-800'>{formatNumber(item.saldo_actual)}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function formatNumber(value: number) {
    return Number(value).toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export { ListsData }
