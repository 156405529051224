import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Filter } from './Filter';
import { RealizarPagoModal } from '../_modals/RealizarPagoModal';
import { MovimientosModal } from '../_modals/MovimientosModal';
import { useGetInfoContrato } from '../hooks/PagosHook';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { toast } from 'react-toastify';

const View: React.FC = () => {
  const [idRow, setIdRow] = useState(0);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityPagoModal, setVisivilityPagoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(Math.random() * 40);
  const [saldoRestante, setSaldoRestante] = useState(0);
  const [saldo, setTotal] = useState(0);
  const [totalPago, setPagar] = useState(0);
  const [descuentoG, setDescuentoG] = useState(0);
  const [initFilterValues, setInitFilterValues] = useState({
    plaza_id: -2,
    arrendatario_id: 0,
  });
  const { data } = useGetInfoContrato(reloadData, initFilterValues);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 1000);
  }, [data]);

  const realizarPago = (id: number) => {
    setIdRow(id);
    setVisivilityPagoModal(!visivilityPagoModal);
  };
  const viewMovimientos = (id: number) => {
    setIdRow(id);
    setVisivilityModal(!visivilityModal);
  };
  const onSearchFilter = (values: any) => {
    if (values) {
      setLoading(true);
      setInitFilterValues(values);
      setReloadData(Math.random() * 40);
    }
  };

  const obtenerNombreMes = () => {
    const meses = [
      'ENE',
      'FEB',
      'MAR',
      'ABR',
      'MAY',
      'JUN',
      'JUL',
      'AGO',
      'SEP',
      'OCT',
      'NOV',
      'DIC',
    ];

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth();
    const nombreMesActual = meses[mesActual];

    // Calcula el siguiente mes, teniendo en cuenta que diciembre (11) lleva al siguiente enero (0)
    const siguienteMes = (mesActual + 1) % 12;
    const nombreSiguienteMes = meses[siguienteMes];

    return `${nombreMesActual} - ${nombreSiguienteMes}`;
  };

  const totalGuante = () => {
    const total = data.locales.reduce((sum, local) => {
      return sum + Number(local.guante);
    }, 0);

    return (
      <>
        {total.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </>
    );
  };

  const total = () => {
    const total = data.locales.reduce((sum, local) => {
      return sum + Number(local.monto);
    }, 0);

    return total.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    if (data) {
      const total = data.locales.reduce((sum, local) => {
        return sum + Number(local.monto);
      }, 0);

      setTotal(total);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const total = data.locales.reduce((sum, local) => {
        return sum + Number(local.monto);
      }, 0);

      if (data.saldo_actual <= 0) {
        setSaldoRestante(0);
      } else {
        if (data.dctos.dctoGlobal) {
          var resto = (total * data.dctos.dctoGlobal) / 100;

          var total2 = data.saldo_actual - resto;

          setSaldoRestante(total2 <= 0 ? 0 : total2);
        } else {
          //setSaldoRestante(data.saldo_actual - total) // Aqui no elimina negativo
          let saldoRestante = data.saldo_actual - total;
          setSaldoRestante(saldoRestante < 0 ? 0 : saldoRestante);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const total = data.locales.reduce((sum, local) => {
      return sum + Number(local.monto);
    }, 0);

    if (data) {
      setDescuentoG((total * -data.dctos.dctoGlobal) / 100);
    }
  }, [data]);

  const saldoDiscount = () => {
    const total = data.locales.reduce((sum, local) => {
      return sum + Number(local.monto);
    }, 0);

    let formattedNumber = (
      (total * -data.dctos.dctoGlobal) /
      100
    ).toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return (
      <div
        className={
          data.saldo_actual < 0
            ? 'fw-bold fs-6 text-danger'
            : 'fw-bold fs-6 text-gray-800'
        }
      >
        {formattedNumber}
      </div>
    );
  };

  const getAmount = () => {
    const total = data.locales.reduce((sum, local) => {
      return sum + Number(local.monto);
    }, 0);

    if (data.saldo_actual > total) {
      let formattedNumber = total.toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return formattedNumber;
    } else {
      let formattedNumber = data.saldo_actual.toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (data.saldo_actual < 0) {
        return '$0.00';
      }

      return formattedNumber;
    }
  };

  const getAmountActually = () => {
    var sum = data.saldo_actual;

    let formattedNumber = sum.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (sum < 0) {
      return '$0.00';
    }

    return formattedNumber;
  };

  useEffect(() => {
    if (data) {
      var sum = data.saldo_actual;

      var newAmount = sum - data.dctos.montoDctoPP;

      setPagar(newAmount);
    }
  }, [data]);

  const getAmountActually2 = () => {
    var sum = data.saldo_actual;

    var newAmount = sum - data.dctos.montoDctoPP;

    let formattedNumber = newAmount.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (sum < 0) {
      return '$0.00';
    }

    return formattedNumber;
  };

  const saldoActual = () => {
    let formattedNumber = data.saldo_actual.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (data.saldo_actual < 0) {
      formattedNumber = formattedNumber.replace('-', '(') + ')';
    }

    return (
      <>
        <div
          className={
            data.saldo_actual < 0
              ? 'fw-bold fs-6 text-danger'
              : 'fw-bold fs-6 text-gray-800'
          }
        >
          {formattedNumber}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onSearchFilter={onSearchFilter}
                loading={loading}
              />
            </div>
            {loading ? (
              <div className="d-flex align-items-center">
                <div className="text-muted text-center fs-6 w-100 m-5">
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </div>
              </div>
            ) : (
              data.id > 0 && (
                <>
                  <div className="separator my-5"></div>
                  <div className="card-body py-3">
                    <div className="mt-n1">
                      <div className="m-0">
                        <div className="fw-bold fs-3 text-gray-800 mb-8">
                          Folio: {data.id}
                        </div>
                        <div className="row g-5 mb-11">
                          <div className="col-sm-6">
                            <div className="fw-semibold fs-7 text-gray-600 mb-1">
                              Plaza:
                            </div>
                            <div className="fw-bold fs-6 text-gray-800">
                              {data.plaza.nombre}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="fw-semibold fs-7 text-gray-600 mb-1">
                              Arrendatario:
                            </div>
                            <div className="fw-bold fs-6 text-gray-800">
                              {data.arrendatario.nombre}{' '}
                              {data.arrendatario.apellido}
                            </div>
                          </div>
                        </div>
                        <div className="row g-5 mb-12">
                          <div className="col-sm-6">
                            <div className="fw-semibold fs-7 text-gray-600 mb-1">
                              Cantidad de locales:
                            </div>
                            <div className="fw-bold fs-6 text-gray-800">
                              {data.locales.length}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="fw-semibold fs-7 text-gray-600 mb-1">
                              Saldo al corte:
                            </div>
                            <div
                              className={
                                data.saldo_actual < 0
                                  ? 'fw-bold fs-6 text-danger d-flex align-items-center flex-wrap'
                                  : 'fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'
                              }
                            >
                              <span className="pe-2">{saldoActual()}</span>
                              <div
                                className="fs-7 text-primary d-flex align-items-center cursor-pointer"
                                data-kt-menu-trigger="hover"
                                data-kt-menu-attach="parent"
                                data-kt-menu-placement="bottom-start"
                                data-kt-menu-flip="top"
                              >
                                <span className="bullet bullet-dot bg-primary me-2"></span>
                                Saldo a pagar el día de hoy
                              </div>
                              <div
                                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px"
                                data-kt-menu="true"
                              >
                                <div className="menu-item px-3 text-gray-500">
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen044.svg"
                                    className="svg-icon-1tx svg-icon-primary me-2"
                                  />
                                  Incluye todo lo pendiente de pago al día de
                                  hoy ( adeudos, renta, depósitos, recargos,
                                  etc.)
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div className="table-responsive border-bottom mb-9">
                            <table className="table mb-3">
                              <thead>
                                <tr className="border-bottom fs-6 fw-bold text-muted">
                                  <th className="min-w-50px pb-2">Local</th>
                                  <th className="min-w-70px text-end pb-2">
                                    Monto
                                  </th>
                                  <th className="min-w-80px text-end pb-2">
                                    Depósito
                                  </th>
                                  <th className="min-w-100px text-end pb-2">
                                    Parcialidad
                                  </th>
                                  <th className="min-w-100px text-end pb-2">
                                    Fecha inicio
                                  </th>
                                  <th className="min-w-100px text-end pb-2">
                                    Fecha fin
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {data.locales.map((row: any) => (
                                  <tr
                                    key={row.id}
                                    className="fw-bold text-gray-700 fs-5 text-end"
                                  >
                                    <td className="d-flex align-items-center pt-6">
                                      <i className="fa fa-genderless text-success fs-2 me-2"></i>
                                      {row.local.num_local}
                                    </td>
                                    <td className="pt-6">
                                      {Number(row.monto).toLocaleString(
                                        'es-MX',
                                        {
                                          style: 'currency',
                                          currency: 'MXN',
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="pt-6">
                                      {Number(row.guante).toLocaleString(
                                        'es-MX',
                                        {
                                          style: 'currency',
                                          currency: 'MXN',
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="pt-6">{row.parcialidad}</td>
                                    <td className="pt-6">
                                      {
                                        new Date(row.fecha_inicio)
                                          .toISOString()
                                          .split('T')[0]
                                      }
                                    </td>
                                    <td className="pt-6">
                                      {
                                        new Date(row.fecha_fin)
                                          .toISOString()
                                          .split('T')[0]
                                      }
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className="mw-400px min-w-300px">
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-semibold pe-10 text-gray-600 fs-5">
                                  Saldo vencido:
                                </div>
                                <div
                                  className="text-end fw-bold fs-6 text-gray-800 fs-5"
                                  style={{
                                    color: saldoRestante > 0 ? 'red' : 'black',
                                  }}
                                >
                                  {saldoRestante.toLocaleString('es-MX', {
                                    style: 'currency',
                                    currency: 'MXN',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-semibold pe-10 text-gray-600 fs-5">
                                  Cargo {obtenerNombreMes()}:
                                </div>
                                <div className="text-end fw-bold fs-6 text-gray-800 fs-5">
                                  {total()}
                                </div>
                              </div>
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-semibold pe-10 text-gray-600 fs-5">
                                  Subtotal:
                                </div>
                                <div className="text-end fw-bold fs-6 text-gray-800 fs-5">
                                  {(saldoRestante + saldo).toLocaleString(
                                    'es-MX',
                                    {
                                      style: 'currency',
                                      currency: 'MXN',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </div>
                              </div>
                              <hr
                                style={{ backgroundColor: 'grey', opacity: 1 }}
                              />
                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-semibold pe-10 text-gray-600 fs-5">
                                  Descuento Global:
                                </div>
                                <div className="text-end fw-bold fs-6 text-gray-800 fs-5">
                                  {saldoDiscount()}
                                </div>
                              </div>

                              <div className="d-flex flex-stack mb-3">
                                <div className="fw-semibold pe-0 text-gray-600 fs-5">
                                  Descuento Pronto Pago:
                                </div>
                                <div
                                  className={
                                    'fw-bold fs-6 text-danger d-flex align-items-center flex-wrap'
                                  }
                                >
                                  <span className="pe-2"></span>
                                  <div
                                    className="fs-7 text-primary d-flex align-items-start cursor-pointer"
                                    data-kt-menu-trigger="hover"
                                    data-kt-menu-attach="parent"
                                    data-kt-menu-placement="bottom-start"
                                    data-kt-menu-flip="top"
                                  >
                                    <span className="pe-2  bg-primary me-2"></span>
                                    <div className="d-flex text-end fw-bold fs-6 text-gray-800 fs-5">
                                      {data.dctos.montoDctoPP > 0 &&
                                      data.saldo_actual -
                                        data.dctos.montoDctoGlobal -
                                        data.dctos.montoDctoPP >=
                                        0
                                        ? (
                                            data.dctos.montoDctoPP * -1
                                          ).toLocaleString('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : (-0).toLocaleString('es-MX', {
                                            style: 'currency',
                                            currency: 'MXN',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                    </div>
                                  </div>
                                  <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px"
                                    data-kt-menu="true"
                                  >
                                    <div className="menu-item px-3 text-gray-500">
                                      <KTSVG
                                        path="/media/icons/duotune/general/gen044.svg"
                                        className="svg-icon-1tx svg-icon-primary me-2"
                                      />
                                      Los descuentos para pronto pago solo son
                                      validos si los pagos no tiene saldo en
                                      adeudo.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr
                                style={{ backgroundColor: 'grey', opacity: 1 }}
                              />
                              <div className="d-flex flex-stack">
                                <div className="fw-semibold pe-10 text-gray-600 fs-5">
                                  Total a pagar :
                                </div>
                                <div className="text-end fw-bold fs-6 text-gray-800 fs-5">
                                  {getAmountActually2()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-stack pt-10 mb-3">
                        <a
                          href="#/"
                          className="btn btn-primary"
                          onClick={() => {
                            viewMovimientos(data.id);
                          }}
                        >
                          Ver movimientos
                        </a>
                        <a
                          href="#/"
                          className="btn btn-success min-w-300px"
                          onClick={() => {
                            realizarPago(data.id);
                          }}
                        >
                          Realizar pago
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
      <MovimientosModal
        id_row={idRow}
        contrato={data}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          setIdRow(0);
          setVisivilityModal(!visivilityModal);
        }}
      ></MovimientosModal>
      <RealizarPagoModal
        pagar={totalPago}
        id_row={idRow}
        descuentoG={descuentoG}
        show={visivilityPagoModal}
        handleClose={(updateData: boolean) => {
          if (updateData) {
            setReloadData(Math.random() * 40);
          }
          setIdRow(0);
          setVisivilityPagoModal(!visivilityPagoModal);
        }}
      ></RealizarPagoModal>
    </>
  );
};

export { View };
