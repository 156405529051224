import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../../_metronic/helpers'
import { removeContrato } from '../../../../service/catalog/Catalogs'
import { toast } from 'react-toastify';

type Props = {
    show: boolean
    id_row: number,
    handleClose: ( updateGrid:boolean ) => void

}

const RemoveModal: React.FC<Props> = ({ show, id_row, handleClose }) => {

    const [loading, setLoading] = useState(false)
    const [statusError, setStatusError] = useState('')

    const confirmRemove = () => {
        
        setLoading(true)
        setTimeout(() => {

            removeContrato( id_row )
            .then(( res ) => {

                const { data: { message } } = res;
                toast.success(message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
                handleClose(true);

            }
            ).catch(( err ) => {

                let resMessageToast: string = ""
                const { data: { message, details = null } } = err.response;
                resMessageToast =`${message}`;
                if( details.length ){
                    setStatusError(`${details[0]?.message}`)
                }
                setLoading(false)
                toast.error(resMessageToast, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
            );

        }, 1000)
    };
    return (
        <Modal
        id='kt_modal_active_user'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={ show }
        onHide={ () => {
            handleClose( false )
        } }
        >
            <div className='modal-content'>
                <div className='modal-header ' id='kt_modal_active_user_header'>
                    <h2> Eliminar plantilla </h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={ () => {
                        handleClose( false )
                    } }>
                    <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className="modal-body">
                    {
                        statusError && (
                        <div className='alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{ statusError }</div>
                        </div>
                        )
                    }
                    <div className="modal-body">
                        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen044.svg'
                                className='svg-icon-2tx svg-icon-warning me-4'
                            />
                            <div className='d-flex flex-stack flex-grow-1'>
                                <div className='fw-bold'>
                                <div className='fs-6 text-gray-600'>
                                    Eliminar registros de catálogos puede afectar en la consulta de información actual, está seguro que desea eliminar?
                                    <br />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center pt-8">
                        <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel" onClick={ () => {
                            handleClose( false )
                        } }>Cancelar</button>
                        <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit" disabled={ loading }
                        onClick={ confirmRemove }>
                        {!loading && <span className='indicator-label'>Confirmar</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Espere por favor...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export { RemoveModal }