import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllCPP(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.plaza_id >= -1) search += `&plaza_id=${filters.plaza_id}`;
  }
  search += `&fechaOpBetween=${filters.fecha_inicio},${filters.fecha_fin}`;
  const response = await axios.get(
    `${API_URL}finanzas/cuentasPorPagar?sort=id&limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getAllCPPWithoutPage(filters: any) {
  let search: string = '';
  if (filters) {
    if (filters.plaza_id >= -1) search += `&plaza_id=${filters.plaza_id}`;
  }
  search += `&fechaOpBetween=${filters.fecha_inicio},${filters.fecha_fin} 23:59:59`;
  const response = await axios.get(
    `${API_URL}finanzas/cuentasPorPagar?sort=id${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getOneCPP(id_row: number) {
  const response = await axios.get(
    `${API_URL}finanzas/cuentasPorPagar/${id_row}`
  );
  const { id, descripcion, tipo_cuenta_pago, fecha, monto, plaza } =
    response.data.doc.data;
  return {
    id: id || 0,
    descripcion: descripcion || '',
    tipo_cuenta_pago_id: tipo_cuenta_pago.id || 0,
    plaza_id: plaza.id || 0,
    fecha: fecha || new Date(),
    monto: monto || 0,
  };
}
export async function addCPP(body: any) {
  const { descripcion, tipo_cuenta_pago_id, fecha, monto, plaza_id } = body;
  const data = {
    descripcion: descripcion,
    tipo_cuenta_pago_id: tipo_cuenta_pago_id,
    plaza_id: plaza_id,
    fecha: fecha,
    monto: monto,
  };
  const response = await axios.post(`${API_URL}finanzas/cuentasPorPagar`, data);
  return response;
}
export async function updateCPP(body: any) {
  const { id, descripcion, tipo_cuenta_pago_id, fecha, monto, plaza_id } = body;
  const response = await axios.put(`${API_URL}finanzas/cuentasPorPagar/${id}`, {
    descripcion,
    tipo_cuenta_pago_id,
    fecha,
    monto,
    plaza_id,
  });
  return response;
}
export async function removeCPP(id: number) {
  const response = await axios.delete(
    `${API_URL}finanzas/cuentasPorPagar/${id}`
  );
  return response;
}
