import React from 'react';
import { Pagination } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
  reset: any;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loadingGrid,
  reset,
}) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const semaforoHtml = (semaforo: number) => {
    switch (semaforo) {
      case 0:
        return <i className="fa fa-genderless text-success fs-1"></i>;
      case 1:
        return <i className="fa fa-genderless text-warning fs-1"></i>;
      case 2:
        return <i className="fa fa-genderless text-danger fs-1"></i>;
      default:
        break;
    }
  };

  const semaforoRender = (semaforo: number) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <div className="text-muted text-center fs-6 w-100">
            {semaforoHtml(semaforo)}
          </div>
        </div>
      </>
    );
  };

  const saldoActual = (saldo: number) => {
    let formattedNumber = saldo.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (saldo < 0) {
      formattedNumber = formattedNumber.replace('-', '(') + ')';
    }

    return (
      <>
        <div
          className={
            saldo < 0
              ? 'fw-bold fs-6 text-danger'
              : 'fw-bold fs-6 text-gray-800'
          }
        >
          {formattedNumber}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-600px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-100px">Contrato</th>
                <th className="w-300px">Arrendatario</th>
                <th className="w-300px">Plaza</th>
                <th className="w-200px">Saldo</th>
                <th className="w-200px">Fecha último cargo</th>
                <th className="w-200px">Fecha último pago</th>
                <th className="text-end">Semáforo</th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((row: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.contrato}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row.arrendatario.nombre} ${row.arrendatario.apellido}`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.plaza ? row.plaza.nombre : 'N/A'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start fw-bold flex-column fs-6">
                          {saldoActual(Number(row.saldo))}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.fecha_ult_cargo
                            ? new Date(row.fecha_ult_cargo)
                                .toISOString()
                                .split('T')[0]
                            : 'N/A'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.fecha_ult_pago
                            ? new Date(row.fecha_ult_pago)
                                .toISOString()
                                .split('T')[0]
                            : 'N/A'}
                        </div>
                      </div>
                    </td>
                    <td>{semaforoRender(row.semaforo)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            reset={reset}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
