import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { KTSVG } from '../../../../_metronic/helpers';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
import { CreateModal } from '../_modals/CreateModal';
import { RemoveModal } from '../_modals/RemoveModal';
import { useGetAll, useGetAllWithoutPages } from '../hooks/CuentasPagarHook';
import { DownloadExcel } from '../../../../_metronic/helpers/DownloadExcelHelper';

let fechaActual = new Date();
let fecha_inicio_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth(),
  1
);
let fecha_fin_init = new Date(
  fechaActual.getFullYear(),
  fechaActual.getMonth() + 1,
  0
);

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reset, setReset] = useState(Math.random() * 40);

  const [idRow, setIdRow] = useState(0);
  const [idDelete, setIdDelete] = useState(0);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    plaza_id: -2,
    fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
    fecha_fin: fecha_fin_init.toISOString().split('T')[0],
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const { dataExcel } = useGetAllWithoutPages(reloadGrid, initFilterValues);
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const addRegister = () => {
    setTitleModal('Nuevo gastos operativos');
    setVisivilityModal(!visivilityModal);
  };
  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal('Editar gastos operativos');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (id: number) => {
    setIdDelete(id);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };
  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        plaza_id: -2,
        fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
        fecha_fin: fecha_fin_init.toISOString().split('T')[0],
      });
    }
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      plaza_id: -2,
      fecha_inicio: fecha_inicio_init.toISOString().split('T')[0],
      fecha_fin: fecha_fin_init.toISOString().split('T')[0],
    });
    setReloadGrid(Math.random() * 40);
    setCurrentPage(1);
    setReset(Math.random() * 40);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const download = () => {
    let columnsName = [
      'Plaza',
      'Tipo de cuenta',
      'Descripción',
      'Fecha pago',
      'Monto',
    ];
    let data: any = dataExcel.map((item: any) => {
      return {
        plaza: item.plaza?.nombre,
        tipo_cuenta_pago: item.tipo_cuenta_pago?.descripcion,
        descripcion: item.descripcion,
        fecha: new Date(item.fecha).toISOString().split('T')[0],
        monto: Number(item.monto).toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      };
    });
    const response = {
      sheetName: 'Gastos',
      fileName: 'gastos_operativos.xlsx',
      title: 'Gastos operativos',
      columnsName: columnsName,
      columns: [
        {
          key: 'plaza',
          width: 20,
        },
        {
          key: 'tipo_cuenta_pago',
          width: 20,
        },
        {
          key: 'descripcion',
          width: 20,
        },
        {
          key: 'fecha',
          width: 20,
        },
        {
          key: 'monto',
          width: 20,
        },
      ],
      data: data,
    };
    DownloadExcel(response);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              />

              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                {userHasRole(['AUDI', 'OPER']) && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light-primary"
                    id="kt_toolbar_primary_button"
                    onClick={addRegister}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5 svg-icon-gray-500 me-1"
                    />
                    Nuevo
                  </a>
                )}
                <a
                  href="#/"
                  className="btn btn-sm btn-light-success ms-5"
                  title="Descargar excel"
                  onClick={download}
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil009.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Excel
                </a>
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onRemoveRegister={removeRegister}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              onEditRegister={editRegister}
              reset={reset}
            />
          </div>
        </div>
      </div>
      <CreateModal
        id_row={idRow}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
          setIdRow(0);
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
      <RemoveModal
        show={visivilityRemoveModal}
        id_row={idDelete}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
          }
          setIdDelete(0);
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
