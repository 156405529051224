import { useState, useEffect } from 'react'
import { getAllUsuariosPlazas } from '../../../../service/catalog/UsuariosPlazas'
import { UsuariosPlazaModel } from '../models/UsuariosPlazaModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number ) => {

    const [ data, setData ] = useState<UsuariosPlazaModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllUsuariosPlazas( startIndex, endIndex ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid ]);
    return { data, count, loadingRequest }
}
