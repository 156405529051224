import React, { useState, useRef } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  addContrato,
  updateContrato,
} from '../../../../service/catalog/Catalogs';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/ContratoHook';
import { useSelectPlazas } from '../../plazas/hooks/PlazaHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const ContratoSchema = Yup.object().shape({
  plaza_id: Yup.number()
    .min(0, 'Seleccione una plaza.')
    .required('Plaza es equerido.'),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { plazas } = useSelectPlazas();
  const contratoRef = useRef<HTMLInputElement>(null);

  const onOpenFile = () => {
    contratoRef?.current?.click();
  };
  const formContrato = useFormik({
    initialValues: initValues,
    validationSchema: ContratoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!values.file && values.contrato === '') {
        toast.error('Por favor seleccione un documento válido.', {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setSubmitting(false);
      } else {
        setLoading(true);
        setTimeout(() => {
          if (values.id > 0) {
            updateContrato(values)
              .then((res) => {
                const {
                  data: { message },
                } = res;
                toast.success(message, {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                formContrato.resetForm();
                setLoading(false);
                handleClose(true);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  setStatus(`${details[0]?.message}`);
                }
                setLoading(false);
                setSubmitting(false);
                toast.error(resMessageToast, {
                  position: 'top-right',
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              });
          } else {
            addContrato(values)
              .then((res) => {
                const {
                  data: { message },
                } = res;
                toast.success(message, {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                formContrato.resetForm();
                setLoading(false);
                handleClose(true);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  setStatus(`${details[0]?.message}`);
                }
                setLoading(false);
                setSubmitting(false);
                toast.error(resMessageToast, {
                  position: 'top-right',
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              });
          }
        }, 1000);
      }
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formContrato.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formContrato.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formContrato.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-5">
                <KTSVG
                  path="/media/icons/duotune/general/gen044.svg"
                  className="svg-icon-2tx svg-icon-primary me-5 mt-10"
                />
                <div className="d-flex flex-stack flex-grow-1">
                  <div className="fw-bold">
                    <div className="fs-6 text-primary">
                      Estás a punto de cargar una plantilla contrato. Para poder
                      procesarlo correctamente, necesitamos que agregues los
                      siguientes datos{' '}
                      <b>
                        {'{arrendatario}'} {'{locales}'} {'{monto}'}{' '}
                        {'{montoletra}'}
                        {'{fecha_inicio}'} {'{fecha_fin}'}
                      </b>{' '}
                      según corresponda al archivo. Esto nos ayudará a
                      identificar la información de manera adecuada.
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Plaza
                      </label>
                      {formContrato.touched.plaza_id &&
                        formContrato.errors.plaza_id && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formContrato.errors.plaza_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select mb-10"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...formContrato.getFieldProps('plaza_id')}
                    >
                      <option value={-2}>Seleccione</option>
                      <option value={-1}>Casas</option>
                      <option value={0}>Sin plaza</option>
                      {plazas.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="card-body d-flex justify-content-center text-center flex-column p-2">
                <a
                  href="#/"
                  className="text-gray-800 text-hover-primary d-flex flex-column"
                >
                  <div className="symbol symbol-75px mb-6">
                    <img
                      src={
                        formContrato.values.file || formContrato.values.contrato
                          ? toAbsoluteUrl('/media/svg/files/doc.svg')
                          : toAbsoluteUrl('/media/svg/files/upload.svg')
                      }
                      className="image-input-wrapper"
                      onClick={onOpenFile}
                      crossOrigin="anonymous"
                      alt="INE"
                    />
                  </div>
                  <div
                    className="required fs-5 fw-bolder mb-2"
                    onClick={onOpenFile}
                  >
                    CONTRATO (PLANTILLA)
                  </div>
                  <input
                    style={{ display: 'none' }}
                    ref={contratoRef}
                    type="file"
                    name="contrato"
                    accept=".doc, .docx"
                    onChange={(event) => {
                      if (event.currentTarget.files) {
                        let files = event.currentTarget.files;
                        if (files.length > 0) {
                          formContrato.setFieldValue('file', files[0]);
                        }
                      }
                    }}
                  />
                </a>
                <div className="fs-7 text-gray-400 mt-auto">
                  {' '}
                  Archivos permitidos: doc, docx.{' '}
                </div>
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formContrato.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
