import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getAllUsuariosPlazas( startIndex:number, endIndex:number ) {

    const response = await axios.get(`${API_URL}catalogos/plazasusuario?sort=id&limit=${startIndex}&page=${endIndex}`);
    const data = response.data.doc.data;
    return data;

}

export async function addUsuariosPlazas( body:any ) {

    const response = await axios.post(`${API_URL}catalogos/plazasusuario/`, body);
    return response;

}

export async function removeUsuarioPlaza( id:number ) {

    const response = await axios.delete(`${API_URL}catalogos/plazasusuario/${id}`);
    return response;

}