import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import axios from 'axios';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import {
  addContratoLocal,
  updateContratoLocal,
  cargoProrata,
} from '../../../service/contrato_local/ContratoLocal';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/ContratoLocalHook';
import { useSelectPlazas } from '../../catalogos/plazas/hooks/PlazaHook';
import { useSelectArrendatario } from '../../catalogos/arrendatarios/hooks/ArrendatarioHook';
import { useSelectLocal } from '../../catalogos/locales/hooks/LocalHook';
import { EditarLocalesModal } from './EditarLocales';
import Select from 'react-select';

type Props = {
  isDetail: boolean;
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
  updateGrid: (updateGrid: boolean) => void;
};
const ContratoLocalchema = Yup.object().shape({
  arrendatario_id: Yup.number()
    .min(1, 'Seleccione un arrendatario.')
    .required('Arrendatario es requerido.'),
  plaza_id: Yup.number()
    .min(-1, 'Seleccione una plaza.')
    .required('Plaza es requerido.'),
  frecuencia_id: Yup.number()
    .min(1, 'Seleccione una frecuencia.')
    .required('Frecuencia es requerido.'),
  locales: Yup.array(),
});

const ContratoLocalDetailschema = Yup.object().shape({
  local_id: Yup.number()
    .min(1, 'Seleccione un local.')
    .required('Local es requerido.'),
  monto: Yup.number()
    .min(1, 'Monto requerido.')
    .required('Monto es requerido.'),
  guante: Yup.number()
    .min(1, 'Depósito requerido.')
    .required('Depósito requerido.'),
  parcialidad: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  fecha_inicio: Yup.date().required('Fecha inicio es requerido.'),
  fecha_fin: Yup.date()
    .min(Yup.ref('fecha_inicio'), 'Debe ser mayor')
    .required('Fecha fin es requerido.'),
});

let fechaActual = new Date();
let fecha_fin_init = new Date(
  fechaActual.getFullYear() + 1,
  fechaActual.getMonth(),
  0
);

const initValuesGrid = {
  local_id: 0,
  local: {
    id: 0,
    num_local: '',
  },
  monto: 0,
  guante: 0,
  parcialidad: 1,
  fecha_inicio: fechaActual.toISOString().split('T')[0],
  fecha_fin: fecha_fin_init.toISOString().split('T')[0],
};

const CreateModal: React.FC<Props> = ({
  isDetail,
  id_row,
  show,
  handleClose,
  title,
  updateGrid,
}) => {
  const [visivilityEditModal, setVisivilityEditModal] = useState(false);
  const [dataEdit, setDataEdit] = useState(initValuesGrid);

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCP, setLoadingCP] = useState(false);
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [loadingGridDelete, setLoadingGridDelete] = useState(false);
  const [loadingXF, setLoadingXF] = useState(false);
  const [loadingContrato, setLoadingContrato] = useState(false);

  const { initValues } = useGetOne(id_row);

  const [plazaIdSelected, seTplazaIdSelected] = useState(-1);

  const { plazas } = useSelectPlazas();
  const { data: arrendatarios } = useSelectArrendatario();
  const { data: locales } = useSelectLocal(plazaIdSelected);
  // const { catalog: frecuencia } = useSelectGeneric('FRECUENCIA');

  const changePlaza = (e: any) => {
    const id = e.target.value;
    formContratoLocal.setFieldValue('plaza_id', Number(id));
    seTplazaIdSelected(Number(id));
  };

  const handleAdd = (array: any, item: object) => {
    const newArray = [...array, item];
    return newArray;
  };

  const deleteLocal = (item: any) => {
    setLoadingGridDelete(true);
    setTimeout(() => {
      let array = formContratoLocal.values.locales;
      const newArray = array.filter((e: any) => e?.local_id !== item.local_id);
      formContratoLocal.setFieldValue(`locales`, newArray);
      setLoadingGridDelete(false);
    }, 1000);
  };
  const editLocal = (item: any) => {
    setVisivilityEditModal(true);
    setDataEdit(item);
  };

  const changeLocal = (e: any) => {
    const id = e.target.value;
    const num_local =
      e.target.options[e.target.selectedIndex].getAttribute('num_local');
    formContratoLocalDetails.setFieldValue('local_id', Number(id));
    formContratoLocalDetails.setFieldValue('local', {
      id: Number(id),
      num_local: num_local,
    });
  };

  const handleArrendatarios = (selectedOption: any) => {
    const id = selectedOption.value;
    setSelectedOption(selectedOption);
    formContratoLocal.setFieldValue('arrendatario_id', Number(id));
  };

  useEffect(() => {
    if (initValues) {
      if (initValues.id > 0) {
        const arre = arrendatarios.find(
          (arrendatario) => arrendatario.id === initValues.arrendatario_id
        );
        const initArrendatario: any = {
          label: `${arre?.nombre} ${arre?.apellido}`,
          value: initValues.arrendatario_id,
        };

        setSelectedOption(initArrendatario);
      }
      seTplazaIdSelected(initValues.plaza_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues]);

  const totalRender = () => {
    const total = formContratoLocal.values.locales.reduce((sum, local) => {
      return sum + local.monto;
    }, 0);

    return (
      <>
        <span data-kt-element="grand-total">
          {total.toLocaleString('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </>
    );
  };
  const totalGuante = () => {
    const total = formContratoLocal.values.locales.reduce((sum, local) => {
      return sum + local.guante;
    }, 0);

    return (
      <>
        <span data-kt-element="grand-total">
          {total.toLocaleString('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </>
    );
  };
  const onCargoProrrata = () => {
    setLoadingCP(true);
    setTimeout(() => {
      const id = formContratoLocal.values.id;
      cargoProrata(id)
        .then((res) => {
          const {
            data: { message },
          } = res;
          toast.success(message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setLoadingCP(false);
        })
        .catch((err) => {
          let resMessageToast: string = '';
          const {
            data: { message },
          } = err.response;
          resMessageToast = `${message}`;
          setLoadingCP(false);
          toast.error(resMessageToast, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        });
    }, 1000);
  };

  const onDownloadContrato = async () => {
    setLoadingContrato(true);
    setTimeout(() => {
      const id = formContratoLocal.values.id;
      axios({
        url: `${process.env.REACT_APP_API}catalogos/contratosArrendatarios/contratos/${id}`,
        method: 'POST',
        responseType: 'blob',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `contrato-plaza-${id}.docx`);
          document.body.appendChild(link);
          link.click();
          updateGrid(true);
          setLoadingContrato(false);
        })
        .catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text());
          toast.error(responseObj.message, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setLoadingContrato(false);
        });
    }, 1000);
  };
  const onDownloadContratoXF = async () => {
    setLoadingXF(true);
    setTimeout(() => {
      const id = formContratoLocal.values.id;
      axios({
        url: `${process.env.REACT_APP_API}catalogos/contratosArrendatarios/contratosxf/${id}`,
        method: 'POST',
        responseType: 'blob',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `contrato-plaza-${id}.docx`);
          document.body.appendChild(link);
          link.click();
          updateGrid(true);
          setLoadingXF(false);
        })
        .catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text());
          toast.error(responseObj.message, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setLoadingXF(false);
        });
    }, 1000);
  };

  const formContratoLocalDetails = useFormik({
    initialValues: initValuesGrid,
    validationSchema: ContratoLocalDetailschema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoadingGrid(true);
      setTimeout(() => {
        if (
          formContratoLocal.values.locales.some(
            (e: any) => e?.local_id === values.local_id
          )
        ) {
          toast.error('Ya existe el local seleccionado.', {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setLoadingGrid(false);
        } else {
          formContratoLocal.setFieldValue(
            `locales`,
            handleAdd(formContratoLocal.values.locales, {
              local_id: Number(values.local_id),
              local: {
                id: Number(values.local_id),
                num_local: values.local.num_local,
              },
              monto: values.monto,
              guante: values.guante,
              parcialidad: Number(values.parcialidad),
              fecha_inicio: values.fecha_inicio,
              fecha_fin: values.fecha_fin,
            })
          );
          setLoadingGrid(false);
        }
      }, 1000);
    },
  });

  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const formContratoLocal = useFormik({
    initialValues: initValues,
    validationSchema: ContratoLocalchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const locales = values.locales;
      if (locales.length > 0) {
        setLoading(true);
        setTimeout(() => {
          if (values.id > 0) {
            updateContratoLocal(values)
              .then((res) => {
                const {
                  data: { message },
                } = res;
                toast.success(message, {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                formContratoLocal.resetForm();
                formContratoLocalDetails.resetForm();
                setSelectedOption(null);
                seTplazaIdSelected(-1);
                setLoading(false);
                handleClose(true);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  setStatus(`${details[0]?.message}`);
                }
                setLoading(false);
                setSubmitting(false);
                toast.error(resMessageToast, {
                  position: 'top-right',
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              });
          } else {
            addContratoLocal(values)
              .then((res) => {
                const {
                  data: { message },
                } = res;
                toast.success(message, {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                formContratoLocal.resetForm();
                formContratoLocalDetails.resetForm();
                setSelectedOption(null);
                seTplazaIdSelected(-1);
                setLoading(false);
                handleClose(true);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  setStatus(`${details[0]?.message}`);
                }
                setLoading(false);
                setSubmitting(false);
                toast.error(resMessageToast, {
                  position: 'top-right',
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              });
          }
        }, 1000);
      } else {
        toast.error('Por favor ingresa locales al contrato.', {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    },
  });
  return (
    <>
      <Modal
        id="kt_modal_create"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-xl"
        show={show}
        onHide={() => {
          formContratoLocal.resetForm();
          formContratoLocalDetails.resetForm();
          setSelectedOption(null);
          seTplazaIdSelected(-1);
          handleClose(false);
        }}
      >
        <div
          className="modal-content"
          style={{ opacity: visivilityEditModal ? 0.2 : 1 }}
        >
          <div className="modal-header">
            <h2>{title}</h2>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={() => {
                formContratoLocal.resetForm();
                formContratoLocalDetails.resetForm();
                setSelectedOption(null);
                seTplazaIdSelected(-1);
                handleClose(false);
              }}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotune/arrows/arr061.svg"
              />
            </div>
          </div>
          <div className="modal-body scroll-y mx-5">
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <form
                id="kt_modal_generic_form"
                className="form"
                action="#"
                onSubmit={formContratoLocal.handleSubmit}
              >
                {formContratoLocal.values.id > 0 && (
                  <div
                    className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-original-title="Enter invoice number"
                    data-kt-initialized="1"
                  >
                    <span className="fs-2 fw-bold text-gray-800">Folio: </span>
                    <span className="fs-2 fw-bold text-gray-500 ms-2">
                      {' '}
                      {formContratoLocal.values.id}
                    </span>
                  </div>
                )}

                <div className="text-gray-600">Información general</div>
                <div className="separator my-5"></div>
                <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                  <div className="w-100 d-flex">
                    <div className="pe-5" style={{ width: '33%' }}>
                      <div className="w-100 d-flex">
                        <label className="required fw-bold fs-6 mb-2 w-auto">
                          Arrendatario
                        </label>
                        {formContratoLocal.touched.arrendatario_id &&
                          formContratoLocal.errors.arrendatario_id && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocal.errors.arrendatario_id}
                              </span>
                            </div>
                          )}
                      </div>
                      <Select
                        options={arrendatarios}
                        placeholder={'Seleccione'}
                        onChange={handleArrendatarios}
                        value={selectedOption}
                        isDisabled={formContratoLocal.values.id > 0}
                        noOptionsMessage={() => {
                          return <span>No hay arrendatarios</span>;
                        }}
                        styles={{
                          placeholder: (provided, state) => ({
                            ...provided,
                            color: '#181c32',
                          }),
                          indicatorSeparator: (provided, state) => ({
                            ...provided,
                            display: 'none',
                          }),
                          dropdownIndicator: (provided, state) => ({
                            ...provided,
                            color: '#7E8199',
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            border: '1px solid #e4e6ef',
                            borderRadius: '6px',
                            padding: '3px',
                            fontSize: '1.1rem',
                            boxShadow: 'none',
                            backgroundColor: state.isDisabled
                              ? '#EFF2F5'
                              : 'white',
                            // color: state.isDisabled ? '#181c32' : 'white',
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: state.isDisabled ? '#181c32' : 'inherit',
                            fontWeight: 500,
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '1.1rem',
                          }),
                        }}
                      />

                      {/* <select
                                            className='form-select mb-3'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-allow-clear='true'
                                            disabled={ (formContratoLocal.values.id > 0)}
                                            { ...formContratoLocal.getFieldProps('arrendatario_id') }
                                            >
                                                <option value={ 0 } >Seleccione</option>
                                                {arrendatarios.map(( item, index ) =>(
                                                    <option key={index} value={ item.id }
                                                    >{ item.nombre } { item.apellido } ( { item.correo } )</option>
                                                ))}                                    
                                        </select> */}
                    </div>
                    <div className="pe-5" style={{ width: '33%' }}>
                      <div className="w-100 d-flex">
                        <label className="required fw-bold fs-6 mb-2 w-auto">
                          Plaza
                        </label>
                        {formContratoLocal.touched.plaza_id &&
                          formContratoLocal.errors.plaza_id && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocal.errors.plaza_id}
                              </span>
                            </div>
                          )}
                      </div>
                      <select
                        className="form-select mb-3"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        disabled={
                          formContratoLocal.values.locales.length > 0 ||
                          formContratoLocal.values.id > 0
                        }
                        {...formContratoLocal.getFieldProps('plaza_id')}
                        onChange={changePlaza}
                      >
                        <option value={-2}>Seleccione</option>
                        <option value={-1}>Casas</option>
                        <option value={0}>Sin plaza</option>
                        {plazas.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="pe-5" style={{ width: '33%' }}>
                      <div className="w-100 d-flex">
                        <label className="required fw-bold fs-6 mb-2 w-auto">
                          Frecuencia
                        </label>
                        {formContratoLocal.touched.frecuencia_id &&
                          formContratoLocal.errors.frecuencia_id && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocal.errors.frecuencia_id}
                              </span>
                            </div>
                          )}
                      </div>
                      <select
                        className="form-select mb-8"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        disabled={isDetail}
                        {...formContratoLocal.getFieldProps('frecuencia_id')}
                      >
                        <option value={1}>Mensual</option>
                        {/* { frecuencia.map(( item, index ) =>(
                                                    <option key={index} value={ item.id }
                                                    >{ item.name }</option>
                                                )) }                                     */}
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <form
                id="kt_modal_detail_form"
                className="form"
                action="#"
                onSubmit={formContratoLocalDetails.handleSubmit}
              >
                <div className="text-gray-600">Locales</div>
                <div className="separator my-5"></div>
                <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                  <div className="w-100 d-flex">
                    <div className="w-25 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Local
                        </label>
                        {formContratoLocalDetails.touched.local_id &&
                          formContratoLocalDetails.errors.local_id && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocalDetails.errors.local_id}
                              </span>
                            </div>
                          )}
                      </div>
                      <select
                        className="form-select mb-8"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        disabled={isDetail}
                        {...formContratoLocalDetails.getFieldProps('local_id')}
                        onChange={changeLocal}
                      >
                        <option
                          value={0}
                          {...{
                            num_local: '',
                          }}
                        >
                          Seleccione
                        </option>
                        {locales.map((item, index) => (
                          <option
                            key={index}
                            value={item.id}
                            {...{
                              num_local: item.num_local.toString(),
                            }}
                          >
                            {item.num_local}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-25 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Mensualidad
                        </label>
                        {formContratoLocalDetails.touched.monto &&
                          formContratoLocalDetails.errors.monto && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocalDetails.errors.monto}
                              </span>
                            </div>
                          )}
                      </div>
                      <NumericFormat
                        name="monto"
                        className="form-control mb-3"
                        value={formContratoLocalDetails.values.monto}
                        decimalScale={2}
                        decimalSeparator="."
                        fixedDecimalScale
                        disabled={isDetail}
                        placeholder="$ 0.00"
                        prefix="$ "
                        thousandSeparator=","
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          formContratoLocalDetails.setFieldValue(
                            'monto',
                            floatValue
                          );
                        }}
                      />
                    </div>
                    <div className="w-25 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Depósito
                        </label>
                        {formContratoLocalDetails.touched.guante &&
                          formContratoLocalDetails.errors.guante && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocalDetails.errors.guante}
                              </span>
                            </div>
                          )}
                      </div>
                      <NumericFormat
                        name="guante"
                        className="form-control mb-3"
                        value={formContratoLocalDetails.values.guante}
                        decimalScale={2}
                        decimalSeparator="."
                        fixedDecimalScale
                        disabled={isDetail}
                        placeholder="$ 0.00"
                        prefix="$ "
                        thousandSeparator=","
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          formContratoLocalDetails.setFieldValue(
                            'guante',
                            floatValue
                          );
                        }}
                      />
                    </div>
                    <div className="w-25 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Parcialidades depósito
                        </label>
                        {formContratoLocalDetails.touched.parcialidad &&
                          formContratoLocalDetails.errors.parcialidad && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocalDetails.errors.parcialidad}
                              </span>
                            </div>
                          )}
                      </div>
                      <select
                        className="form-select mb-8"
                        // defaultValue={ 1 }
                        data-kt-select2="true"
                        disabled={isDetail}
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        {...formContratoLocalDetails.getFieldProps(
                          'parcialidad'
                        )}
                      >
                        <option value={1}>1</option>
                        {/* <option value={3}>3</option>
                        <option value={6}>6</option>
                        <option value={9}>9</option>
                        <option value={12}>12</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                  <div className="w-100 d-flex">
                    <div className="w-25 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Fecha inicio
                        </label>
                        {formContratoLocalDetails.touched.fecha_inicio &&
                          formContratoLocalDetails.errors.fecha_inicio && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocalDetails.errors.fecha_inicio}
                              </span>
                            </div>
                          )}
                      </div>
                      <input
                        type="date"
                        value={
                          new Date(formContratoLocalDetails.values.fecha_inicio)
                            .toISOString()
                            .split('T')[0]
                        }
                        className="form-control"
                        onKeyDown={(e) => e.preventDefault()}
                        disabled={isDetail}
                        onChange={(e) => {
                          if (e.target.value) {
                            const fecha = new Date(e.target.value);
                            const fecha_fin = new Date(
                              fecha.getFullYear() + 1,
                              fecha.getMonth(),
                              0
                            );
                            formContratoLocalDetails.setFieldValue(
                              'fecha_inicio',
                              fecha.toISOString().split('T')[0]
                            );
                            formContratoLocalDetails.setFieldValue(
                              'fecha_fin',
                              fecha_fin.toISOString().split('T')[0]
                            );
                          }
                        }}
                      ></input>
                    </div>
                    <div className="w-25 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-95px">
                          Fecha fin
                        </label>
                        {formContratoLocalDetails.touched.fecha_fin &&
                          formContratoLocalDetails.errors.fecha_fin && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formContratoLocalDetails.errors.fecha_fin}
                              </span>
                            </div>
                          )}
                      </div>
                      <input
                        type="date"
                        disabled={isDetail}
                        value={
                          new Date(formContratoLocalDetails.values.fecha_fin)
                            .toISOString()
                            .split('T')[0]
                        }
                        onKeyDown={(e) => e.preventDefault()}
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value) {
                            const fecha = new Date(e.target.value)
                              .toISOString()
                              .split('T')[0];
                            formContratoLocalDetails.setFieldValue(
                              'fecha_fin',
                              fecha
                            );
                          }
                        }}
                      ></input>
                    </div>
                    <div className="w-25 mt-9">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary "
                        onClick={() => {
                          formContratoLocalDetails.submitForm();
                        }}
                        disabled={loadingGrid || isDetail}
                      >
                        {!loadingGrid && (
                          <span className="indicator-label">Agregar</span>
                        )}
                        {loadingGrid && (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Espere por favor...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-10">
                <div className="w-100 d-flex table-responsive">
                  <table
                    className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                    data-kt-element="items"
                  >
                    <thead>
                      <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                        <th className="min-w-100px w-100px">Local</th>
                        <th className="min-w-100px w-100px">Monto</th>
                        <th className="min-w-100px w-100px">Depósito</th>
                        <th className="min-w-100px w-100px">Parcialidades</th>
                        <th className="min-w-150px w-150px">Fecha inicio</th>
                        <th className="min-w-150px w-150px">Fecha fin</th>
                        <th className="min-w-75px w-75px text-end"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingGrid || loadingGridDelete ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="d-flex align-items-center">
                              <div className="text-muted text-center fs-6 w-100 m-5">
                                <span
                                  className="indicator-progress"
                                  style={{ display: 'block' }}
                                >
                                  Espere por favor...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : formContratoLocal.values.locales.length <= 0 ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="d-flex align-items-center">
                              <div className="text-muted text-center fs-6 w-100 m-5">
                                Sin datos
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        formContratoLocal.values?.locales.map((row: any) => (
                          <tr key={row.local_id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-6">
                                  {row.local.num_local}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-6">
                                  {row.monto.toLocaleString('es-MX', {
                                    style: 'currency',
                                    currency: 'MXN',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-6">
                                  {row.guante.toLocaleString('es-MX', {
                                    style: 'currency',
                                    currency: 'MXN',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-6">
                                  {row.parcialidad}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-6">
                                  {
                                    new Date(row.fecha_inicio)
                                      .toISOString()
                                      .split('T')[0]
                                  }
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start flex-column fs-6">
                                  {
                                    new Date(row.fecha_fin)
                                      .toISOString()
                                      .split('T')[0]
                                  }
                                </div>
                              </div>
                            </td>
                            <td className="pt-5 text-end">
                              {!isDetail && (
                                <>
                                  <a
                                    href="#/"
                                    className="btn btn-sm btn-icon btn-active-color-primary"
                                    onClick={() => {
                                      editLocal(row);
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen055.svg"
                                      className="svg-icon-4 me-1"
                                    />
                                  </a>
                                  <a
                                    href="#/"
                                    className="btn btn-sm btn-icon btn-active-color-primary"
                                    onClick={() => {
                                      deleteLocal(row);
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen027.svg"
                                      className="svg-icon-4 me-1"
                                    />
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                    <tfoot>
                      <tr
                        className="align-top fw-bold text-gray-700"
                        style={{ borderBottom: 'none' }}
                      >
                        <th colSpan={4}></th>
                        <th colSpan={1} className="fs-6 text-end">
                          Depósito:{' '}
                        </th>
                        <th colSpan={2} className="text-end fs-6 text-nowrap">
                          {totalGuante()}
                        </th>
                      </tr>
                      <tr
                        className="align-top fw-bold text-gray-700"
                        style={{ borderStyle: 'none' }}
                      >
                        <th colSpan={4}></th>
                        <th colSpan={1} className="fs-6 text-end">
                          Total:{' '}
                        </th>
                        <th colSpan={2} className="text-end fs-6 text-nowrap">
                          {totalRender()}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

            <div
              className={
                userHasRole(['AUDI', 'OPER']) && isDetail
                  ? 'text-end pt-15'
                  : 'text-center pt-15'
              }
            >
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formContratoLocal.resetForm();
                  formContratoLocalDetails.resetForm();
                  setSelectedOption(null);
                  seTplazaIdSelected(-1);
                  handleClose(false);
                }}
              >
                Cancelar
              </button>

              {userHasRole(['AUDI', 'OPER']) && isDetail && (
                <button
                  type="button"
                  onClick={onCargoProrrata}
                  className="btn btn-primary me-3"
                  data-kt-users-modal-action="submit"
                  disabled={loadingCP}
                >
                  {!loadingCP && (
                    <span className="indicator-label">
                      <KTSVG
                        path="/media/icons/duotune/finance/fin010.svg"
                        className="svg-icon-5 svg-icon-gray-500"
                      />
                      Cargo prorrata
                    </span>
                  )}
                  {loadingCP && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              )}
              {userHasRole(['AUDI', 'OPER']) && isDetail && (
                <button
                  type="button"
                  onClick={onDownloadContratoXF}
                  className="btn btn-primary me-3"
                  data-kt-users-modal-action="submit"
                  disabled={loadingXF}
                >
                  {!loadingXF && (
                    <span className="indicator-label">
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr004.svg"
                        className="svg-icon-5 svg-icon-gray-500"
                      />
                      Contrato XF
                    </span>
                  )}
                  {loadingXF && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              )}
              {userHasRole(['AUDI', 'OPER']) && isDetail && (
                <button
                  type="button"
                  onClick={onDownloadContrato}
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loadingContrato}
                >
                  {!loadingContrato && (
                    <span className="indicator-label">
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr004.svg"
                        className="svg-icon-5 svg-icon-gray-500"
                      />
                      Generar contrato
                    </span>
                  )}
                  {loadingContrato && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              )}
              {!isDetail && (
                <button
                  type="button"
                  onClick={() => {
                    formContratoLocal.submitForm();
                  }}
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Guardar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <EditarLocalesModal
        show={visivilityEditModal}
        dataEdit={dataEdit}
        handleEdit={(data: any) => {
          let array = formContratoLocal.values.locales;
          setLoadingGridDelete(true);
          setTimeout(() => {
            const locales = array.map((local: any) => {
              if (local.id === data.id) {
                return {
                  ...local,
                  guante: data.guante,
                  monto: data.monto,
                  fecha_inicio: data.fecha_inicio,
                  fecha_fin: data.fecha_fin,
                  parcialidad: data.parcialidad,
                };
              }
              return local;
            });
            formContratoLocal.setFieldValue(`locales`, locales);
            setLoadingGridDelete(false);
          }, 1000);
          setVisivilityEditModal(!visivilityEditModal);
        }}
        handleClose={(updateGrid: boolean) => {
          setDataEdit(initValuesGrid);
          setVisivilityEditModal(!visivilityEditModal);
        }}
      ></EditarLocalesModal>
    </>
  );
};
export { CreateModal };
