import React, { useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addLocal, updateLocal } from '../../../../service/catalog/Catalogs';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/LocalHook';
import { useSelectPlazas } from '../../plazas/hooks/PlazaHook';
import { useSelectGeneric } from '../../generico/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const LocalesSchema = Yup.object().shape({
  num_local: Yup.string().required('Requerido.'),
  medidas: Yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    'Sólo numeros'
  ),
  tipo_local_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  nivel_id: Yup.number(),
  // .min(1, 'Requerido')
  // .required("Requerido"),
  estatus_id: Yup.number().min(1, 'Requerido').required('Requerido'),
  socio: Yup.boolean(),
  nombre_socio: Yup.string().when('socio', {
    is: true,
    then: Yup.string()
      .max(100, 'No debe tener más de 100 caracteres')
      .required('Nombre de socio requerido.'),
  }),
  plaza_id: Yup.number(),
  num_int: Yup.string(),
  num_ext: Yup.string(),
  codigo_postal: Yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    'Sólo numeros'
  ),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { plazas } = useSelectPlazas();
  const { catalog: tipo_local } = useSelectGeneric('TIPO_LOCAL');
  const { catalog: estatus_local } = useSelectGeneric('EST_LOCAL');
  const { catalog: niveles } = useSelectGeneric('NIVELES');
  const changePlaza = (e: any) => {
    const id = e.target.value;
    const socio =
      e.target.options[e.target.selectedIndex].getAttribute('socio');
    const nombre_socio =
      e.target.options[e.target.selectedIndex].getAttribute('nombre_socio');
    const calle =
      e.target.options[e.target.selectedIndex].getAttribute('calle');
    const num_int =
      e.target.options[e.target.selectedIndex].getAttribute('num_int');
    const num_ext =
      e.target.options[e.target.selectedIndex].getAttribute('num_ext');
    const colonia =
      e.target.options[e.target.selectedIndex].getAttribute('colonia');
    const codigo_postal =
      e.target.options[e.target.selectedIndex].getAttribute('codigo_postal');
    const ciudad =
      e.target.options[e.target.selectedIndex].getAttribute('ciudad');
    const estado =
      e.target.options[e.target.selectedIndex].getAttribute('estado');

    formLocales.setFieldValue('socio', socio === 'true');
    formLocales.setFieldValue(
      'nombre_socio',
      nombre_socio !== '' ? nombre_socio : ''
    );
    formLocales.setFieldValue('calle', calle !== '' ? calle : '');
    formLocales.setFieldValue('num_int', num_int !== '' ? num_int : '');
    formLocales.setFieldValue('num_ext', num_ext !== '' ? num_ext : '');
    formLocales.setFieldValue('colonia', colonia !== '' ? colonia : '');
    formLocales.setFieldValue(
      'codigo_postal',
      codigo_postal !== '' ? codigo_postal : ''
    );
    formLocales.setFieldValue('ciudad', ciudad !== '' ? ciudad : '');
    formLocales.setFieldValue('estado', estado !== '' ? estado : '');
    formLocales.setFieldValue('plaza_id', Number(id));
  };

  const formLocales = useFormik({
    initialValues: initValues,
    validationSchema: LocalesSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateLocal(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formLocales.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addLocal(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formLocales.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog mw-1000px"
      show={show}
      onHide={() => {
        formLocales.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formLocales.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formLocales.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="text-gray-600">Información general</div>
              <div className="separator my-5"></div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Plaza</label>
                      {formLocales.touched.plaza_id &&
                        formLocales.errors.plaza_id && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formLocales.errors.plaza_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      disabled={formLocales.values.id > 0}
                      {...formLocales.getFieldProps('plaza_id')}
                      onChange={changePlaza}
                    >
                      <option
                        value={-1}
                        {...{
                          socio: 'false',
                          nombre_socio: '',
                          calle: '',
                          num_int: '',
                          num_ext: '',
                          colonia: '',
                          codigo_postal: '',
                          ciudad: '',
                          estado: '',
                        }}
                      >
                        Casas
                      </option>
                      <option
                        value={0}
                        {...{
                          socio: 'false',
                          nombre_socio: '',
                          calle: '',
                          num_int: '',
                          num_ext: '',
                          colonia: '',
                          codigo_postal: '',
                          ciudad: '',
                          estado: '',
                        }}
                      >
                        Sin plaza
                      </option>
                      {plazas.map((item, index) => (
                        <option
                          key={index}
                          value={item.id}
                          {...{
                            socio: item.socio.toString(),
                            nombre_socio: item.nombre_socio.toString(),
                            calle: item.calle.toString(),
                            num_int: item.num_int.toString(),
                            num_ext: item.num_ext.toString(),
                            colonia: item.colonia.toString(),
                            codigo_postal: item.codigo_postal.toString(),
                            ciudad: item.ciudad.toString(),
                            estado: item.estado.toString(),
                          }}
                        >
                          {item.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        No. Local
                      </label>
                      {formLocales.touched.num_local &&
                        formLocales.errors.num_local && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formLocales.errors.num_local}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Número de local"
                      {...formLocales.getFieldProps('num_local')}
                      className="form-control mb-3"
                      type="text"
                      name="num_local"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Medidas ( m² )
                      </label>
                      {formLocales.touched.medidas &&
                        formLocales.errors.medidas && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formLocales.errors.medidas}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Medidas"
                      {...formLocales.getFieldProps('medidas')}
                      className="form-control mb-3"
                      type="text"
                      name="medidas"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-300px pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Tipo de local
                      </label>
                      {formLocales.touched.tipo_local_id &&
                        formLocales.errors.tipo_local_id && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formLocales.errors.tipo_local_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...formLocales.getFieldProps('tipo_local_id')}
                      // onChange={ changePlaza }
                    >
                      <option value={0}>Seleccione</option>
                      {tipo_local.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-300px pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Estatus de local
                      </label>
                      {formLocales.touched.estatus_id &&
                        formLocales.errors.estatus_id && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formLocales.errors.estatus_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...formLocales.getFieldProps('estatus_id')}
                      // onChange={ changePlaza }
                    >
                      <option value={0}>Seleccione</option>
                      {estatus_local.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-300px pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Nivel</label>
                      {formLocales.touched.nivel_id &&
                        formLocales.errors.nivel_id && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formLocales.errors.nivel_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...formLocales.getFieldProps('nivel_id')}
                      // onChange={ changePlaza }
                    >
                      <option value={0}>Seleccione</option>
                      {niveles.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-gray-600 mt-5">Dirección</div>
              <div className="separator my-5"></div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Calle</label>
                      {formLocales.touched.calle && formLocales.errors.calle && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formLocales.errors.calle}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Calle"
                      {...formLocales.getFieldProps('calle')}
                      className="form-control mb-3"
                      maxLength={100}
                      disabled={formLocales.values.plaza_id > 0}
                      type="text"
                      name="calle"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        No. Exterior
                      </label>
                      {formLocales.touched.num_ext &&
                        formLocales.errors.num_ext && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formLocales.errors.num_ext}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="No. Exterior"
                      {...formLocales.getFieldProps('num_ext')}
                      className="form-control mb-3"
                      maxLength={10}
                      type="text"
                      disabled={formLocales.values.plaza_id > 0}
                      name="num_ext"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        No. Interior
                      </label>
                      {formLocales.touched.num_int &&
                        formLocales.errors.num_int && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formLocales.errors.num_int}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="No. Interior"
                      {...formLocales.getFieldProps('num_int')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={10}
                      name="num_int"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-75 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Colonia
                      </label>
                      {formLocales.touched.colonia &&
                        formLocales.errors.colonia && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formLocales.errors.colonia}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Colonia"
                      {...formLocales.getFieldProps('colonia')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      name="colonia"
                      disabled={formLocales.values.plaza_id > 0}
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">C.P.</label>
                      {formLocales.touched.codigo_postal &&
                        formLocales.errors.codigo_postal && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formLocales.errors.codigo_postal}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="C.P."
                      {...formLocales.getFieldProps('codigo_postal')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={5}
                      disabled={formLocales.values.plaza_id > 0}
                      name="codigo_postal"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Ciudad</label>
                      {formLocales.touched.ciudad && formLocales.errors.ciudad && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formLocales.errors.ciudad}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Ciudad"
                      {...formLocales.getFieldProps('ciudad')}
                      className="form-control mb-3"
                      type="text"
                      name="ciudad"
                      maxLength={50}
                      disabled={formLocales.values.plaza_id > 0}
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Estado</label>
                      {formLocales.touched.estado && formLocales.errors.estado && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formLocales.errors.estado}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Estado"
                      {...formLocales.getFieldProps('estado')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      disabled={formLocales.values.plaza_id > 0}
                      name="estado"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              {formLocales.values.plaza_id <= 0 ||
              formLocales.values.socio === true ? (
                <>
                  <div className="separator my-5"></div>

                  <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                    <div className="w-400px">
                      <div className="mt-10 mb-5 mx-auto w-50">
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          <span className="form-check-label fw-bold text-gray-400 me-5">
                            Incluir socio
                          </span>
                          <input
                            className="form-check-input"
                            name="socio"
                            type="checkbox"
                            disabled={formLocales.values.plaza_id > 0}
                            checked={formLocales.values.socio}
                            onChange={(e) => {
                              formLocales.handleChange(e);
                              if (formLocales.values.socio) {
                                formLocales.setFieldValue('nombre_socio', '');
                                formLocales.setFieldTouched(
                                  'nombre_socio',
                                  false,
                                  false
                                );
                              }
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="flex-row-fluid">
                      <div className="w-100 pe-5">
                        <div className="w-100 d-flex">
                          <label
                            className={
                              formLocales.values.socio
                                ? 'required fw-bold fs-6 mb-2 w-auto'
                                : 'fw-bold fs-6 mb-2 w-auto text-gray-500'
                            }
                          >
                            Nombre del socio
                          </label>
                          {formLocales.touched.nombre_socio &&
                            formLocales.errors.nombre_socio && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formLocales.errors.nombre_socio}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Nombre"
                          {...formLocales.getFieldProps('nombre_socio')}
                          className="form-control mb-3"
                          type="text"
                          name="nombre_socio"
                          disabled={
                            formLocales.values.plaza_id > 0 ||
                            !formLocales.values.socio
                          }
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div style={{ minHeight: '114px' }}></div>
              )}
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formLocales.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
