import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { KTSVG } from '../../../../_metronic/helpers';
import { NumericFormat } from 'react-number-format';
import { useGetResumen } from '../hooks/PagosHook';
import { toast } from 'react-toastify';
import { realizarPago, descargarRecibo } from '../../../service/pagos/Pagos';

type Props = {
  descuentoG: number,
  show: boolean;
  pagar: number;
  handleClose: (updateData: boolean) => void;
  id_row: number;
};
const RealizarPagoSchema = Yup.object().shape({
  mensualidad_concepto: Yup.string().when('mensualidad', {
    is: (val: any) => val > 0,
    then: Yup.string().required('Concepto es requerido.'),
    otherwise: Yup.string(),
  }),
  recargo_motivo: Yup.string().when('recargo', {
    is: (val: any) => val > 0,
    then: Yup.string().required('Concepto es requerido.'),
    otherwise: Yup.string(),
  }),
  descuento_motivo: Yup.string().when('descuento', {
    is: (val: any) => val > 0,
    then: Yup.string().required('Concepto es requerido.'),
    otherwise: Yup.string(),
  }),
});

const initValues = {
  mensualidad: 0,
  guante: 0,
  recargo: 0,
  descuento: 0,
  mensualidad_concepto: '',
  recargo_motivo: '',
  descuento_motivo: '',
};

const RealizarPagoModal: React.FC<Props> = ({pagar,descuentoG,show, id_row, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const { data } = useGetResumen(id_row);

  useEffect(() => {
    if (data.id > 0) {
      setLoadingInfo(false);
    } else {
      setLoadingInfo(true);
    }
  }, [data]);

  const formRealizarPago = useFormik({
    initialValues: initValues,
    validationSchema: RealizarPagoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const sumaTotal = calculate();
      setLoading(true);
      setTimeout(() => {
        if (sumaTotal <= 0) {
          toast.error('No puede realizar un pago sin un monto.', {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setLoading(false);
        } else {
          realizarPago(values, id_row)
            .then((res) => {
              const {
                data: { message, doc },
              } = res;
              const { data } = doc;
              descargarRecibo(data.id)
                .then((response) => {
                  toast.success(message, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                  formRealizarPago.resetForm();
                  setLoading(false);
                  setLoadingInfo(true);
                  handleClose(true);
                })
                .catch((error) => {
                  toast.error(error.message, {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                  setLoading(false);
                });
            })
            .catch((err) => {
              console.log(err)
              let resMessageToast: string = '';
              if(err.response){
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  setStatus(`${details[0]?.message}`);
                }
                setLoading(false);
                setSubmitting(false);
              }else{
                resMessageToast = "Conexion perdida con el servidor"

              }
              
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });

  const calculate = () => {
    const mensualidad = formRealizarPago.values.mensualidad
      ? formRealizarPago.values.mensualidad
      : 0;
    const descuento = formRealizarPago.values.descuento
      ? formRealizarPago.values.descuento
      : 0;
    const recargo = formRealizarPago.values.recargo
      ? formRealizarPago.values.recargo
      : 0;
    const guante = formRealizarPago.values.guante
      ? formRealizarPago.values.guante
      : 0;
    const calculo = mensualidad + recargo + guante - descuento;
    return calculo;
  };

  const sumaTotal = () => {
    const sumaTotal = calculate();
    let formattedNumber = sumaTotal.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (sumaTotal < 0) {
      formattedNumber = formattedNumber.replace('-', '(') + ')';
    }

    return (
      <>
        <div
          className={
            sumaTotal < 0
              ? 'text-end fw-bold fs-6 text-danger'
              : 'text-end fw-bold fs-6 text-gray-800'
          }
        >
          {formattedNumber}
        </div>
      </>
    );
  };
  const saldoPendiente = () => {
    const saldo = data.saldo;
    let formattedNumber = saldo.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (saldo < 0) {
      formattedNumber = formattedNumber.replace('-', '(') + ')';
    }

    return (
      <>
        <div
          className={
            saldo < 0
              ? 'fw-bold fs-6 text-danger'
              : 'fw-bold fs-6 text-gray-800'
          }
        >
          {formattedNumber}
        </div>
      </>
    );
  };

  return (
    <Modal
      id="kt_modal_realizar_pago"
      tabIndex={-1}
      pagar={pagar}
      aria-hidden="true"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      descuentoG={descuentoG}
      onHide={() => {
        formRealizarPago.resetForm();
        handleClose(false);
        setLoadingInfo(true);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>Realizar pago</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formRealizarPago.resetForm();
              handleClose(false);
              setLoadingInfo(true);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          {loadingInfo ? (
            <div className="d-flex align-items-center">
              <div className="text-muted text-center fs-6 w-100 m-5">
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </div>
            </div>
          ) : (
            <div className="row mb-5">
              <div className="w-50 pe-5">
                <div className="fw-semibold fs-7 text-gray-600 mb-1">
                  Saldo al corte:
                </div>
                {saldoPendiente()}
              </div>
              <div className="w-50 pe-5">
                <div>
                  <div className="fw-semibold fs-7 text-gray-600 mb-1">
                    Descuento pronto pago:
                  </div>
                </div>
                <div className='d-flex' >
                <div className="fw-bold fs-6 text-gray-800">
                  {data.monto_dcto_pp.toLocaleString('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                {
                  data.dctoPPObject.comision > 0 && (
                    <div className='fw-bold fs-6 ms-3' style={{color:"DeepSkyBlue",borderRadius:"7px"}} >
                  {`(${data.dctoPPObject.comision}%) valido hasta ${ data.dctoPPObject.fecha}`}
                  </div>
                  )
                }
                </div>
              </div>
              <div className="w-50 pe-5 mt-5">
                <div className="fw-semibold fs-7 text-gray-600 mb-1">
                  Descuento global:
                </div>
                <div className='d-flex' >

                <div className="fw-bold fs-6 text-gray-800">
                  {(-1 *descuentoG).toLocaleString('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                {
                  data.dctoGlobalObject.comision > 0 && (
                    <div className='fw-bold fs-6 ms-3' style={{color:"DeepSkyBlue",borderRadius:"7px"}} >
                  {`(${data.dctoGlobalObject.comision}%) valido hasta ${data.dctoGlobalObject.fecha}`}
                  </div>
                  )
                }
                </div>
                
              </div>
              <div className="w-50 pe-5 mt-5">
                <div className="fw-semibold fs-7 text-gray-600 mb-1">
                  Total:
                </div>
                <div className="fw-bold fs-6  text-gray-800">
                    {pagar.toLocaleString('es-MX', {
                      style: 'currency',
                      currency: 'MXN',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
              </div>
            </div>
          )}
          <div className="separator my-5"></div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formRealizarPago.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Mensualidad
                      </label>
                      {formRealizarPago.touched.mensualidad &&
                        formRealizarPago.errors.mensualidad && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formRealizarPago.errors.mensualidad}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="mensualidad"
                      className="form-control mb-3"
                      value={formRealizarPago.values.mensualidad}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formRealizarPago.setFieldValue(
                          'mensualidad',
                          floatValue
                        );
                      }}
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Concepto
                      </label>
                      {formRealizarPago.touched.mensualidad_concepto &&
                        formRealizarPago.errors.mensualidad_concepto && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formRealizarPago.errors.mensualidad_concepto}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Concepto"
                      disabled={
                        formRealizarPago.values.mensualidad <= 0 ||
                        formRealizarPago.values.mensualidad === undefined
                      }
                      {...formRealizarPago.getFieldProps(
                        'mensualidad_concepto'
                      )}
                      className="form-control mb-3"
                      type="text"
                      name="mensualidad_concepto"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Descuento
                      </label>
                      {formRealizarPago.touched.descuento &&
                        formRealizarPago.errors.descuento && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formRealizarPago.errors.descuento}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="descuento"
                      className="form-control mb-3"
                      value={formRealizarPago.values.descuento}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formRealizarPago.setFieldValue('descuento', floatValue);
                      }}
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Concepto
                      </label>
                      {formRealizarPago.touched.descuento_motivo &&
                        formRealizarPago.errors.descuento_motivo && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formRealizarPago.errors.descuento_motivo}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Concepto"
                      disabled={
                        formRealizarPago.values.descuento <= 0 ||
                        formRealizarPago.values.descuento === undefined
                      }
                      {...formRealizarPago.getFieldProps('descuento_motivo')}
                      className="form-control mb-3"
                      type="text"
                      name="descuento_motivo"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Recargo
                      </label>
                      {formRealizarPago.touched.recargo &&
                        formRealizarPago.errors.recargo && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formRealizarPago.errors.recargo}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="recargo"
                      className="form-control mb-3"
                      value={formRealizarPago.values.recargo}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formRealizarPago.setFieldValue('recargo', floatValue);
                      }}
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Concepto
                      </label>
                      {formRealizarPago.touched.recargo_motivo &&
                        formRealizarPago.errors.recargo_motivo && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formRealizarPago.errors.recargo_motivo}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Concepto"
                      disabled={
                        formRealizarPago.values.recargo <= 0 ||
                        formRealizarPago.values.recargo === undefined
                      }
                      {...formRealizarPago.getFieldProps('recargo_motivo')}
                      className="form-control mb-3"
                      type="text"
                      name="recargo_motivo"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Depósito
                      </label>
                      {formRealizarPago.touched.guante &&
                        formRealizarPago.errors.guante && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formRealizarPago.errors.guante}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="guante"
                      className="form-control mb-3"
                      value={formRealizarPago.values.guante}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formRealizarPago.setFieldValue('guante', floatValue);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="separator my-5"></div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-100 pe-5">
                    
                    <div className="d-flex justify-content-between">
                    <div style={{color:"red",maxWidth:"400px"}} className='max-w-300px' >
                    **Los descuentos pronto pago solo estan disponibles al contar con $0 adeudo y en pagos de una solo exhibición.
                    </div>
                      <div className="mw-300px">
                        
                        <div className="d-flex flex-stack">
                          
                          <div className="fw-semibold pe-10 text-gray-600 fs-7">
                            Total:
                          </div>

                          {sumaTotal()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formRealizarPago.resetForm();
                  handleClose(false);
                  setLoadingInfo(true);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-success"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">Realizar pago</span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { RealizarPagoModal };
