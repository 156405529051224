import { useState, useEffect } from 'react'
import { getUsers, getRoles, getUser, getSelectUsuarios } from '../../../service/users/Usuarios'
import { UserSelectModel } from '../../../modules/auth/models/UserSelectModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = ( realoadGrid: number, startIndex: number, endIndex: number ) => {

    const [ users, setUsers ] = useState([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const { data, count} = await getUsers( startIndex, endIndex)
            if( data ){
                setUsers(data);
                setCount(count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid ]);
    return { users, count, loadingRequest }
}

export const useGetUser = ( id:number, realoadInfo: boolean, init:any ) => {
    
    const [ user, setUser ] = useState<any>(init);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getUser( id ).then().catch((error) => {
                history.push('/error/500')
            });
            setUser(data);
         };
         fetchPost();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ realoadInfo ]);
    return { user }
}

export const useGetRoles = () => {

    const [ roles, setRoles ] = useState<any[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getRoles().then().catch((error) => {
                history.push('/error/500')
            });
            setRoles(data);
        };
        fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { roles }
}
export const useSelectUsuarios = ( ) => {

    const [ usuarios, setUsuarios ] = useState<UserSelectModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getSelectUsuarios().then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setUsuarios(res);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { usuarios }
}

