import React, { useState, useEffect } from 'react';
// import { KTSVG } from '../../../../_metronic/helpers'
import * as Yup from 'yup';
import { useSelectPlazas } from '../../catalogos/plazas/hooks/PlazaHook';
import { useSelectArrendatarioContrato } from '../../catalogos/arrendatarios/hooks/ArrendatarioHook';
import { useFormik } from 'formik';
import Select from 'react-select';

type Props = {
  initFilterValues: any;
  onSearchFilter: (values: any) => void;
  loading: boolean;
};
const filterSchema = Yup.object().shape({
  plaza_id: Yup.number().min(-1, 'Requerido.').required('Requerido.'),
  arrendatario_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
});

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  loading,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const { plazas } = useSelectPlazas();
  const [plazaIdSelected, seTplazaIdSelected] = useState(-1);
  const { data: arrendatarios } =
    useSelectArrendatarioContrato(plazaIdSelected);
  const [filterValues, setFilterValues] = useState(initFilterValues);

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  const changePlaza = (e: any) => {
    const id = Number(e.target.value);
    formFilter.setFieldValue('plaza_id', id);
    setSelectedOption(null);
    seTplazaIdSelected(id);
    formFilter.setFieldValue('arrendatario_id', 0);
  };
  const handleArrendatarios = (selectedOption: any) => {
    const id = selectedOption.value;
    setSelectedOption(selectedOption);
    formFilter.setFieldValue('arrendatario_id', Number(id));
  };
  const formFilter = useFormik({
    initialValues: filterValues,
    validationSchema: filterSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter(values);
    },
  });

  return (
    <>
      <div className="card-title align-items-start flex-column w-100">
        <form
          id="kt_modal_generic_form"
          className="form w-100"
          action="#"
          onSubmit={formFilter.handleSubmit}
        >
          <div className="d-flex flex-column flex-xl-row flex-row-fluid">
            <div className="w-100 d-flex">
              <div className="w-25 pe-5">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Plaza
                  </label>
                  {formFilter.touched.plaza_id &&
                    formFilter.errors.plaza_id && (
                      <span className="fs-6 text-danger d-flex  w-100 ms-5">
                        {formFilter.errors.plaza_id}
                      </span>
                    )}
                </div>
                <select
                  className="form-select mb-3"
                  data-kt-select2="true"
                  disabled={plazas.length > 0 ? false : true}
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  onChange={changePlaza}
                >
                  <option value={-2}>Seleccione</option>
                  <option value={-1}>Casas</option>
                  <option value={0}>Sin plaza</option>
                  {plazas.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.nombre}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-25 pe-5">
                <div className="w-100 d-flex">
                  <label className=" required fw-bold fs-6 mb-2 w-auto">
                    Arrendatario
                  </label>
                  {formFilter.touched.arrendatario_id &&
                    formFilter.errors.arrendatario_id && (
                      <span className="fs-6 text-danger d-flex  w-100 ms-5">
                        {formFilter.errors.arrendatario_id}
                      </span>
                    )}
                </div>

                <Select
                  options={arrendatarios}
                  placeholder={'Seleccione'}
                  onChange={handleArrendatarios}
                  value={selectedOption}
                  noOptionsMessage={() => {
                    return <span>No hay arrendatarios</span>;
                  }}
                  styles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: 'none',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: '#7E8199',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #e4e6ef',
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                    }),
                  }}
                />
                {/* <select
                                className='form-select mb-3'
                                data-kt-select2='true'
                                disabled={ ( arrendatarios.length > 0) ? false : true }
                                data-placeholder='Select option'
                                data-allow-clear='true'  
                                {...formFilter.getFieldProps('arrendatario_id')}
                                >
                                    <option value={ 0 } >Seleccione</option>
                                    {arrendatarios.map(( item, index ) =>(
                                        <option key={index} value={ item.id }
                                        >{ item.nombre } { item.apellido } ( { item.correo } )</option>
                                    ))}                                    
                            </select> */}
              </div>
              <div className="w-50 text-end">
                {/* <button type='submit' className='btn btn-sm btn-primary mt-10' data-kt-menu-dismiss='true'>
                                Buscar
                            </button> */}
                <button
                  type="button"
                  onClick={() => {
                    formFilter.submitForm();
                  }}
                  className="btn btn-sm btn-primary mt-10"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Buscar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Buscando...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export { Filter };
