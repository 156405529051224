import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { View } from './components/View'
import { useParams } from "react-router-dom";
import { useGetGenerico } from "./hooks/genericoHook";     

const GenericoPage: FC = () => {

    let { code } : { code:string } = useParams();
    const [ codeCurrent, setCodeCurrent] = useState('')
    const { title } = useGetGenerico( code );
    
    useEffect(() => {
      if( code ){
        setCodeCurrent(code);
      }
    }, [ code ])

    return (
      <>
        <PageTitle breadcrumbs={ [
          {
            title: 'Catálogos',
            path: `/catalogos/generico/${ codeCurrent }`,
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ] }>{ title }</PageTitle>
        <View code_catalogo={ codeCurrent } />
      </>
    )
}

export { GenericoPage }