import { useState, useEffect } from 'react'
import { getAllParametrosOperativos, getOneParametrosOperativos } from '../../../../service/catalog/ParametrosOperativos'
import { ParametroOperativoModel } from '../models/ParametroOperativoModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<ParametroOperativoModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllParametrosOperativos( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid ]);
    return { data, count, loadingRequest }
}

export const convertToLocalDate = (date:Date) => {

    let fecha = new Date(date);

    console.log(fecha);

    fecha.setDate(fecha.getDate()-1);
    fecha.setSeconds(fecha.getSeconds()-1);

    console.log(fecha);

    let newString = fecha.toISOString()
    .split('T')[0]

    console.log(newString);

    return newString;
}

export const useGetOne = ( id:number ) => {
    
    let fechaActual = new Date();
    let descuentoUnoIni = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 1);
    let descuentoUnoFin = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 10);

    let descuentoDosIni = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 11);
    let descuentoDosFin = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 21);

    let descuentoOtroIni = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 1);
    let descuentoOtroFin = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 2, 0);

    const initModel = {
        id: 0,
        plaza_id: -2,
        pcte_dcto_segundo_contrato: 0,
        promo_pcte_dcto_uno: 0,
        promo_dcto_ini_uno: descuentoUnoIni,
        promo_dcto_fin_uno: descuentoUnoFin,
        promo_pcte_dcto_dos: 0,
        promo_dcto_ini_dos: descuentoDosIni,
        promo_dcto_fin_dos: descuentoDosFin,
        dias_anticipacion_vencimiento: 0,
        dias_gracia: 0,
        otro_promo_pcte_dcto: 0,
        otro_promo_descripcion: "",
        otro_promo_fecha_ini: descuentoOtroIni,
        otro_promo_fecha_fin: descuentoOtroFin
    }
    const [ initValues, setInitValues ] = useState<ParametroOperativoModel>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOneParametrosOperativos( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}