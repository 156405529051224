import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import {  activeUser } from '../../../service/users/Usuarios'
import { toast } from 'react-toastify';

type Props = {
    show: boolean
    user: any,
    title: string,
    description: string,
    handleClose: ( updateGrid:boolean ) => void
}

const ActiveUserModal: React.FC<Props> = ({ show, user, title, description, handleClose }) => {

    const [loading, setLoading] = useState(false)
    const [statusError, setStatusError] = useState(false)
    const [statusErrorMsessage, setStatusErrorMessage] = useState('')
    const [userSelected, setUserSelected] = useState(user);
    useEffect(() => {
        if(user){
            setUserSelected(user);
        }
    }, [ user ]);

    const confirmActive = () => {
        
        setLoading(true)
        setTimeout(() => {
            activeUser( {
                id: userSelected.id,
                active: !userSelected.active,
            } )
            .then(( res ) => {

                const { data: { message } } = res;
                toast.success(message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
                handleClose( true )

            })
            .catch(( err ) => {

                let resMessageToast: string = ""
                const { data: { message, details = null } } = err.response;
                resMessageToast =`${message}`;
                if( details ){
                    setStatusErrorMessage(`${details[0]?.message}`)
                }
                setLoading(false)
                setStatusError(true);
                toast.error(resMessageToast, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        }, 1000)
    };
    return (
        <Modal
        id='kt_modal_active_user'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={ show }
        onHide={ () => {
            
            handleClose( false )
        } }
        // onEntered={loadStepper}
        >
            <div className='modal-content'>
                <div className='modal-header ' id='kt_modal_active_user_header'>
                    <h2>{ title }</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={ () => {
                        handleClose( false )
                    } }>
                    <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className="modal-body mx-5 ">
                    {
                        statusError && (
                        <div className='alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{ statusErrorMsessage }</div>
                        </div>
                        )
                    }
                    <div className='text-gray-600'>{ description }</div>
                    <div className="text-center pt-15">
                        <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel" onClick={ () => {
                            handleClose( false )
                        } }>Cancelar</button>
                        <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit" disabled={ loading }
                        onClick={ confirmActive }>
                        {!loading && <span className='indicator-label'>Confirmar</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            Espere por favor...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export { ActiveUserModal }