import { useState, useEffect } from 'react'
import { getAllContratos, getOneContrato } from '../../../../service/catalog/Catalogs'
import { ContratoModel } from '../models/ContratoModel'
import { useHistory } from 'react-router-dom'


export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<ContratoModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()

    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllContratos( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid]);
    return { data, count, loadingRequest }
}

export const useGetOne = ( id:number ) => {
    const initModel = {
        id: 0,
        contrato: "",
        plaza_id: -2,
    }
    const history = useHistory()
    const [ initValues, setInitValues ] = useState<ContratoModel>(initModel);
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOneContrato( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}
