import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getReporteSemaforoPagos( startIndex:number, endIndex:number, filters:any ) {
    let search : string = ''; 
    if( filters ){
        if( filters.plaza_id >= -1 ) search += `&plaza=${filters.plaza_id}`;
        if( filters.arrendatario_id > 0 ) search += `&arrendatario=${filters.arrendatario_id}`;
        if( filters.contrato > 0 ) search += `&contrato=${filters.contrato}`;
    }
    search += `&fecha_cargoOpBetween=${filters.fecha_inicio},${filters.fecha_fin}`;
    const response = await axios.get(`${API_URL}reportes/semaforo?sort=id&limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;
}