import * as ExcelJS from 'exceljs';
import { DownloadExcelModel } from './models/DownloadExcelModel';

export const DownloadExcel = async (options: DownloadExcelModel) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(options.sheetName);
  sheet.properties.defaultRowHeight = 20;

  sheet.getRow(1).height = 35;
  sheet.getRow(1).font = {
    name: 'Arial',
    family: 4,
    size: 18,
    bold: true,
    color: { argb: '55ABC0' },
  };

  sheet.mergeCells('A1', 'L1');
  sheet.getCell('A1').value = options.title;

  sheet.getRow(5).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'A7A7A7' },
  };
  sheet.getRow(5).font = {
    name: 'Arial',
    family: 4,
    size: 12,
    bold: true,
    color: { argb: 'FFFFFF' },
  };

  sheet.getRow(5).values = options.columnsName;
  sheet.columns = options.columns;

  const datarows: any = options.data;

  datarows.forEach((seller: any, index: any) => {
    const row: any = {};
    for (const c of options.columns) {
      row[c.key] = seller[c.key];
    }

    sheet.addRow(row);

    const rowNumber = index + 6; // Comenzar en la fila 6 (1 para la cabecera y 5 para otros elementos)
    const cell = sheet.getCell(`I${rowNumber}`); // Columna I es la columna "Conciliado"

    // Establecer el color de fondo en función del valor de "Conciliado"
    if (seller['conciliado'] === 'Conciliado') {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00FF00' }, // Fondo verde para "Conciliado"
      };
      cell.font = {
        color: { argb: '000000' }, // Letra en blanco
      };
    } else {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' }, // Fondo rojo para "No conciliado"
      };
      cell.font = {
        color: { argb: 'FFFFFF' }, // Letra en blanco
      };
    }

  });

  workbook.xlsx
    .writeBuffer()
    .then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const anchor = document.createElement('a');
      const url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = options.fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(url);
    })
    .catch((err) => console.log(err));
};
