import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getReporteContratosPorVencer(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.plaza_id >= -1) search += `&plaza_id=${filters.plaza_id}`;
    if( filters.contrato > 0 ) search += `&contrato=${filters.contrato}`;
  }
  const response = await axios.get(
    `${API_URL}reportes/porvencer?sort=plaza_id&limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}
