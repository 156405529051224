import React from 'react';
import { Pagination } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  // onChangeRegister: (id: number, row: any) => void;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
  reset: any;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  // onChangeRegister,
  onPageChange,
  loadingGrid,
  reset,
}) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  // const checkedHandler = (id: number, event: any) => {
  //   onChangeRegister(id, event);
  // };

  const calculate = (row: any) => {
    const mensualidad = Number(row.mensualidad) ? Number(row.mensualidad) : 0;
    const descuento = Number(row.descuento) ? Number(row.descuento) : 0;
    const recargo = Number(row.recargo) ? Number(row.recargo) : 0;
    const guante = Number(row.guante) ? Number(row.guante) : 0;
    const calculo = mensualidad + recargo + guante - descuento;

    let formattedNumber = calculo.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (calculo < 0) {
      formattedNumber = formattedNumber.replace('-', '(') + ')';
    }

    return (
      <>
        <div
          className={
            calculo < 0
              ? 'fw-bold fs-6 text-danger'
              : 'fw-bold fs-6 text-gray-800'
          }
        >
          {formattedNumber}
        </div>
      </>
    );
  };

  const conciliadoRender = (conciliado: number) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <div className="text-muted text-center fs-6 w-100">
            {conciliado ? (
              <i className="fa fa-check text-success fs-5"></i>
            ) : (
              <i className="fa fa-times text-danger fs-5"></i>
            )}
          </div>
        </div>
      </>
    );
  };

  // const dropdownRender = (row: any) => {
  //   return (
  //     <>
  //       <div className="d-flex justify-content-end flex-shrink-0">
  //         <input
  //           className="form-check-input me-3"
  //           type="checkbox"
  //           value={row.conciliado}
  //           name="roles"
  //           onChange={(event) => {
  //             const value = event.target.checked;
  //             checkedHandler(row.id, value);
  //           }}
  //           checked={row.conciliado}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-600px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-150px">Recibo</th>
                <th className="w-150px">Contrato</th>
                <th className="w-350px">Arrendatario</th>
                <th className="w-200px">Mensualidad</th>
                <th className="w-200px">Recargo</th>
                <th className="w-200px">Depósito</th>
                <th className="w-200px">Descuento</th>
                <th className="w-200px">Total</th>
                <th className="text-end">Conciliado</th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.id}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.contrato_arrendatario.id}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.contrato_arrendatario.arrendatario.nombre}{' '}
                          {row.contrato_arrendatario.arrendatario.apellido}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {Number(row.mensualidad).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {Number(row.recargo).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          {/* {new Date(row.fecha).toISOString().split('T')[0]} */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {Number(row.guante).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          {/* {new Date(row.fecha).toISOString().split('T')[0]} */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {Number(row.descuento).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {calculate(row)}
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.conciliado}
                        </div>
                      </div>
                    </td> */}

                    <td>{conciliadoRender(row.conciliado)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            reset={reset}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

// Historial pagos realizados

export { ListGrid };
