import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getAllParametrosOperativos( startIndex:number, endIndex:number, filters:any ) {

    let search : string = ''; 
    if( filters ){
        if( filters.plaza_id >= -1 ){
            search += `&plaza_id=${filters.plaza_id}`;
        }
    }
    const response = await axios.get(`${API_URL}catalogos/parametrosOperacion?sort=id&limit=${startIndex}&page=${endIndex}${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function getOneParametrosOperativos( id_row:number ) {


    const response = await axios.get(`${API_URL}catalogos/parametrosOperacion/${id_row}`);
    const { 
        id, plaza_id, pcte_dcto_segundo_contrato, promo_pcte_dcto_uno,
        promo_dcto_ini_uno, promo_dcto_fin_uno, promo_pcte_dcto_dos, promo_dcto_ini_dos, 
        promo_dcto_fin_dos, dias_anticipacion_vencimiento, dias_gracia, otro_promo_pcte_dcto,
        otro_promo_descripcion, otro_promo_fecha_ini, otro_promo_fecha_fin
    } = response.data.doc.data;
    let fechaActual = new Date();
    let minSiguienteMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 1);
    let maxSiguienteMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 2, 0);
    return {
        id: id || 0,
        plaza_id: plaza_id,
        pcte_dcto_segundo_contrato: pcte_dcto_segundo_contrato || 0,
        promo_pcte_dcto_uno: promo_pcte_dcto_uno || 0,
        promo_dcto_ini_uno: promo_dcto_ini_uno || minSiguienteMes,
        promo_dcto_fin_uno: promo_dcto_fin_uno || maxSiguienteMes,
        promo_pcte_dcto_dos: promo_pcte_dcto_dos || 0,
        promo_dcto_ini_dos: promo_dcto_ini_dos || minSiguienteMes,
        promo_dcto_fin_dos: promo_dcto_fin_dos || maxSiguienteMes,
        dias_anticipacion_vencimiento: dias_anticipacion_vencimiento || 0,
        dias_gracia: dias_gracia || 0,
        otro_promo_pcte_dcto: otro_promo_pcte_dcto || 0,
        otro_promo_descripcion: otro_promo_descripcion || "",
        otro_promo_fecha_ini: otro_promo_fecha_ini || minSiguienteMes,
        otro_promo_fecha_fin: otro_promo_fecha_fin || maxSiguienteMes
    };

}
export async function addParametrosOperativos( body:any ) {

    const {
        plaza_id, pcte_dcto_segundo_contrato, promo_pcte_dcto_uno,
        promo_dcto_ini_uno, promo_dcto_fin_uno, promo_pcte_dcto_dos, promo_dcto_ini_dos, 
        promo_dcto_fin_dos, dias_anticipacion_vencimiento, dias_gracia, otro_promo_pcte_dcto,
        otro_promo_descripcion, otro_promo_fecha_ini, otro_promo_fecha_fin
     } = body;
     
    const data = {
        plaza_id: Number(plaza_id),
        pcte_dcto_segundo_contrato: Number(pcte_dcto_segundo_contrato),
        dias_anticipacion_vencimiento: Number(dias_anticipacion_vencimiento),
        dias_gracia: Number(dias_gracia),

        promo_pcte_dcto_uno: Number(promo_pcte_dcto_uno),
        promo_dcto_ini_uno: new Date(promo_dcto_ini_uno).toISOString().split('T')[0],
        promo_dcto_fin_uno: new Date(promo_dcto_fin_uno).toISOString().split('T')[0],

        promo_pcte_dcto_dos: Number(promo_pcte_dcto_dos),
        promo_dcto_ini_dos: new Date(promo_dcto_ini_dos).toISOString().split('T')[0],
        promo_dcto_fin_dos: new Date(promo_dcto_fin_dos).toISOString().split('T')[0],

        otro_promo_pcte_dcto: Number(otro_promo_pcte_dcto),
        otro_promo_descripcion: otro_promo_descripcion,
        otro_promo_fecha_ini: new Date(otro_promo_fecha_ini).toISOString().split('T')[0],
        otro_promo_fecha_fin: new Date(otro_promo_fecha_fin).toISOString().split('T')[0],
    }
    const response = await axios.post(`${API_URL}catalogos/parametrosOperacion`, data);
    return response;

}
export async function updateParametrosOperativos( body:any ) {

    const {
        id, plaza_id, pcte_dcto_segundo_contrato, promo_pcte_dcto_uno,
        promo_dcto_ini_uno, promo_dcto_fin_uno, promo_pcte_dcto_dos, promo_dcto_ini_dos, 
        promo_dcto_fin_dos, dias_anticipacion_vencimiento, dias_gracia, otro_promo_pcte_dcto,
        otro_promo_descripcion, otro_promo_fecha_ini, otro_promo_fecha_fin
     } = body;
     
    const response = await axios.put(`${API_URL}catalogos/parametrosOperacion/${id}`, {
        plaza_id: Number(plaza_id),
        pcte_dcto_segundo_contrato: Number(pcte_dcto_segundo_contrato),
        dias_anticipacion_vencimiento: Number(dias_anticipacion_vencimiento),
        dias_gracia: Number(dias_gracia),

        promo_pcte_dcto_uno: Number(promo_pcte_dcto_uno),
        promo_dcto_ini_uno: new Date(promo_dcto_ini_uno).toISOString().split('T')[0],
        promo_dcto_fin_uno: new Date(promo_dcto_fin_uno).toISOString().split('T')[0],

        promo_pcte_dcto_dos: Number(promo_pcte_dcto_dos),
        promo_dcto_ini_dos: new Date(promo_dcto_ini_dos).toISOString().split('T')[0],
        promo_dcto_fin_dos: new Date(promo_dcto_fin_dos).toISOString().split('T')[0],

        otro_promo_pcte_dcto: Number(otro_promo_pcte_dcto),
        otro_promo_descripcion: otro_promo_descripcion,
        otro_promo_fecha_ini: new Date(otro_promo_fecha_ini).toISOString().split('T')[0],
        otro_promo_fecha_fin: new Date(otro_promo_fecha_fin).toISOString().split('T')[0],
    });
    return response;

}
export async function removeParametrosOperativos( id:number ) {

    const response = await axios.delete(`${API_URL}catalogos/parametrosOperacion/${id}`);
    return response;

}