import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useSelectArrendatario } from '../../../catalogos/arrendatarios/hooks/ArrendatarioHook';
import { useSelectPlazas } from '../../../catalogos/plazas/hooks/PlazaHook';
import { useFormik } from 'formik';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { plazas } = useSelectPlazas();
  const { data: arrendatarios } = useSelectArrendatario();
  const [filterValues, setFilterValues] = useState(initFilterValues);

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  const handleArrendatarios = (selectedOption: any) => {
    const id = selectedOption.value;
    setSelectedOption(selectedOption);
    formFilter.setFieldValue('arrendatario_id', Number(id));
  };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter(values);
    },
  });

  return (
    <>
      <div className="card-title align-items-start flex-column">
        <a
          href="#/"
          className={
            formFilter.values.arrendatario_id > 0 ||
            formFilter.values.contrato > 0 ||
            formFilter.values.plaza_id >= -1 ||
            formFilter.values.fecha_inicio ||
            formFilter.values.fecha_fin
              ? 'btn btn-sm btn-primary'
              : 'btn btn-sm btn-light-primary'
          }
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>

          <div className="separator border-gray-200"></div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">Contrato: </label>
                <div>
                  <NumericFormat
                    name="contrato"
                    className="form-control mb-3"
                    value={formFilter.values.contrato}
                    decimalScale={0}
                    decimalSeparator="."
                    maxLength={20}
                    fixedDecimalScale
                    placeholder="0"
                    prefix=""
                    thousandSeparator=""
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      formFilter.setFieldValue(
                        'contrato',
                        floatValue ? floatValue : 0
                      );
                    }}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Arrendatario</label>
                {/* EVENTO PARA DETENER EL CERRADO DEL POPOVER */}
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={arrendatarios}
                    placeholder={'Seleccione'}
                    onChange={handleArrendatarios}
                    value={selectedOption}
                    noOptionsMessage={() => {
                      return <span>No hay arrendatarios</span>;
                    }}
                    styles={{
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: '#181c32',
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: 'none',
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        color: '#7E8199',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '1px solid #e4e6ef',
                        borderRadius: '6px',
                        padding: '3px',
                        fontSize: '1.1rem',
                        boxShadow: 'none',
                        backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '1.1rem',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">Plaza</label>
                <div>
                  <select
                    className="form-select"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...formFilter.getFieldProps('plaza_id')}
                  >
                    <option value={-2}>Seleccione</option>
                    <option value={-1}>Casas</option>
                    <option value={0}>Sin plaza</option>
                    {plazas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label fw-bold">
                  Fecha cargo inicio:{' '}
                </label>
                <div>
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(formFilter.values.fecha_inicio)
                        .toISOString()
                        .split('T')[0]
                    }
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        formFilter.setFieldValue('fecha_inicio', fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
              <div className="mb-10">
                <label className="form-label fw-bold">Fecha cargo fin: </label>
                <div>
                  <input
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(formFilter.values.fecha_fin)
                        .toISOString()
                        .split('T')[0]
                    }
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        formFilter.setFieldValue('fecha_fin', fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    setSelectedOption(null);
                    onCleanFilter();
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Filter };
