import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllconciliation(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.contrato_arrendatario_id > 0)
      search += `&contrato_arrendatario_id=${filters.contrato_arrendatario_id}`;
    if (filters.id > 0) search += `&id=${filters.id}`;
    if (filters.conciliado) {
      search += `&conciliado=1`;
    } else {
      search += `&conciliado=0`;
    }
    search += `&fechaOpBetween=${filters.fecha_inicio},${filters.fecha_fin} 23:59:59`;
  }
  const response = await axios.get(
    `${API_URL}finanzas/cobranza/bitacora?limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getAllconciliationWithoutPages(filters: any) {
  let search: string = '';
  let fecha_fin: string = '';
  if (filters) {
    if (filters.contrato_arrendatario_id > 0)
      search += `&contrato_arrendatario_id=${filters.contrato_arrendatario_id}`;
    if (filters.id > 0) search += `&id=${filters.id}`;
    if (filters.conciliado) {
      search += `&conciliado=1`;
    } else {
      search += `&conciliado=0`;
    }
    fecha_fin = `${filters.fecha_fin} 23:59:59`
    search += `&fechaOpBetween=${filters.fecha_inicio},${fecha_fin}`;
  }
  const response = await axios.get(
    `${API_URL}finanzas/cobranza/bitacora?sort=id${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function onChangeRegister(id: number, body: any) {
  const response = await axios.put(
    `${API_URL}finanzas/cobranza/bitacora/${id}`,
    {
      conciliado: body,
    }
  );
  return response;
}

export async function getAllConciliationReport(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.contrato_arrendatario_id > 0)
      search += `&contrato_arrendatario_id=${filters.contrato_arrendatario_id}`;
    if (filters.id > 0) search += `&id=${filters.id}`;

    if (filters.conciliado >= 0 && filters.conciliado != '') {
      search += `&conciliado=${filters.conciliado}`;
    }

    search += `&fechaOpBetween=${filters.fecha_inicio},${filters.fecha_fin} 23:59:59`;
  }
  const response = await axios.get(
    `${API_URL}finanzas/cobranza/bitacora?limit=${startIndex}&page=${endIndex}${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getAllconciliationWithoutPagesReport(filters: any) {
  let search: string = '';
  let fecha_fin: string = '';
  if (filters) {
    if (filters.contrato_arrendatario_id > 0)
      search += `&contrato_arrendatario_id=${filters.contrato_arrendatario_id}`;
    if (filters.id > 0) search += `&id=${filters.id}`;
    if (filters.conciliado >= 0 && filters.conciliado != '') {
      search += `&conciliado=${filters.conciliado}`;
    }
    fecha_fin = `${filters.fecha_fin} 23:59:59`
    search += `&fechaOpBetween=${filters.fecha_inicio},${fecha_fin}`;
  }
  const response = await axios.get(
    `${API_URL}finanzas/cobranza/bitacora?sort=id${search}`
  );
  const data = response.data.doc.data;
  return data;
}