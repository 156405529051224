import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getBalance() {

    const response = await axios.get(`${API_URL}reportes/saldos`)
    const data = response.data.doc.data;
    return data;

}

export async function getOcupation() {

    const response = await axios.get(`${API_URL}reportes/ocupacion`)
    const data = response.data.doc.data;
    return data;

}

export async function getResults() {

    const response = await axios.get(`${API_URL}reportes/resultados`)
    const data = response.data.doc.data;
    return data;

}

export async function getNotifications(notificationId :any) {

    const response = await axios.get(`${API_URL}notifications/${notificationId}`)   
    const data = response.data.data;
    return data;

}