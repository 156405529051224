import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
import axios from 'axios';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { toast } from 'react-toastify';
import { CreateModal } from '../_modals/CreateModal';
import { RemoveModal } from '../_modals/RemoveModal';
import { AddContratoModal } from '../_modals/AddContratoModal';
import { HistoryModal } from '../_modals/HistoryModal';
import { useGetAll, useGetAllWithoutPages } from '../hooks/ContratoLocalHook';
import { DownloadExcel } from '../../../../_metronic/helpers/DownloadExcelHelper';

const View: React.FC = () => {
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reset, setReset] = useState(Math.random() * 40);

  const [idHistoryRow, setIdHistoryRow] = useState(0);
  const [idRow, setIdRow] = useState(0);
  const [idDelete, setIdDelete] = useState(0);
  const [viewDetail, setViewDetail] = useState(false);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityHistoryModal, setVisivilityHistoryModal] = useState(false);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [visivilityModaContrato, setVisivilityModaContrato] = useState(false);
  const [titleModal, setTitleModal] = useState('');

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    plaza_id: -2,
    contrato: 0,
    arrendatario_id: 0,
  });
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const { dataExcel } = useGetAllWithoutPages(reloadGrid, initFilterValues);

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const addRegister = () => {
    setViewDetail(false);
    setTitleModal('Nuevo contrato');
    setVisivilityModal(!visivilityModal);
  };
  const editRegister = (id: number) => {
    setViewDetail(false);
    setIdRow(id);
    setTitleModal('Editar contrato');
    setVisivilityModal(!visivilityModal);
  };
  const viewRegister = (id: number, isDetail: boolean) => {
    setViewDetail(isDetail);
    setIdRow(id);
    setTitleModal('Detalle contrato');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (id: number) => {
    setIdDelete(id);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };
  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        plaza_id: -2,
        contrato: 0,
        arrendatario_id: 0,
      });
    }
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      plaza_id: -2,
      contrato: 0,
      arrendatario_id: 0,
    });
    setReloadGrid(Math.random() * 40);
    setCurrentPage(1);
    setReset(Math.random() * 40);
  };

  const onUploadFile = (id: number) => {
    setViewDetail(false);
    setIdRow(id);
    setTitleModal('Cargar contrato firmado');
    setVisivilityModaContrato(!visivilityModaContrato);
  };
  const onHistoryRegister = (id: number) => {
    setIdHistoryRow(id);
    setVisivilityHistoryModal(!visivilityHistoryModal);
  };
  const onDownloadContratoFirmado = (id: number) => {
    axios({
      url: `${process.env.REACT_APP_API}catalogos/contratosArrendatarios/firmado/${id}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `contrato-plaza-${id}.docx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(async (error) => {
        let responseObj = JSON.parse(await error.response.data.text());
        toast.error(responseObj.message, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };
  const onDownloadContrato = (id: number) => {
    axios({
      url: `${process.env.REACT_APP_API}catalogos/contratosArrendatarios/contratos/${id}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `contrato-plaza-${id}.docx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(async (error) => {
        let responseObj = JSON.parse(await error.response.data.text());
        toast.error(responseObj.message, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const download = () => {
    let columnsName = [
      'Contrato',
      'Arrendatario',
      'Plaza',
      'Frecuencia',
      'Monto Total',
    ];

    let data: any = dataExcel.map((item: any) => {
      return {
        contrato: item.id,
        arrendatario: item.arrendatario?.nombre,
        plaza: item.plaza?.nombre,
        frecuencia: item.frecuencia?.name,
        monto: Number(
          item.locales.reduce(
            (accum: number, obj: any) => accum + Number(obj.monto),
            0
          )
        ).toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      };
    });
    const response = {
      sheetName: 'Contratos',
      fileName: 'Contratos.xlsx',
      title: 'Contratos',
      columnsName: columnsName,
      columns: [
        {
          key: 'contrato',
          width: 20,
        },
        {
          key: 'arrendatario',
          width: 20,
        },
        {
          key: 'plaza',
          width: 20,
        },
        {
          key: 'frecuencia',
          width: 20,
        },
        {
          key: 'monto',
          width: 20,
        },
      ],
      data: data,
    };
    DownloadExcel(response);
  };

  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              />
              <div
                className="card-toolbar text-end"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                {userHasRole(['AUDI', 'OPER']) && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light-primary"
                    id="kt_toolbar_primary_button"
                    onClick={addRegister}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5 svg-icon-gray-500 me-1"
                    />
                    Nuevo
                  </a>
                )}

                <a
                  href="#/"
                  className="btn btn-sm btn-light-success ms-5"
                  title="Descargar excel"
                  onClick={download}
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil009.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Excel
                </a>
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onRemoveRegister={removeRegister}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              onEditRegister={editRegister}
              onViewRegister={viewRegister}
              onUploadFile={onUploadFile}
              onDownloadContratoFirmado={onDownloadContratoFirmado}
              onDownloadContrato={onDownloadContrato}
              onHistoryRegister={onHistoryRegister}
              reset={reset}
            />
          </div>
        </div>
      </div>
      <CreateModal
        isDetail={viewDetail}
        id_row={idRow}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
          setIdRow(0);
          setVisivilityModal(!visivilityModal);
        }}
        updateGrid={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
        }}
      ></CreateModal>
      <RemoveModal
        show={visivilityRemoveModal}
        id_row={idDelete}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
          }
          setIdDelete(0);
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
      <AddContratoModal
        id_row={idRow}
        title={titleModal}
        show={visivilityModaContrato}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
          }
          setIdRow(0);
          setVisivilityModaContrato(!visivilityModaContrato);
        }}
      ></AddContratoModal>
      <HistoryModal
        id_row={idHistoryRow}
        show={visivilityHistoryModal}
        handleClose={(updateGrid: boolean) => {
          setIdHistoryRow(0);
          setVisivilityHistoryModal(!visivilityHistoryModal);
        }}
      ></HistoryModal>
    </>
  );
};

export { View };
