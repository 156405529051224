/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { GenericBarChart } from '../../../_metronic/helpers/GenericBarChart';
import { ListsData } from './components/BalanceList';
import { ResultsList } from './components/ResultList';
import {
  useBalance,
  useOcupation,
  useResults,
  useGetNotifications,
} from './hooks/DashboardHook';
import { getNotifications } from '../../service/dashboard/Dashboard';

const DashboardPage: FC = () => {
  const { balances } = useBalance();
  const { results } = useResults();
  const { series, categories } = useOcupation();
  const { notifications } = useGetNotifications();
  const history = useHistory();

  useEffect(() => {
    if (notifications.length) {
      notifications.forEach((item) => {
        const description = <a>{item.description}</a>;
        const readToast = (
          <a
            onClick={() => {
              notificationClose(item.id);
            }}
            style={{ color: '#009ef7', fontSize: '12px' }}
          >
            Marcar como leída
          </a>
        );

        toast.warning(
          <div>
            {description} <br></br>
            {readToast}
          </div>,
          {
            toastId: item.id,
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: false,
          }
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const notificationClose = async (notificationId: number) => {
    // toast.dismiss(notificationId);
    await getNotifications(notificationId)
      .then(() => {
        toast.dismiss(notificationId);
      })
      .catch((error) => {
        history.push('/error/500');
      });
  };

  return (
    <>
      <div className="row gy-5 g-xl-12">
        {series.length && categories.length && (
          <GenericBarChart
            className="mb-5 mb-xxl-8"
            title={'Locales'}
            description={'Ocupación de locales en plazas'}
            isMoney={false}
            series={series}
            categories={categories}
            height={350}
          />
        )}
      </div>
      <div className="row gy-5 g-xl-12">
        <div className="col-sm-6">
          <ListsData className="mb-5 mb-xxl-8" data={balances} />
        </div>
        <div className="col-sm-6">
          <ResultsList className="mb-5 mb-xxl-8" data={results} />
        </div>
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
