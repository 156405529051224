/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../assets/ts/_utils';

type Props = {
  className: string;
  title: string;
  description: string;
  isMoney: boolean;
  height: number;
  series: any[];
  categories: string[];
};

const GenericBarChart: React.FC<Props> = ({
  className,
  title,
  description,
  isMoney,
  height,
  series,
  categories,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(isMoney, height, series, categories)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, isMoney, height, series, categories]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
          <span className="text-muted fw-bold fs-7">{description}</span>
        </h3>
      </div>
      <div className="card-body">
        <div
          ref={chartRef}
          id="kt_charts_widget_6_chart"
          style={{ height: '350px' }}
        ></div>
      </div>
    </div>
  );
};

export { GenericBarChart };

function getChartOptions(
  isMoney: boolean,
  height: number,
  _series: any | null,
  _categories: string[]
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');

  // const baseColor = getCSSVariableValue('--bs-primary')
  // const secondaryColor = getCSSVariableValue('--bs-info')
  const baseLightColor = getCSSVariableValue('--bs-success');
  const tertiaryColor = getCSSVariableValue('--bs-warning');
  const quaternaryColor = getCSSVariableValue('--bs-danger');

  return {
    series: _series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '45%',
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    // labels: _categories,
    xaxis: {
      categories: _categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return isMoney ? '$' + val : '' + val;
        },
      },
    },
    colors: [
      '#21b6ff',
      '#84CFF4',
      baseLightColor,
      tertiaryColor,
      quaternaryColor,
    ],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
}
