import { useState, useEffect } from 'react';
import {
  getAllconciliation,
  getAllconciliationWithoutPages,
  getAllconciliationWithoutPagesReport,
  getAllConciliationReport
} from '../../../service/conciliacion/Conciliacion';
import { ConciliacionModel } from '../models/ConciliacionModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<ConciliacionModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllconciliation(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, realoadGrid]);
  return { data, count, loadingRequest };
};

export const useGetAllWithoutPages = (realoadGrid: number, filters: any) => {
  const [dataExcel, setDataExcel] = useState<ConciliacionModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllconciliationWithoutPages(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataExcel(res.rows);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { dataExcel };
};


export const useGetAllDataReport = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<ConciliacionModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllConciliationReport(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endIndex, realoadGrid]);
  return { data, count, loadingRequest };
};


export const useGetAllWithoutPagesReport = (realoadGrid: number, filters: any) => {
  const [dataExcel, setDataExcel] = useState<ConciliacionModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllconciliationWithoutPagesReport(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataExcel(res.rows);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { dataExcel };
};