/* eslint-disable react-hooks/rules-of-hooks */
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../app/modules/auth/models/UserModel' 
import { RootState } from '../../setup/index'

export const userHasRole = ( roles : string[] ) => {
    
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const luser: UserModel = user;
    let hasRole: boolean = false;
    
    roles.forEach(element => {

      if (luser?.roles?.some(e => e.code === element)) {
        hasRole = true;
      }
    });
   return hasRole;
}