import React from 'react';
import { Pagination, KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { descargarRecibo } from '../../../service/pagos/Pagos';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loading: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loading,
}) => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const onDescargarRecibo = (pago_id: number) => {
    descargarRecibo(pago_id)
      .then((response) => {})
      .catch((error) => {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  return (
    <>
      <div className="card-body py-3">
        <div className="table-responsive min-h-500px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-100px w-100px">Tipo movimiento</th>
                <th className="min-w-100px w-100px">Folio</th>
                <th className="min-w-80px w-80px">Monto</th>
                <th className="min-w-200px w-200px">Concepto</th>
                <th className="min-w-70px w-70px">Fecha Movimiento</th>
                <th className="text-end min-w-70px w-70px">Recibo</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : count <= 0 ? (
                <tr>
                  <td colSpan={6}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.tipo}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.folio}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start fw-bold flex-column fs-6">
                          {Number(row.monto).toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.concepto}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {
                            new Date(row.fecha_corte)
                              .toISOString()
                              .split('T')[0]
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        {row.pago_id > 0 && (
                          <>
                            <button
                              type="button"
                              data-kt-menu-trigger="hover"
                              data-kt-menu-attach="parent"
                              data-kt-menu-placement="right-start"
                              data-kt-menu-flip="right"
                              onClick={() => {
                                onDescargarRecibo(row.pago_id);
                              }}
                              className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end"
                            >
                              <i className="ki-duotone ki-exit-right-corner fs-2">
                                <KTSVG
                                  path="/media/icons/duotune/ecommerce/ecm008.svg"
                                  className="svg-icon-1 svg-icon-primary"
                                />
                              </i>
                            </button>
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-200px"
                              data-kt-menu="true"
                            >
                              <div className="menu-item px-3 text-gray-500">
                                <KTSVG
                                  path="/media/icons/duotune/arrows/arr004.svg"
                                  className="svg-icon-1tx svg-icon-primary me-2"
                                />
                                Recibo de pago
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            reset={null}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
