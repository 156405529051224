import { useState, useEffect } from 'react'
import { getCatalog, getCatalogGrid } from '../../../../service/catalog/Catalogs'
import { GenericoModel } from '../models/GenericoModel'
import { useHistory } from 'react-router-dom'

export const useGetGenerico = ( code:string ) => {
    
    const [ title, setTitle ] = useState('');
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getCatalog( code ).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setTitle(data.info.name)
            }
         };
         fetchPost();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ code ]);
    return { title }
}
export const useGetAll = ( code:string, realoadGrid: number, startIndex: number, endIndex: number, filters:string ) => {

    const [ catalog, setCatalog ] = useState<GenericoModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const data = await getCatalogGrid( code, startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setCatalog(data.rows);
                setCount(data.count);
                setLoadingRequest(1);
            }
         };
         if(code){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ code, endIndex,  realoadGrid ]);
    return { catalog, count, loadingRequest }
}

export const useSelectGeneric = ( code:string ) => {

    const [ catalog, setCatalog ] = useState<GenericoModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getCatalog( code ).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setCatalog(data.rows);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ code ]);

    return { catalog }
}
