import React, { useState, useRef } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  addArrendatario,
  updateArrendatario,
} from '../../../../service/catalog/Catalogs';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/ArrendatarioHook';
import { NumericFormat } from 'react-number-format';

const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}arrendatarios/id/`;
type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const numbersRegExp =
  /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
const rfcRegExp =
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

const ArrendatarioSchema = Yup.object().shape({
  nombre: Yup.string()
    .max(30, 'El nombre no debe tener más de 30 caracteres')
    .required('Nombre es requerido.'),
  apellido: Yup.string()
    .max(30, 'El apellido no debe tener más de 30 caracteres')
    .required('Apellido es requerido.'),
  telefono: Yup.string()
    .matches(phoneRegExp, 'Formato de teléfono inválido')
    .min(10, 'Formato muy corto')
    .max(10, 'Formato muy largo')
    .required('Teléfono es requerido.'),
  correo: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Correo es requerido.'),
  aval: Yup.string().max(30, 'Aval no debe tener más de 30 caracteres'),
  rfc: Yup.string().matches(rfcRegExp, 'Formato RFC inválido'),
  num_int: Yup.string(),
  num_ext: Yup.string().required('No. requerido.'),
  calle: Yup.string().required('Calle es requerido.'),
  colonia: Yup.string().required('Colonia es requerido.'),
  ciudad: Yup.string().required('Ciudad es requerido.'),
  estado: Yup.string().required('Estado es requerido.'),
  codigo_postal: Yup.string()
    .matches(numbersRegExp, 'Sólo numeros')
    .required('C.P. es requerido.'),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const ineAnversoRef = useRef<HTMLInputElement>(null);

  const onOpenAnversoFile = () => {
    ineAnversoRef?.current?.click();
  };

  const formArrendatario = useFormik({
    initialValues: initValues,
    validationSchema: ArrendatarioSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateArrendatario(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formArrendatario.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addArrendatario(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formArrendatario.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog mw-1000px"
      show={show}
      onHide={() => {
        formArrendatario.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formArrendatario.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formArrendatario.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="text-gray-600">Información general</div>
              <div className="separator my-5"></div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Nombre
                      </label>
                      {formArrendatario.touched.nombre &&
                        formArrendatario.errors.nombre && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formArrendatario.errors.nombre}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Nombre"
                      {...formArrendatario.getFieldProps('nombre')}
                      className="form-control mb-3"
                      type="text"
                      name="nombre"
                      autoComplete="off"
                    />
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Apellido
                      </label>
                      {formArrendatario.touched.apellido &&
                        formArrendatario.errors.apellido && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formArrendatario.errors.apellido}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Apellido"
                      {...formArrendatario.getFieldProps('apellido')}
                      className="form-control mb-3"
                      type="text"
                      name="apellido"
                      autoComplete="off"
                    />
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className=" fw-bold fs-6 mb-2 w-auto">RFC</label>
                      {formArrendatario.touched.rfc &&
                        formArrendatario.errors.rfc && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formArrendatario.errors.rfc}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="RFC"
                      style={{ textTransform: 'uppercase' }}
                      maxLength={13}
                      {...formArrendatario.getFieldProps('rfc')}
                      className="form-control mb-3"
                      type="text"
                      name="rfc"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Teléfono
                      </label>
                      {formArrendatario.touched.telefono &&
                        formArrendatario.errors.telefono && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formArrendatario.errors.telefono}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      className="form-control mb-3"
                      {...formArrendatario.getFieldProps('telefono')}
                      decimalScale={0}
                      fixedDecimalScale
                      placeholder="Teléfono"
                      prefix=""
                      isAllowed={(values: any) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 9999999999;
                      }}
                      onValueChange={(values: any) => {
                        const { floatValue } = values;
                        formArrendatario.setFieldValue('telefono', floatValue);
                      }}
                    />

                    {/* <input
                                            placeholder="Teléfono"
                                            {...formArrendatario.getFieldProps('telefono')}
                                            className="form-control mb-3"
                                            type='text'
                                            name='telefono'
                                            autoComplete='off'
                                        /> */}
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Correo
                      </label>
                      {formArrendatario.touched.correo &&
                        formArrendatario.errors.correo && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formArrendatario.errors.correo}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Correo"
                      {...formArrendatario.getFieldProps('correo')}
                      className="form-control mb-3"
                      type="text"
                      name="correo"
                      autoComplete="off"
                    />
                  </div>
                  <div className="pe-5" style={{ width: '33%' }}>
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">Aval</label>
                      {formArrendatario.touched.aval &&
                        formArrendatario.errors.aval && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formArrendatario.errors.aval}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Aval"
                      {...formArrendatario.getFieldProps('aval')}
                      className="form-control mb-3"
                      type="text"
                      name="aval"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="text-gray-600 mt-5">Dirección</div>
              <div className="separator my-5"></div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Calle
                      </label>
                      {formArrendatario.touched.calle &&
                        formArrendatario.errors.calle && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formArrendatario.errors.calle}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Calle"
                      {...formArrendatario.getFieldProps('calle')}
                      className="form-control mb-3"
                      type="text"
                      name="calle"
                      maxLength={100}
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        No. Exterior
                      </label>
                      {formArrendatario.touched.num_ext &&
                        formArrendatario.errors.num_ext && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formArrendatario.errors.num_ext}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="No. Exterior"
                      {...formArrendatario.getFieldProps('num_ext')}
                      className="form-control mb-3"
                      type="text"
                      name="num_ext"
                      maxLength={10}
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        No. Interior
                      </label>
                      {formArrendatario.touched.num_int &&
                        formArrendatario.errors.num_int && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formArrendatario.errors.num_int}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="No. Interior"
                      {...formArrendatario.getFieldProps('num_int')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={10}
                      name="num_int"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-75 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Colonia
                      </label>
                      {formArrendatario.touched.colonia &&
                        formArrendatario.errors.colonia && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formArrendatario.errors.colonia}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Colonia"
                      {...formArrendatario.getFieldProps('colonia')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      name="colonia"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-25 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        C.P.
                      </label>
                      {formArrendatario.touched.codigo_postal &&
                        formArrendatario.errors.codigo_postal && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formArrendatario.errors.codigo_postal}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="C.P."
                      {...formArrendatario.getFieldProps('codigo_postal')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={5}
                      name="codigo_postal"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Ciudad
                      </label>
                      {formArrendatario.touched.ciudad &&
                        formArrendatario.errors.ciudad && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formArrendatario.errors.ciudad}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Ciudad"
                      {...formArrendatario.getFieldProps('ciudad')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      name="ciudad"
                      autoComplete="off"
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Estado
                      </label>
                      {formArrendatario.touched.estado &&
                        formArrendatario.errors.estado && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formArrendatario.errors.estado}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      placeholder="Estado"
                      {...formArrendatario.getFieldProps('estado')}
                      className="form-control mb-3"
                      type="text"
                      maxLength={50}
                      name="estado"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-gray-600 mt-5">Documentos</div>
            <div className="separator my-5"></div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="card-body d-flex justify-content-center text-center flex-column p-2">
                <a
                  href="#/"
                  className="text-gray-800 text-hover-primary d-flex flex-column"
                >
                  <div className="symbol symbol-75px mb-6">
                    <img
                      src={
                        formArrendatario.values.files ||
                        formArrendatario.values.img_id_frente
                          ? toAbsoluteUrl(
                              '/media/icons/duotune/files/fil028.svg'
                            )
                          : toAbsoluteUrl('/media/svg/files/upload.svg')
                      }
                      className="image-input-wrapper"
                      onClick={onOpenAnversoFile}
                      crossOrigin="anonymous"
                      alt="INE"
                    />
                  </div>
                  <div
                    className="fs-5 fw-bolder mb-2"
                    onClick={onOpenAnversoFile}
                  >
                    INE
                  </div>
                  <input
                    style={{ display: 'none' }}
                    ref={ineAnversoRef}
                    type="file"
                    multiple={true}
                    max={2}
                    name="img_id_frente"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => {
                      if (event.currentTarget.files) {
                        let files = event.currentTarget.files;
                        if (files.length <= 2) {
                          formArrendatario.setFieldValue('files', files);
                        } else {
                          toast.error(
                            'Por favor seleccione máximo 2 archivos.',
                            {
                              position: 'top-right',
                              autoClose: 4000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: 'light',
                            }
                          );
                        }
                      }
                    }}
                  />
                </a>
                <div className="fs-7 fw-bold text-gray-400 mt-auto">
                  {' '}
                  Anverso/Reverso
                </div>
                <div className="fs-7 text-gray-400 mt-auto">
                  {' '}
                  Archivos permitidos: png, jpg, jpeg.{' '}
                </div>
              </div>

              {formArrendatario.values.img_id_frente && (
                <div className="card-body justify-content-center d-flex flex-column fw-bold">
                  <a
                    href={
                      formArrendatario.values.img_id_frente
                        ? `${API_URL_IMAGE_PUBLIC}${formArrendatario.values.img_id_frente}`
                        : '#/'
                    }
                    rel="noreferrer"
                    target={
                      formArrendatario.values.img_id_frente ? '_blank' : '_self'
                    }
                    className={
                      formArrendatario.values.img_id_frente
                        ? 'd-flex align-items-center text-primary text-hover-primary mb-5'
                        : 'd-flex align-items-center text-gray-600 mb-5'
                    }
                  >
                    <i
                      className={
                        formArrendatario.values.img_id_frente
                          ? 'bi bi-eye fs-1 text-primary'
                          : 'bi bi-eye fs-1'
                      }
                    ></i>
                    <span style={{ marginLeft: '10px' }}>
                      Ver documento anverso
                    </span>
                  </a>
                  <a
                    href={
                      formArrendatario.values.img_id_atras
                        ? `${API_URL_IMAGE_PUBLIC}${formArrendatario.values.img_id_atras}`
                        : '#/'
                    }
                    rel="noreferrer"
                    target={
                      formArrendatario.values.img_id_atras ? '_blank' : '_self'
                    }
                    className={
                      formArrendatario.values.img_id_atras
                        ? 'd-flex align-items-center text-primary text-hover-primary mb-5'
                        : 'd-flex align-items-center text-gray-600 mb-5'
                    }
                  >
                    <i
                      className={
                        formArrendatario.values.img_id_atras
                          ? 'bi bi-eye fs-1 text-primary'
                          : 'bi bi-eye fs-1'
                      }
                    ></i>
                    <span style={{ marginLeft: '10px' }}>
                      Ver documento reverso
                    </span>
                  </a>
                </div>
              )}
            </div>
            <div className="text-center pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formArrendatario.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
