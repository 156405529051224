import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getBalance, getOcupation, getResults,getNotifications } from '../../../service/dashboard/Dashboard'
import { BalanceModel } from '../models/BlanceModel'
import { ResultModel } from '../models/ResultModel'
import { OcupationModel } from '../models/OcupationModel'

export const useBalance = () => {

    const [balances, setBalances] = useState<BalanceModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getBalance().then().catch((error) => {
                history.push('/error/500')
            });
            if (data) {
                setBalances(data.rows);
            }
        };
        fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { balances }
}

export const useOcupation = () => {

    const [series, setSeries] = useState<any[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const history = useHistory()

    useEffect(() => {
        const fetchPost = async () => {
            const data = await getOcupation().then().catch((error) => {
                history.push('/error/500')
            });
            if (data) {
                const { series, categories } = generateArrays(data.rows)
                setSeries(series);
                setCategories(categories);
            }
        };
        fetchPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { series, categories }
}

export const useResults = () => {
    const [results, setResults] = useState<ResultModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getResults().then().catch((error) => {
                history.push('/error/500')
            });
            if (data) {
                setResults(data.rows);
            }
        };
        fetchPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { results }
}

function generateArrays(data: Array<OcupationModel>) {
    let categories = new Array<string>();
    let localTotals: any = [];
    let localRented: any = [];

    data.forEach((item, index) => {
        categories.push(item.plaza.nombre.toUpperCase() + '  ' + item.pcte_ocupacion);
        localTotals.push(item.total_locales);
        localRented.push(item.total_rentados);
    });

    const series: any = [
        {
            name: 'Total de locales',
            data: localTotals,
        },
        {
            name: 'Locales ocupados',
            data: localRented,
        },
    ];

    return { series, categories }
}

export const useGetNotifications = () => {
    
    const [notifications, setNotifications] = useState<any[]>([]);
    const history = useHistory();

    useEffect(() => {
        const fetchGet = async () => {
            const data = await getNotifications('').then().catch((error) => {
                history.push('/error/500')
            });
            if (data) {
                setNotifications(data.notifications);
            }
        };
        fetchGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        notifications
    }

}