import React, { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { addCPP, updateCPP } from '../../../service/cuentas_pagar/CuentasPagar';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/CuentasPagarHook';
import { useSelectPlazas } from '../../catalogos/plazas/hooks/PlazaHook';
import { useSelect } from '../../catalogos/tipos_cuentas_pagar/hooks/TipoCuentaPagoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const CPPSchema = Yup.object().shape({
  plaza_id: Yup.number()
    .min(1, 'Seleccione una plaza.')
    .required('Plaza es requerido.'),
  tipo_cuenta_pago_id: Yup.number()
    .min(1, 'Seleccione un tipo de cuenta.')
    .required('Tipo de cuenta es requerido.'),
  descripcion: Yup.string().max(300, 'No puede tener más de 100 caracteres.'),
  fecha: Yup.date().required('Fecha de pago es requerido.'),
  monto: Yup.number()
    .min(1, 'Ingrese un monto válido.')
    .required('Monto es requerido.'),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { plazas } = useSelectPlazas();
  const { data: tipo_cuenta_pago } = useSelect();

  const formCPP = useFormik({
    initialValues: initValues,
    validationSchema: CPPSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateCPP(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formCPP.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addCPP(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formCPP.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog mw-900px"
      show={show}
      onHide={() => {
        formCPP.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formCPP.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formCPP.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Plaza
                      </label>
                      {formCPP.touched.plaza_id && formCPP.errors.plaza_id && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">{formCPP.errors.plaza_id}</span>
                        </div>
                      )}
                    </div>
                    <select
                      className="form-select mb-3"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...formCPP.getFieldProps('plaza_id')}
                    >
                      <option value={0}>Seleccione</option>
                      {plazas.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Tipo de cuenta
                      </label>
                      {formCPP.touched.tipo_cuenta_pago_id &&
                        formCPP.errors.tipo_cuenta_pago_id && (
                          <div className="fv-plugins-message-container text-danger w-50 ms-5">
                            <span role="alert">
                              {formCPP.errors.tipo_cuenta_pago_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select mb-3"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...formCPP.getFieldProps('tipo_cuenta_pago_id')}
                    >
                      <option value={0}>Seleccione</option>
                      {tipo_cuenta_pago.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.descripcion}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Monto
                      </label>
                      {formCPP.touched.monto && formCPP.errors.monto && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">{formCPP.errors.monto}</span>
                        </div>
                      )}
                    </div>
                    <NumericFormat
                      name="monto"
                      className="form-control mb-3"
                      value={formCPP.values.monto}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formCPP.setFieldValue('monto', floatValue);
                      }}
                    />
                  </div>
                  <div className="w-50 pe-5">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Fecha
                      </label>
                      {formCPP.touched.fecha && formCPP.errors.fecha && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">{formCPP.errors.fecha}</span>
                        </div>
                      )}
                    </div>
                    <input
                      type="date"
                      onKeyDown={(e) => e.preventDefault()}
                      value={
                        new Date(formCPP.values.fecha)
                          .toISOString()
                          .split('T')[0]
                      }
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formCPP.setFieldValue('fecha', fecha);
                        }
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-xl-row flex-row-fluid">
                <div className="w-100 d-flex">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="fw-bold fs-6 mb-2 w-auto">
                        Descripción
                      </label>
                      {formCPP.touched.descripcion &&
                        formCPP.errors.descripcion && (
                          <div className="fv-plugins-message-container text-danger w-100 ms-5">
                            <span role="alert">
                              {formCPP.errors.descripcion}
                            </span>
                          </div>
                        )}
                    </div>
                    <textarea
                      style={{ resize: 'none' }}
                      className="form-control"
                      rows={4}
                      data-kt-element="input"
                      placeholder="Agregue una descripción"
                      {...formCPP.getFieldProps('descripcion')}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formCPP.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
