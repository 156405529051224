import { useState, useEffect } from 'react'
import { getAllLocales, getOneLocal, getSelectLocal } from '../../../../service/catalog/Catalogs'
import { LocalModel } from '../models/LocalModel'
import { useHistory } from 'react-router-dom'

export const useGetAll = (realoadGrid: number, startIndex: number, endIndex: number, filters:any ) => {

    const [ data, setData ] = useState<LocalModel[]>([]);
    const [ count, setCount ] = useState(1);
    const [ loadingRequest, setLoadingRequest ] = useState(0);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getAllLocales( startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
         };
         if( realoadGrid ){
            fetchPost();
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endIndex,  realoadGrid]);
    return { data, count, loadingRequest }
}

export const useGetOne = ( id:number ) => {
    const initModel = {
        id: 0,
        num_local: '',
        medidas: 0,
        tipo_local_id: 0,
        nivel_id: 0,
        estatus_id: 0,
        socio: false,
        nombre_socio: '',
        plaza_id: 0,
        calle: '',
        num_int: '',
        num_ext: '',
        colonia: '',
        codigo_postal: '',
        ciudad: '',
        estado: ''
    }
    const [ initValues, setInitValues ] = useState<LocalModel>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOneLocal( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}


export const useSelectLocal = ( plazaId: number ) => {

    const [ data, setData ] = useState<LocalModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getSelectLocal(plazaId).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setData(res.rows);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plazaId]);

    return { data }
}
